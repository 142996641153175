import React from "react"
import Card from "react-bootstrap/Card"
import Button from "react-bootstrap/Button"
import t24 from "../../assets/titleimage/t24.png"
import { Link } from "react-router-dom"
import { Grid } from "@material-ui/core"
import { Helmet } from "react-helmet"


export const CasProjects = () => (
  <main>
   <div>
    <Helmet>
    <title>
    CAS Projects | Cambridge International Foundation School 

         </title>
    </Helmet>

    </div>
    <div align="center">
    <img src={t24} alt="blog" />
    </div>
    <Grid container justify="center" style={{ margin: "10px 2px" }}>
      <div
        className="teamContainer pageContainer1 pageContainer3 alumniVideo"
        style={{ marginBottom: "30px" }}
      >
        <h1
          style={{ color: "#e95721", fontWeight: "bold", fontFamily: "sans-serif" }}
        >
          Creativity Activity Service Projects
        </h1>

        <p className="card-text text-secondary">
          Creativity activity service (CAS) is one of the three essential
          elements that every student must complete as part of the Diploma
          Programme (DP). Here are some of our students' CAS Projects —
        </p>

        <Grid container spacing={1}>
          <Grid item xs={12} sm={12} md={6}>
            <Card
              style={{
                border: "solid #fcbc3c",
              }}
            >
              <Card.Img
                variant="top"
                src={require("../../assets/casprojects/cas1.jpg")}
              />

              
                  <h4
                align="center"
                style={{
                  color: "#e95721",
                  fontWeight: "bold",
                  fontFamily: "sans-serif",
                  paddingTop: "15px",
                }}
              >
                We Care Project
              </h4>

              <Card.Text
                className="card-text text-secondary"
                align="center"
                style={{
                  fontSize: "16px",
                  textAlign: "justify",
                  padding: "10px 10px 10px 10px ",
                }}
              >
                We Care is a campaign run by our school organization to help
                needy and people in a state of poverty who needs help getting
                food. This is done on weekly basis , on every Fridays. Me and my
                school mates were a part of this as well. On 8th' Nov' 2019 me
                and myclassmates supporting this service took initiative to be a
                part of this and went at a place near our school to distribute
                certain food items to needy people passing by which included
                kids, elderly people, disabled people and needy labour.Before
                setting off from school we collected these food items from the
                students of our school so that we can have an enough amount of
                food items to give away, Which included biscuits,candies,fruits
                and many more.It was a great pleasure to be a part of this
                service and we experienced the worth of gaining smiles on
                people's faces while helping them.
              </Card.Text>
            </Card>
          </Grid>

          <Grid item xs={12} sm={12} md={6} className="imageAdjust">
            <Card
              style={{
                border: "solid #fcbc3c",
              }}
            >
              <Card.Img
                variant="top"
                src={require("../../assets/casprojects/plastic.jpeg")}
              />

              <h4
                align="center"
                style={{
                  color: "#e95721",
                  fontWeight: "bold",
                  fontFamily: "sans-serif",
                  paddingTop: "15px",
                }}
              >
                Alternatives to Plastic are Fantastic
              </h4>
              <Card.Text
                className="card-text text-secondary"
                align="center"
                style={{
                  fontSize: "16px",
                  textAlign: "justify",
                  padding: "10px 10px 10px 10px ",
                }}
              >
                To reduce the utilization of plastic bags we the students of
                IBDP (Jr) took the initiative to start the practice of using
                paper bags instead of plastic bags. We started our activity by
                spreading awareness among the students and giving them demos on
                how to prepare paper bags. Later we conducted Paper bag making
                activity on the occasion of Independence day. We collected
                around 1500-1600 paper bags. According to our CAS plan we were
                supposed to distribute the paper bags in vegetable market to
                vendors and convincing them not to sell vegetables in plastic
                bags. We all took initiative to talk each vendor and explain
                them about adverse effects of plastic. We received a very good
                response as we were working in group which made huge impact on
                them and persuading them became even more easy and we were able
                to cover large area in short duration.
              </Card.Text>
            </Card>
          </Grid>
        </Grid>

        <Grid container spacing={1}>
          <Grid item xs={12} sm={12} md={6}>
            <br />
            <Card
              style={{
                border: "solid #fcbc3c",
              }}
            >
              <Card.Img
                variant="top"
                src={require("../../assets/casprojects/imbibe.jpeg")}
              />

              <h4
                align="center"
                style={{
                  color: "#e95721",
                  fontWeight: "bold",
                  fontFamily: "sans-serif",
                  paddingTop: "15px",
                }}
              >
                Session on Imbibing Sanguiness
              </h4>
              <Card.Text
                className="card-text text-secondary"
                align="center"
                style={{
                  fontSize: "16px",
                  textAlign: "justify",
                  padding: "10px 10px 10px 10px ",
                }}
              >
                ‘Nothing can doom man but the belief in doom, for this prevents
                the movement of return.’Negativity is the biggest hurdle in
                man’s progress. To help our students shun the negative thoughts
                and be always the supporters of positivity, session on ‘Imbibing
                Sanguiness’ was organized in our school. The video and power
                point presentation shown helped to inculcate a positive aura and
                the activity taken up inspired the young minds to always look at
                the brighter side of everything.
              </Card.Text>
            </Card>
          </Grid>

          <Grid item xs={12} sm={12} md={6} className="imageAdjust">
            <Card
              style={{
                border: "solid #fcbc3c",
              }}
            >
              <Card.Img
                variant="top"
                src={require("../../assets/casprojects/canteen.jpeg")}
              />

              <h4
                align="center"
                style={{
                  color: "#e95721",
                  fontWeight: "bold",
                  fontFamily: "sans-serif",
                  paddingTop: "15px",
                }}
              >
                Canteen Fundraiser
              </h4>
              <Card.Text
                className="card-text text-secondary"
                align="center"
                style={{
                  fontSize: "16px",
                  textAlign: "justify",
                  padding: "10px 10px 10px 10px ",
                }}
              >
                The quickest way to ones heart is through their stomach. The
                stomach also happens to be the quickest way to ones pocket. That
                was one of the main reasons my classmates and I chose to set up
                a canteen as our first fundraiser. The money collected has been
                put aside for the future; should we need funding for any
                activity, we will be ready.
              </Card.Text>
            </Card>
          </Grid>
        </Grid>

        <Grid container spacing={1}>
          <Grid item xs={12} sm={12} md={6}>
            <br />
            <Card
              style={{
                border: "solid #fcbc3c",
              }}
            >
              <Card.Img
                variant="top"
                src={require("../../assets/casprojects/art.jpeg")}
              />

              <h4
                align="center"
                style={{
                  color: "#e95721",
                  fontWeight: "bold",
                  fontFamily: "sans-serif",
                  paddingTop: "15px",
                }}
              >
                Art Exhibition
              </h4>
              <Card.Text
                className="card-text text-secondary"
                align="center"
                style={{
                  fontSize: "16px",
                  textAlign: "justify",
                  padding: "10px 10px 10px 10px ",
                }}
              >
                Art can only exist among those who appreciate it. But there
                needs to be art for people to learn to appreciate first. That
                was the goal when students of IBDP(12) chose to set up this art
                exhibition to allow ople to appreciate the creativity and talent
                of the young minds. There is no art gallery in this city, so it
                often goes unappreciated. They hope they had helped change that,
                even if it was just a little bit.
              </Card.Text>
            </Card>
          </Grid>

          <Grid item xs={12} sm={12} md={6} className="imageAdjust">
            <Card
              style={{
                border: "solid #fcbc3c",
              }}
            >
              <Card.Img
                variant="top"
                src={require("../../assets/casprojects/tedx.jpeg")}
              />

              <h4
                align="center"
                style={{
                  color: "#e95721",
                  fontWeight: "bold",
                  fontFamily: "sans-serif",
                  paddingTop: "15px",
                }}
              >
                TEDxYouth
              </h4>
              <Card.Text
                className="card-text text-secondary"
                align="center"
                style={{
                  fontSize: "16px",
                  textAlign: "justify",
                  padding: "10px 10px 10px 10px ",
                }}
              >
                TED, with the slogan 'ideas worth spreading', has been a great
                influence on young minds of this generation. TEDxYouth allows
                these young minds to set up something similar, something to
                share knowledge and incite curiosity. The event took place on
                October 14th, but preparations began 3 months prior. There was a
                lot of work, after all, and most of the students involved were
                young and inexperienced. IBDP(12) students volunteered to be a
                part of this activity.
              </Card.Text>
            </Card>
          </Grid>
        </Grid>
      </div>
    </Grid>
  </main>
)
