import React from "react"
import { Helmet } from "react-helmet"

export const EarlyyearsPage = () => (
  <main>
 

  <div>
    <Helmet>
    <title>
     Dean Sparkling Stars Message | Cambridge International Foundation School 

         </title>
    </Helmet>

    </div>
    <div className="row">
      <div className="col teamContainer pageContainer pageContainer1">
        <h1
          style={{
            color: "#e95721",
            fontSize: "35px",
            fontWeight: "bold",
            fontFamily: "sans-serif",
          }}
        >
         Dean Sparkling Stars Message
        </h1>

        <p>
                Ms. Monica Jouhal has for the last ten years in all her
                spirits of knowledge, profession and attitude
                dedicated to nurture the youngest members of LWES
                family.
        </p>
        <p>
                    As an early childhood teacher, has been challenged to
          find new experiences to share with children. Her
          greatest strength is that she guides children and not
          lead them, always moves in a direction that interests
          the child.
        </p>
      </div>
      <div
        className="col"
        className="pageContainer pageContainer1 teamImage spaceAdjust"
      >
        <img
          style={{ border: "2.5px solid #fcbc3c" }}
          src={require("../assets/images//monica.jpg")}
          alt="Ms. Monica Jouhal"
          loader="gradient"
          Width="250px"
          Height="300px"
          gradientPreset="life"
        />
        <p align="center" style={{ paddingLeft: "20px" }}>
          <strong>Ms. Monica Jouhal</strong>
          <br />
          Dean Sparkling Stars
        </p>
      </div>
    </div>

    <div className="teamContainer pageContainer pageContainer1 spaceAdjust">
      <p>
                She has mentored and fostered early years
        teachers to develop and educate children to be
        independent learners.
      </p>

      <p>
              With her Master's in English and Degrees in Early Years Education as well as in
      Education, Monica has created exciting curriculum plans, learners centered activities and parent
      engagement plans. Early Years Learners in LWES "Shine On" under her tender care.
      </p>
      
      <p>
        <strong>Warm regards,</strong>
        <br />
        <strong> Monica Jouhal</strong>
      </p>
    </div>
  </main>
)
