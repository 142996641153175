import React from 'react'
import ReactDom from 'react-dom'
import IndexPage from './pages'
import "semantic-ui-css/semantic.min.css"

ReactDom.render(
  <React.StrictMode>
      <IndexPage />
  </React.StrictMode>,
  document.getElementById('root')
)
