import React from "react"
import Gallery from "../gallery"
import t21 from "../../assets/titleimage/t21.png"
import { Grid } from "@material-ui/core"
import { Helmet } from "react-helmet"


export const OurStory = () => (
  <main>
  <div>
    <Helmet>
    <title>
    Our Story | Cambridge International Foundation School 

         </title>
    </Helmet>

    </div>
    <div align="center">
    <img src={t21} alt="Our-Story" />
    </div>
    <Grid container style={{ margin: "10px 2px" }}>
      <div
        className="teamContainer pageContainer pageContainer1 pageContainer3 alumniVideo"
        style={{ marginBottom: "30px" }}
      >
        <h1
          style={{
            color: "#e95721",
            fontSize: "35px",
            fontWeight: "bold",
            fontFamily: "sans-serif",
          }}
        >
          Our Story
        </h1>
        <p className="card-text text-secondary">
          Cambridge International Foundation School is the 1st IB School in
          Punjab, Internationally Benchmarked, offering classes from KG to XII.
        </p>
        <div align="center">
        <img
         
          style={{
            background: "transparent",
           
          }}
          src={require("../../assets/icons/OurStory.png")}
          alt="Our-Story"
          loader="gradient"
          gradientPreset="life"
        />
        </div>
        <p className="card-text text-secondary">
          CIFS provides an International exposure within the cultural dimensions
          to the students. Our school extends innovative and progressive
          pedagogy blend with international curriculum that fosters inquiry and
          investigation among the students. The students are nurtured to
          confront every challenge leading to their development.
        </p>
        <p className="card-text text-secondary">
          We believe in making the change makers for the society who has the
          potential to break the stereotypical norms by achieving 100% result.
          The curriculum includes a plethora of co-curricular activities which
          are woven into the academic timetable with an approach of ‘I am the
          leader’ to develop self-inquirers and learners.
        </p>
        <p className="card-text text-secondary">
          The students are endowed with the opportunities to learn outside of
          the regular school day. They develop positive attitude and values with
          a sense of responsibility towards the community. At CIFS, we encourage
          the strong parent partnership which is essential for the holistic
          development of the children and raising them into confident, capable
          and truly global citizens of whom the entire Cambridge family can be
          proud.
        </p>
        <p>
          <i style={{ fontSize: "150%", color: "#0ca685" }}>
            {" "}
            We are a part of the Learning Wings family
          </i>
          .
        </p>
        <p className="card-text text-secondary">
          Learning Wings is a global organisation devoted to high-quality
          education that meets the demands of parents, students and education
          providers the world over. It is also a leading provider of school
          management services and works closely with schools to raise student
          achievements. It's flagship schools - Cambridge International School -
          spread across North India is showing children the process of realizing
          their potential.
        </p>
        <a
          href="https://www.learningwings.org/"
          style={{ fontWeight: "bold", fontSize: "15px" }}
        >
          Read about Learning Wings Education Systems ⇝>
        </a>
        <br />
        <br />



<div align="center">
        <img
         
          style={{
            background: "transparent",
           
          }}
          src={require("../../assets/images/gallery6.png")}
          alt="Our-Story"
          loader="gradient"
          gradientPreset="life"
        />
        </div>
        <br />
        <br />


        <div align="center">
        <img
         
          style={{
            background: "transparent",
           
          }}
          src={require("../../assets/images/gallery3.png")}
          alt="Our-Story"
          loader="gradient"
          gradientPreset="life"
        />
        </div>
        <br />
        <br />

        <div align="center">
        <img
         
          style={{
            background: "transparent",
           
          }}
          src={require("../../assets/images/gallery4.png")}
          alt="Our-Story"
          loader="gradient"
          gradientPreset="life"
        />
        </div>
        <br />
        <br />

        <div align="center">
        <img
         
          style={{
            background: "transparent",
           
          }}
          src={require("../../assets/images/gallery5.png")}
          alt="Our-Story"
          loader="gradient"
          gradientPreset="life"
        />
        </div>
        <br />
        <br />

        <div align="center">
        <img
         
          style={{
            background: "transparent",
           
          }}
          src={require("../../assets/images/gallery1.png")}
          alt="Our-Story"
          loader="gradient"
          gradientPreset="life"
        />
        </div>
        <br />
        <br />

        <div align="center">
        <img
         
          style={{
            background: "transparent",
           
          }}
          src={require("../../assets/images/gallery2.png")}
          alt="Our-Story"
          loader="gradient"
          gradientPreset="life"
        />
        </div>





  
      </div>
    </Grid>
  </main>
)
