import React from "react"
import { Helmet } from "react-helmet"
import Table from "react-bootstrap/Table"
import { Grid } from "@material-ui/core"

import ap from "../assets/video/ap.mp4"



import ReactPlayer from "react-player"

export const Ap = () => (
  <main>
  <div>
    <Helmet>
    <title>
     AP Exams | Cambridge International Foundation School 

         </title>
    </Helmet>

    </div>




<div className="pageContainer pageContainer1">
      <h1
        style={{
          color: "#000000",
          fontWeight: "bold",
          fontFamily: "sans-serif",
          fontSize: "35px",
        }}
      >
     
      </h1>

      
        <p> 
         
        College Board’s Advanced Placement Program® is recognized by universities around the world as a standard of academic excellence. Since 1955, the AP® Program has enabled millions of students to take.
    
      </p>

      
      <p>
         U.S. university undergraduate-level courses and earn undergraduate degree credit, advanced placement, or both while still in high school/secondary school.
        </p>
      
      <p>
       Nearly all colleges and universities in the United States—as well as more than 500 universities in 75 countries such as the U.K., Canada, and Australia— consider AP participation favourably for admission and have policies around AP credit and placement.

        </p>

      <br/>
    <p>
        For more information visit College Board’s website: <a style={{ paddingRight: "80px", fontStyle: "italic", textDecorationLine: "underline"}} href="https://international.collegeboard.org/students/ap/taking-ap-india">Click here</a>
        </p>
         <p>
        Access the list of Universities around the world offering AP credits:  <a style={{ paddingRight: "80px", fontStyle: "italic", textDecorationLine: "underline"}} href="https://international.collegeboard.org/students/ap/find-universities-recognize-ap">Click here</a>
        </p>
      
      
               <h4
              style={{
                color: "#0ca685",
                fontWeight: "bold",
                fontSize: "25px",
                fontFamily: "sans-serif",
              }}
            >
             AP BENEFITS ENABLE MOTIVATED STUDENTS LIKE YOU TO
            </h4>


<ul>
            <li>
              
              Stand out in university admissions. 
            </li>


            <li>
             
             Earn university credit.
            </li>


            <li>
               
             Save money on tuition.
              
              
            </li>


            <li>
              
               Skip introductory university classes.
              
            </li>

            <li>
              
              Build skills and confidence.
              
            </li>
          <li>
              
             Earn higher first-year grades at university.
              
            </li>
          </ul>


    <br/>
      <p>
Read  <a style={{ paddingRight: "80px", fontStyle: "italic", textDecorationLine: "underline"}} href="https://timesofindia.indiatimes.com/spotlight/heres-how-students-benefit-from-the-advanced-placement-program/articleshow/81147892.cms?upcache=2">how AP helped these students.</a>
               </p>

<p>
The link to the course descriptions is <a style={{ paddingRight: "80px", fontStyle: "italic", textDecorationLine: "underline"}} href="https://apstudents.collegeboard.org/course-index-page">https://apstudents.collegeboard.org/course-index-page</a>
               </p>
                 <p>
Review <a style={{ fontStyle: "italic", textDecorationLine: "underline"}} href="https://apcentral.collegeboard.org/exam-administration-ordering-scores/exam-dates">AP course descriptions</a> for the subject(s) you wish to take to see which topics are covered in the AP course - and what might be on the exam.</p>
  <br/>
                   <br/>
                  
 <h4
              style={{
                color: "#0ca685",
                fontWeight: "bold",
                fontSize: "25px",
                fontFamily: "sans-serif",
              }}
            >
            AUTHORIZED AP TEST CENTER
            </h4>
<p>
              Cambridge International Foundation School is authorized by the AP Program to administer 2025 AP Exams. However, test centres and schools set their own exam pricing, registration timeline and payment policies.

              </p>

  <br/>
                <br/>
            

              
               <h4
              style={{
                color: "#0ca685",
                fontWeight: "bold",
                fontSize: "25px",
                fontFamily: "sans-serif",
              }}
            >
            IMPORTANT CHANGE FOR THE 2025 AP EXAM
            </h4>
<p>
The 2025 AP exams will be administered as Fully Digital and Hybrid Digital over two weeks in May 2025.
</p>
              
 <Table striped bordered hover size="sm" responsive="sm">
          <thead>
            <tr
              style={{
                color: "#BLACK",
                fontSize: "15px",
                fontWeight: "bold",
                fontFamily: "sans-serif",
              }}
            >
              <th className="transition"> Fully digital AP subjects for 2025—no paper testing option is available except for students approved by the College Board to receive a paper exam for digital assessments</th>
              <th>Hybrid digital AP subjects for 2025—students will view all of the exam questions in Bluebook but will answer free-response questions in a paper booklet as usual</th>
              </tr>
          </thead>
          <tbody>




          <tr className="card-text text-secondary">
              <td> AP Computer Science A </td>
              <td> AP Biology</td>
              </tr>

            <tr className="card-text text-secondary">
              <td> AP English Language and Composition </td>
              <td> AP Calculus AB </td>
              </tr>

            <tr className="card-text text-secondary">
              <td> AP English Literature and Composition </td>
              <td> AP Calculus BC </td>
             </tr>

               <tr className="card-text text-secondary">
              <td> AP Environmental Science </td>
              <td> AP Chemistry </td>
             </tr>

                <tr className="card-text text-secondary">
              <td> AP Psychology </td>
              <td> AP Macroeconomics </td>
             </tr>

                <tr className="card-text text-secondary">
              <td> AP Comparative Government and Politics </td>
              <td> AP Microeconomics </td>
             </tr>

                 <tr className="card-text text-secondary">
              <td> AP Art History </td>
              <td> AP Physics 2: Algebra-Based </td>
             </tr>

                <tr className="card-text text-secondary">
              <td> AP Psychology </td>
              <td> AP Physics C: Mechanics </td>
             </tr>

               <tr className="card-text text-secondary">
              <td>  </td>
              <td> AP Physics C: Electricity and Magnetism </td>
             </tr>

               <tr className="card-text text-secondary">
              <td>  </td>
              <td> AP Precalculus </td>
             </tr>
              
            </tbody>
        </Table>
     
       <h4
              style={{
                color: "#0ca685",
                fontWeight: "bold",
                fontSize: "25px",
                fontFamily: "sans-serif",
              }}
            >
             2025 AP EXAM SCHEDULE
            </h4>
<p>AP's Student eligibility: Students must be enrolled in or affiliated with a secondary school (high school) to take AP Exams. Students in any of the categories below are eligible to test:</p>

<ul>
            <li>
              
            Students actively enrolled in secondary school.
            </li>


            <li>
             
            Secondary school-level students who are home-schooled, participate in independent study, or attend a virtual school.
            </li>


            <li>
               
           Actively enrolled students who may be prepared to take an AP Exam prior to the ninth grade.
              
              
            </li>


            <li>
              
          Recent secondary school graduates who need a specific AP Exam for university admission.
              
            </li>
          </ul>

    <br/>

<p>* If you recently graduated secondary school (typically within but not limited to 1-3 years after graduation) and need an AP Exam to apply for university, you do not need to contact the AP Program for permission to test in 2025. If you aren’t sure if you’re eligible to test, please contact your test center coordinator.</p>

   <h4
              style={{
                color: "#0ca685",
                fontWeight: "bold",
                fontSize: "25px",
                fontFamily: "sans-serif",
              }}
            >
          AP EXAMS: WEEK 1
            </h4>             

<p>Here is the 2025 AP <a style={{ paddingRight: "80px", fontStyle: "italic", textDecorationLine: "underline"}} href="https://international.collegeboard.org/students/ap/taking-ap-india">Exam schedule</a></p>
<p><b>
The first week of the 2025 AP Exams runs from Monday, May 5th to Friday, May 9th.</b> Morning exams will be taken at 8:00am local time, afternoon exams will be taken at 12:00 noon local time.
  </p>

 <h4
              style={{
                color: "#0ca685",
                fontWeight: "bold",
                fontSize: "25px",
                fontFamily: "sans-serif",
              }}
            >
        WEEK 1 AP EXAM SCHEDULE
            </h4>             

<p>2025 AP Exam Dates</p>

<Table striped bordered hover size="sm" responsive="sm">
          <thead>
            <tr
              style={{
                color: "#BLACK",
                fontSize: "15px",
                fontWeight: "bold",
                fontFamily: "sans-serif",
              }}
            >
              <th className="transition"> Week 1</th>
              <th>Morning 8:00 AM (Local Time)</th>
              <th>Afternoon 12:00 noon (Local Time)</th>
              </tr>
          </thead>
          <tbody>




          <tr className="card-text text-secondary">
              <td> Monday, May 5, 2025</td>
              <td> Biology </td>
            <td> European History </td>  
              </tr>

            <tr className="card-text text-secondary">
              <td>   </td>
              <td> Latin </td>
               <td> Microeconomics </td>  
              </tr>

            <tr className="card-text text-secondary">
              <td> Tuesday, May 6, 2025 </td>
              <td> Chemistry </td>
              <td> United States Government and Politics </td>
             </tr>

               <tr className="card-text text-secondary">
              <td>   </td>
              <td> Human Geography </td>
               <td>   </td>
             </tr>

                <tr className="card-text text-secondary">
              <td> Wednesday, May 7, 2025 </td>
              <td> English Literature and Composition </td>
            <td>  Comparative Government and Politics </td>
             </tr>

                <tr className="card-text text-secondary">
              <td>   </td>
              <td>   </td>
              <td> Computer Science A </td>
             </tr>

                 <tr className="card-text text-secondary">
              <td> Thursday, May 8, 2025 </td>
               <td> African American Studies </td>
              <td> Japanese Language and Culture</td>
             </tr>
                
               <tr className="card-text text-secondary">
              <td>   </td>
              <td> Statistics </td>
              <td> World History: Modern </td>
             </tr>

               <tr className="card-text text-secondary">
              <td> Friday, May 9, 2025 </td>
               <td> Italian Language and Culture </td>
               <td> Chinese Language and Culture </td>
             </tr>
              
              <tr className="card-text text-secondary">
              <td>   </td>
               <td> United States History </td>
               <td> Macroeconomics </td>
             </tr>
              
            </tbody>
        </Table>
              <br/>

<h4
              style={{
                color: "#0ca685",
                fontWeight: "bold",
                fontSize: "25px",
                fontFamily: "sans-serif",
              }}
            >
        WEEK 2 AP EXAM SCHEDULE
            </h4>      
          <br/>

<Table striped bordered hover size="sm" responsive="sm">
          <thead>
            <tr
              style={{
                color: "#BLACK",
                fontSize: "15px",
                fontWeight: "bold",
                fontFamily: "sans-serif",
              }}
            >
              <th className="transition"> Week 2</th>
              <th>Morning 8:00 AM (Local Time)</th>
              <th>Afternoon 12:00 noon (Local Time)</th>
              </tr>
          </thead>
          <tbody>




          <tr className="card-text text-secondary">
              <td> Monday, May 12, 2025</td>
              <td> Calculus AB </td>
            <td> Music Theory </td>  
              </tr>

            <tr className="card-text text-secondary">
              <td>   </td>
              <td> Calculus BC </td>
               <td> Seminar </td>  
              </tr>

            <tr className="card-text text-secondary">
              <td>Tuesday, May 13, 2025 </td>
              <td> French Language and Culture </td>
              <td> Environmental Science </td>
             </tr>

               <tr className="card-text text-secondary">
              <td>   </td>
              <td> Precalculus </td>
               <td> Physics 2: Algebra-Based  </td>
             </tr>

                <tr className="card-text text-secondary">
              <td> Wednesday, May 14, 2025 </td>
              <td> English Language and Composition </td>
            <td>  Physics C: Mechanics </td>
             </tr>

                <tr className="card-text text-secondary">
              <td>   </td>
              <td> German Language and Culture  </td>
              <td>   </td>
             </tr>

                 <tr className="card-text text-secondary">
              <td> Thursday, May 15, 2025 </td>
               <td> Art History </td>
              <td> Computer Science Principles</td>
             </tr>
                
               <tr className="card-text text-secondary">
              <td>   </td>
              <td> Spanish Language and Culture </td>
              <td> Physics C: Electricity and Magnetism </td>
             </tr>

               <tr className="card-text text-secondary">
              <td> Friday, May 16, 2025</td>
               <td> Physics 1: Algebra-Based </td>
               <td> Psychology </td>
             </tr>
              
              <tr className="card-text text-secondary">
              <td>   </td>
               <td> Spanish Literature and Culture </td>
               <td>   </td>
             </tr>
              
            </tbody>
        </Table>
              <br/>
<h4
              style={{
                color: "#0ca685",
                fontWeight: "bold",
                fontSize: "25px",
                fontFamily: "sans-serif",
              }}
            >
       TESTING POLICIES
            </h4>      
          <br/>


<ul>
            <li>
              Students actively enrolled in secondary school.
         </li>
         <li>
         Students must have either a valid passport or another valid, government-issued ID, such as a PVC Aadhar card, to register and take AP Exams.
         </li>
         <li>
         Must be under the age of 21 at the time of the exams. (Born after May 31, 2004).
         </li>
           <li>
           Can take any or all of the 4 Physics exams in the same year.
           </li>
          <li>
           Cannot retake an exam in the same year; you may retake it in a subsequent year.
           </li>
         <li>
           Cannot take both Calculus AB and Calculus BC Exams in the same year.
           </li>
        <li>
           For the other exams we offer, course enrolment is not required.
           </li>
        <li>
           You cannot take 2 regular exams scheduled at the same date and time. Decide which exam you wish to take first and then take the other exam during the late testing window.
           </li>
 </ul>
         <br/>

            <h4
              style={{
                color: "#0ca685",
                fontWeight: "bold",
                fontSize: "25px",
                fontFamily: "sans-serif",
              }}
            >
          REGISTRATION TIMELINE – FIRST WINDOW (WITHOUT LATE FEES)
            </h4>
            <br/>
            
<table>
               <tbody>
            <tr className="card-text text-secondary">
              <td> Registration Start Date (for regular and late exams)</td>
              <td> October 22, 2024 </td>
              </tr>

            <tr className="card-text text-secondary">
              <td> Registration End Date (for regular and late exams)  </td>
              <td> November 9, 2024 </td>
            
              </tr>

             <tr className="card-text text-secondary">
              <td> AP Exam Fee (INR) per subject Regular Exam </td>
              <td> Rs.15,000 </td>
            
              </tr>
            </tbody>
            </table>
            <br/>
            
           <p>
            Students will need a student account with College Board to register.
            </p>
            <p>
            Link to My AP account: <a style={{ fontStyle: "italic", textDecorationLine: "underline"}} href="https://myap.collegeboard.org/login">https://myap.collegeboard.org/login </a> (Create an account only if you do not already have one you use for SAT, PSAT, AP.)</p>
<p>
  Find detailed information related to our Test Centre’s AP Exam policy and Registration process, Exam Fee in the PDF document listed below (available during registration period):</p>
          
<p><a target="_blank" href={require("../assets/PSAT/APDocument2024-25.pdf")}>AP EXAM : STUDENT REGISTRATION AND PAYMENT INSTRUCTIONS (.pdf)</a></p>
          <br/>
 <h4
              style={{
                color: "#0ca685",
                fontWeight: "bold",
                fontSize: "25px",
                fontFamily: "sans-serif",
              }}
            >
          REGISTER FOR AP EXAMS
            </h4>
            <br/>
  

  <p>Students must ensure that they have registered for all AP Exams, paid the exam fee to the AP test center, and completed the entire process mentioned in the AP Exam registration instructions by the deadlines listed. There will be no extensions to the deadlines allowed later.</p>
<p>Find detailed information related to our Test Centre’s AP Exam Policy and Registration process and exam Fee in the PDF document listed below:</p>
           
   <br/>        
          
 <h4
              style={{
                color: "#0ca685",
                fontWeight: "bold",
                fontSize: "25px",
                fontFamily: "sans-serif",
              }}
            >
          AP 2025 REGISTRATION
            </h4>
            <br/>
              <p>
              Students can read the benefits of AP Exams & AP course descriptions at <a style={{ paddingRight: "80px", fontStyle: "italic", textDecorationLine: "underline"}} href="https://apstudents.collegeboard.org/coursesr">https://apstudents.collegeboard.org/courses</a>
</p>
  <p>
  Students must ensure that they have registered for all AP Exams, paid the exam fee to the AP test center, and completed the entire process mentioned in the AP Exam registration instructions by the deadlines listed. There will be no extensions to the deadlines allowed later.
  </p>
<br/>
         


          

      <br/>


        <ReactPlayer
        height= "40%"
         width="90%"
          url={ap}
          
          controls
          type="video/mp4"
        />
    

    <br/>
    <br/>





              
      </div>
    
  </main>
)



























 
