import React from "react"
import ReactPlayer from "react-player"
import Button from "react-bootstrap/Button"
import { Link } from "react-router-dom"
import { Grid } from "@material-ui/core"


export const Curriculum = () => (
  <main>
    <Grid container style={{ margin: "10px 2px" }}>
      <div
        className="teamContainer pageContainer pageContainer1 "
        style={{ marginBottom: "30px" }}
      >
        
      <h1
        style={{
          color: "#e95721",
          fontSize: "35px",
          fontWeight: "bold",
          fontFamily: "sans-serif",
        }}
      >
        Education at Cambridge International Foundation School
      </h1>

      <p>
        <i style={{ fontSize: "150%", color: "#0ca685" }}>
          Setting high standards of education for the future of kids.
        </i>
      </p>

      <div>
        <p>
          The Cambridge International Foundation School Curriculum has been
          designed keeping in mind the pedagogic needs of the regions our
          schools are based in and connecting it to international standards. Our
          curriculum has been developed by carrying out ongoing small-scale
          research projects, pilot projects and action research.
        </p>

        <p>
          We have tried to create a flexible curricular model that can be
          personalized to the needs of varied learners. Our curriculum not only
          covers academic areas but also covers the social, cultural issues and
          challenges that the 21st century poses for young people. Our students
          are free to choose from a range of curriculums.We offer National as
          well as International Curriculums.
        </p>
      </div>

      <br />
          <Grid item sm={12} xs={12} md={12}>
        <ReactPlayer url="https://www.youtube.com/watch?v=E9FlGtT61eA&feature=youtu.be" width="100%" />
      </Grid>
      <h2
        style={{
          color: "#e95721",
          fontWeight: "bold",
          fontSize: "23px",
          fontFamily: "sans-serif",
          paddingTop: "30px",
        }}
      >
        Why International Baccalaureate?
      </h2>
      <p>
        The International Baccalaureate®️ (IB) offers a continuum of
        international education. The programmes encourage both personal and
        academic achievement, challenging students to excel in their studies and
        in their personal development. Students at International Baccalaureate®️
        (IB) World Schools are given a unique education.
      </p>
      <p> They will:</p>
      <ul className="b">
        <li>
          be encouraged to think independently and drive their own learning.
        </li>
        <li>
          take part in programmes of education that can lead them to some of the
          highest-ranking universities around the world.
        </li>
        <li>
          become more culturally aware, through the development of a second
          language.
        </li>
        <li>
          be able to engage with people in an increasingly globalized, rapidly
          changing world.
        </li>
      </ul>

      <Grid item sm={12} xs={12} md={12}>
        <img src={require("../../assets/icons/attributes.png")} alt="pyp"  />
        </Grid>
        <h5
        align="center"
        style={{
          color: "#e95721",
          fontFamily: "Times",
          fontWeight: "bold",
          paddingTop: "20px",
        }}
      >
        <s>............................... </s>IB Programmes
        <s> ...............................</s>
      </h5>

      <br />

      <Grid container spacing={4} >
          <Grid item xs={12} sm={12} md={4}  align="center">
          
          <img src={require("../../assets/icons/ibpyp.png")} alt="pyp" className="curriculumImages" />

          
          <p style={{ fontSize: "15px",paddingTop:"20px" }}>
            The PYP for children aged 3 - 12 nurtures and develops young
            students as caring, active participants in a lifelong journey of
            learning through a student-centered approach to education.
          </p>

          
          <br />
          <div align="center">
            <Link to="/pyp" target="_blank">
              <Button
                className="button6"
                style={{ fontSize: "13px" }}
                variant="inherit"
              >
                Click for more
              </Button>{" "}
            </Link>
            </div>
          </Grid>

          <Grid item xs={12} sm={12} md={4}  align="center">

                
          <img src={require("../../assets/icons/ciae.png")} alt="pyp" className="curriculumImages" />

          
          <p style={{ fontSize: "15px", paddingTop:"20px" }}>
            CAIE is a part of the University of Cambridge, United Kingdom and share with them a commitment to excellence in education.CAIE offers programs in the primary, secondary and pre-university years.
          </p>

          
          <br />
          <div align="center">
            <Link to="/caie" target="_blank">
              <Button
                className="button6"
                style={{ fontSize: "13px" }}
                variant="inherit"
              >
                Click for more
              </Button>{" "}
            </Link>
          </div>
       </Grid>

          <Grid item xs={12} sm={12} md={4}  align="center">
          

          <img src={require("../../assets/icons/ibdplogo.png")} alt="pyp" className="curriculumImages"/>

          
          <p style={{ fontSize: "15px", paddingTop:"20px" }}>
            IBDP programme is an assessed programme for students aged 16 to 19 years.
            It provides programs of international education that focus on the 
            intellectual, emotional and social skills.
          </p>

          
          <br />
          <div align="center">
            <Link to="/ibdp" target="_blank">
              <Button
                className="button6"
                style={{ fontSize: "13px" }}
                variant="inherit"
              >
                Click for more
              </Button>{" "}
            </Link>
          </div>
        </Grid>
      </Grid>
      <br/>
      <br/>
      <p>
        <a
          href="https://www.ibo.org/school/049046/"
          style={{ fontWeight: "bold", fontSize: "15px" }}
        >
          Read about IB ⇝>
        </a>
      </p>
      <p>
        The International Baccalaureate®️ (IB) is a non-profit educational
        foundation, motivated by its mission, focused on the student. Our three
        programmes for students aged 3 to 19 help develop the intellectual,
        personal, emotional and social skills to live, learn and work in a
        rapidly globalizing world.
      </p>

      
       </div>
    </Grid>
  </main>
)
