import React, { useState } from "react"
import "./gallery.css"
import Images from "./images"

export default function Gallery() {
  const [selectedImg, setSelectedImg] = useState(Images[0])
  return (
    <div className="App">
      <div className="container">
        <img src={selectedImg} alt="Selected" className="selected mediaNone" />
        <div className="imgContainer" style={{ paddingTop: "30px" }}>
          {Images.map((img, index) => (
            <img
              style={{ border: selectedImg === img ? "4px solid #0ca685" : "" }}
              key={index}
              src={img}
              alt="Gallery"
              onClick={() => setSelectedImg(img)}
            />
          ))}
        </div>
      </div>
    </div>
  )
}
