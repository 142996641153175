import React from "react"
import ReactPlayer from "react-player"
import { Grid } from "@material-ui/core"
import { Helmet } from "react-helmet"

export const Pyp = () => (
  <main>
  <div>
    <Helmet>
    <title>
    PYP | Cambridge International Foundation School 

         </title>
    </Helmet>

    </div>
    <Grid container style={{ margin: "10px 2px" }}>
      <div
        className="teamContainer pageContainer pageContainer1 "
        style={{ marginBottom: "30px" }}
      >
        <h1
          style={{
            color: "#e95721",
            fontSize: "35px",
            fontWeight: "bold",
            fontFamily: "sans-serif",
          }}
        >
          Primary Years Programme
        </h1>
        <h2
          style={{
            color: "#e95721",
            fontWeight: "bold",
            fontSize: "23px",
            fontFamily: "sans-serif",
          }}
        >
          What is PYP?
        </h2>
        <p>
          <i style={{ fontSize: "150%", color: "#0ca685" }}>
            The Primary Years Programme (PYP) is a curriculum framework for
            young learners aged 3–12.
          </i>
        </p>
        <p>
          Fundamental to this learning environment is the development of a
          school wise information services where learners have opportunities to
          access multiple sources and forms of information and the appropriate
          technologies to do so. We have developed strong library and
          information services which are essential to a learning program that
          values the cognitive, cultural, social, affective, technological &
          spiritual development of students. Our Library also has internet based
          facility for searching the books.
        </p>
        <p className="card-text text-secondary">
          The PYP is underpinned by six transdisciplinary themes around which
          learning is planned.
        </p>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12} md={6}>
            <h4
              style={{
                color: "#0ca685",
                fontWeight: "bold",
                fontFamily: "sans-serif",
              }}
            >
              These are:
            </h4>
            <ul className="b">
              <li>Who we are</li>
              <li>Where we are in place and time</li>
              <li>How we express ourselves</li>
              <li>How the world works</li>
              <li>How we organize ourselves</li>
              <li>Sharing the planet</li>
            </ul>

            <p>
              These themes are selected for their relevance to the real world.
              They are described as transdisciplinary because they focus on
              issues that go across subject areas.
            </p>
             <p>
          The Primary Years Programme offers a comprehensive approach to
          teaching and learning. It provides a complete curriculum model which
          incorporates guidelines on what students should learn, as well as
          guidelines on teaching methodologies and assessment strategies.
        </p>
          </Grid>
          <Grid item xs={12} sm={12} md={6} className="imageAdjust">
            <br />
            <br />
            <img src={require("../assets/icons/pyp.png")} alt="pyp" />
          </Grid>
        </Grid>


    
       
        <h2
          className="spaceAdjust"
          align="center"
          style={{
            color: "#e95721",
            fontSize: "23px",
            fontWeight: "bold",
            fontFamily: "sans-serif",
          }}
        >
          Why ages 3-11 matter so much for brain development?
        </h2>

        <div className="row" style={{ paddingLeft: "60px" }}>
          <div className="col" id="bordering" className="container5">
            <img src={require("../assets/icons/image11.png")} alt="pyp" />

            <div className="overlay5">
              <img src={require("../assets/icons/image1.jpeg")} alt="pyp" />
            </div>
          </div>

          <div className="col" id="bordering" className="container5">
            <img src={require("../assets/icons/image12.png")} alt="pyp" />

            <div className="overlay5">
              <img src={require("../assets/icons/image2.jpg")} alt="pyp" />
            </div>
          </div>

          <div className="col" id="bordering" className="container5">
            <img src={require("../assets/icons/image13.png")} alt="pyp" />

            <div className="overlay5">
              <img src={require("../assets/icons/image3.jpg")} alt="pyp" />
            </div>
          </div>
        </div>
        <div
          className="row"
          style={{ paddingLeft: "60px", paddingTop: "30px" }}
        >
          <div className="col" id="bordering" className="container5">
            <img src={require("../assets/icons/image14.png")} alt="pyp" />

            <div className="overlay5">
              <img src={require("../assets/icons/image4.jpg")} alt="pyp" />
            </div>
          </div>

          <div className="col" id="bordering" className="container5">
            <img src={require("../assets/icons/image15.png")} alt="pyp" />

            <div className="overlay5">
              <img src={require("../assets/icons/image5.jpg")} alt="pyp" />
            </div>
          </div>

          <div className="col" id="bordering" className="container5">
            <img src={require("../assets/icons/image16.png")} alt="pyp" />

            <div className="overlay5">
              <img src={require("../assets/icons/image6.jpg")} alt="pyp" />
            </div>
          </div>
        </div>

        <p className="spaceAdjust" style={{ paddingTop: "30px" }}>
          <a
            href="https://www.ibo.org/programmes/primary-years-programme/"
            style={{ fontWeight: "bold", fontSize: "15px" }}
          >
            Read about PYP ⇝
          </a>
        </p>
        <p>
          PYP learners know how to take ownership of their learning,
          collaborating with teachers to deepen understanding and increase their
          confidence and self-motivation.
        </p>

        <Grid container spacing={1} style={{ margin: "10px 0px" }}>
          <Grid item sm={12} xs={12} md={6}>
            <ReactPlayer
              url="https://www.youtube.com/watch?v=1QsMzevN2rU"
              width="100%"
            />
          </Grid>

          <Grid item sm={12} xs={12} md={6}>
            <ReactPlayer
              url="https://www.youtube.com/watch?v=B_6vtA_U4d4"
              width="100%"
            />
          </Grid>
        </Grid>
      </div>
    </Grid>
  
        <a style={{
                marginLeft: "40px",
                  fontSize: "20px",
                  color: "#0000ff",
                  fontWeight: "bold",
                  fontStyle: "italic",
                  fontFamily: "sans-serif",
                  paddingTop: "10px",
                  paddingBottom: "20px"
                }} href="https://sites.google.com/view/cifspyp/home" target="_blank"><u>Primary Years Programme (Cambridge International Foundation School)</u></a>
        
  </main>
)
