export default [
  { title: "Art and Craft", link: "/infrastructure/art-and-craft" },
  { title: "Science Lab", link: "/infrastructure/science-lab" },
  { title: "ICT Lab", link: "/infrastructure/Ict-lab" },
  { title: "Maths Lab", link: "/infrastructure/maths-lab" },
  { title: "Dance and Music", link: "/infrastructure/dance-music" },
  { title: "Sports", link: "/infrastructure/Sports" },
  { title: "Library", link: "/infrastructure/Library" },
  { title: "Security", link: "/infrastructure/Security" },
]
