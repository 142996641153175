import React from "react"
import { Helmet } from "react-helmet"
import { Grid } from "@material-ui/core"


export const Fees = () => (
  <main>
  <div>
    <Helmet>
    <title>
     Pay Fees Online | Cambridge International Foundation School 

         </title>
    </Helmet>

    </div>
   <Grid container justify="center" style={{ margin: "10px 2px" }}>
        <div
          className="teamContainer pageContainer pageContainer1"
          style={{ marginBottom: "30px" }}
        >
      <h1
        style={{
          color: "#e95721",
          fontSize: "35px",
          fontWeight: "bold",
          fontFamily: "sans-serif",
        }}
      >
        Pay Fees Online
      </h1>

      <p className="card-text text-secondary">
        To pay fees online you will need your child's Student ID. If you do not
        know your child's Student ID, please reach out to the school. Follow the
        URLs below to proceed to PayTM payment gateway.
        <br />
        <strong>URL for Fee Payment – </strong>
        <a href="http://m.p-y.tm/cifsfee_web">http://m.p-y.tm/cifsfee_web</a>
        <br />
        <strong>URL for New Registration – </strong>
        <a href="http://m.p-y.tm/cifsnr_web">http://m.p-y.tm/cifsnr_web</a>
      </p>

      <p>
          <i style={{ fontSize: "110%", color: "#0ca685" }}>
            For any clarifications regarding fee, kindly visit school.
          </i>
        </p>

    </div>
    </Grid>
  </main>
)
