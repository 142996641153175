import React from "react"
import { Helmet } from "react-helmet"


export const Policies = () => (
  <main>
 

  <div>
    <Helmet>
    <title>
    Policies | Cambridge International Foundation School 

         </title>
    </Helmet>

    </div>
    <div className="row">
      <div className="col teamContainer pageContainer pageContainer1">
        <h1
          style={{
            color: "#e95721",
            fontSize: "35px",
            fontWeight: "bold",
            fontFamily: "sans-serif",
          }}
        >
          Policies
        </h1>

        
        <p>
         Here is the list of all the school policies.
        </p>
      </div>


    </div>
    <br/>
    <div className="row">
     <div className="col teamContainer pageContainer pageContainer1">
    
      <a target="_blank" href={require("../assets/policies/p1.pdf")}>Academic Integrity Policy </a>
      <br/>
      <a target="_blank" href={require("../assets/policies/p2.pdf")}>Admission policy</a>
      <br/>
      <a target="_blank" href={require("../assets/policies/p3.pdf")}>Assessment Policy</a>
      <br/>
      <a target="_blank" href={require("../assets/policies/p4.pdf")}>Complaint Policy</a>
      <br/>
      <a target="_blank" href={require("../assets/policies/p5.pdf")}>Language Policy</a>
      <br/>
      <a target="_blank" href={require("../assets/policies/p6.pdf")}>Library Policy</a>
      <br/>
      <a target="_blank" href={require("../assets/policies/p7.pdf")}>IT Policy</a>
      <br/>
      <a target="_blank" href={require("../assets/policies/p8.pdf")}>Inclusion Policy </a>
      <br/>
      <a target="_blank" href={require("../assets/policies/p9.pdf")}>PYP Parent Handbook</a>
      <br/>
      <a target="_blank" href={require("../assets/policies/p10.pdf")}>IBDP Booklet</a>
      <br/>
      <a target="_blank" href={require("../assets/policies/p11.pdf")}>CAS Handbook 2022-24</a>
    

      
     </div>
    </div>
    
  </main>
)
