import React from "react"
import { Helmet } from "react-helmet"
import Table from "react-bootstrap/Table"
import { Grid } from "@material-ui/core"

import ap from "../assets/video/ap.mp4"



import ReactPlayer from "react-player"

export const Sat = () => (
  <main>
  <div>
    <Helmet>
    <title>
     SAT Exams | Cambridge International Foundation School 

         </title>
    </Helmet>

    </div>
    <div className="pageContainer pageContainer1">
      <h1
        style={{
          color: "#000000",
          fontWeight: "bold",
          fontFamily: "sans-serif",
          fontSize: "35px",
        }}
      >
        SAT Exams
      </h1>

      
        <p> <i style={{ fontSize: "120%", color: "#00008b" }}>
         
          Cambridge International Foundation School, Jalandhar proudly announces that our School has been approved as International standardized testing examinations center from college board. This accreditation is a testament to the school's dedication to academic excellence and providing students with globally recognized opportunities for assessment and advancement. It's a significant achievement that will undoubtedly benefit both current and future students.
        </i>
      </p>

      
      
      
      <p>
        Offering PSAT/8/9/10, SAT, and AP examinations will enhance the educational experience for students, providing them the chance to demonstrate their academic abilities on a global scale. The respective opportunity helps students of all boards earn college credits and have a competitive edge in college admissions, career advancement and scholarships. Additionally, the option for AP classes covers a wide range of subjects, allowing students to delve deeper into their areas of interest and prepare for college-level coursework. Students who take the PSAT/NMSQT, PSAT 10, and PSAT 8/9 see grade-level benchmarks on their score reports. Grade-level benchmarks represent the section scores on each assessment that students should meet or exceed to be considered on track for college and career readiness. They're based on expected student growth toward SAT College and Career Readiness benchmarks at each grade. When a student takes the SAT, their score is compared to the College and Career Readiness benchmark, regardless of their current grade.
      </p>




      <br/>
      <br/>
      <br/>



      <Grid container spacing={1}>
          <Grid item xs={12} sm={12} md={6}>
            <h4
              style={{
                color: "#0ca685",
                fontWeight: "bold",
                fontSize: "25px",
                fontFamily: "sans-serif",
              }}
            >
              Structure of PSAT 8/9/10 and SAT
            </h4>
            

            <p>
              The digital SAT is composed of two sections: Reading and Writing and Math. Students have 64 minutes to complete the Reading and Writing section and 70 minutes to complete the Math section for a total of 2 hours and 14 minutes. 
Each section is divided into 2 equal length modules, and there is a 10-minute break between the Reading and Writing section and the Math section. The first module of each section contains a broad mix of easy, medium, and hard questions. 
Based on how students perform on the first module, the second module of questions will either be more difficult or less difficult.
            </p>
            
          </Grid>
          <Grid item xs={12} sm={12} md={6} className="imageAdjust">
            <br />
            <br />
            <img src={require("../assets/images/sat.JPG")} alt="SAT Exams" />
          </Grid>
        </Grid>
        


      

      
    <br/>
      <br/>
      <br/>



      <Table striped bordered hover size="sm" responsive="sm">
          <thead>
            <tr
              style={{
                color: "#BLACK",
                fontSize: "15px",
                fontWeight: "bold",
                fontFamily: "sans-serif",
              }}
            >
              <th className="transition"> SAT Sections 2024</th>
              <th>No. of Questions Tested</th>
              <th>Total Duration (mins)</th>
            </tr>
          </thead>
          <tbody>




          <tr className="card-text text-secondary">
              <td>Reading & Writing </td>
              <td> 54 </td>
              <td>64 </td>
            </tr>

            <tr className="card-text text-secondary">
              <td> Mathematics </td>
              <td> 44 </td>
              <td>70</td>
            </tr>

            <tr className="card-text text-secondary">
              <td>Total </td>
              <td> 98 </td>
              <td>134 </td>
            </tr>


            </tbody>
        </Table>
     
      

    <br/>
    <br/>



    
<p className="card-text text-secondary">
        <h1
        style={{

          paddingRight: "80px",
          color: "#000000",
          fontWeight: "bold",
          fontFamily: "sans-serif",
          fontSize: "20px",
        }}
      >
       Registration Link for SAT :
      </h1>
        
        <a style={{ paddingRight: "80px", fontStyle: "italic", textDecorationLine: "underline"}} href="https://satsuite.collegeboard.org/sat/registration">https://satsuite.collegeboard.org/sat/registration</a>
        </p>

        
        <br/>
      <br/>
   



      <Grid container spacing={1}>
          <Grid item xs={12} sm={12} md={6}>
            <h4
              style={{
                color: "#0ca685",
                fontWeight: "bold",
                fontSize: "25px",
                fontFamily: "sans-serif",
              }}
            >
              PSAT 
            </h4>
            

            <p>
              Our school is first centre in Jalandhar to conduct PSAT examinations administered by college board. For PSAT all information will be uploaded in the month of July. You can register from our School.
            </p>
          </Grid>
          <Grid item xs={12} sm={12} md={6} className="imageAdjust">
            <br />
            <br />
            <img src={require("../assets/images/psat.JPG")} alt="PSAT Exams" />
          </Grid>
        </Grid>


       <br/>
      <br/>
       <br/>
      


    <br/>
    <br/>








      </div>
    
  </main>
)



























 
