import React from "react"
import { Helmet } from "react-helmet"

export const CAO = () => (
  <main>

  <div>
    <Helmet>
    <title>
    CAO's Message | Cambridge International Foundation School 

         </title>
    </Helmet>

    </div>

    <div className="row">
      <div className="col teamContainer pageContainer pageContainer1">
        <h1
          style={{
            color: "#e95721",
            fontSize: "35px",
            fontWeight: "bold",
            fontFamily: "sans-serif",
          }}
        >
          CAO's Message
        </h1>

        <p>
          Thank you for your interest in Cambridge International Foundation
          School. Within these pages we hope to convey the spirit of our
          school-its warmth, purpose, thought and soul.
        </p>
        <p>
          We intend to provide your child the information needed to make a
          decision. Cambridge International School is big enough to possess
          prime facilities and yet small enough to identify and provide to the
          individual learner. We believe that every child deserves and needs a
          high quality, broad based education in order to succeed in life.
        </p>
      </div>
      <div
        className="col"
        className="pageContainer pageContainer1 teamImage spaceAdjust"
      >
        <img
          style={{ border: "2.5px solid #fcbc3c" }}
          src={require("../assets/images//deepaDograSquare.jpg")}
          alt="Ms. Deepa Dogra"
          loader="gradient"
          Width="300px"
          Height="300px"
          gradientPreset="life"
        />
        <p align="center">
          <strong>Ms. Deepa Dogra</strong>
          <br />
          CAO
        </p>
      </div>
    </div>
    <div className="teamContainer pageContainer pageContainer1 spaceAdjust">
      <p>
        Learning opportunities are planned and executed to instill academic
        competence as well as acquire skills in order to confidently and
        productively participate in the rapidly changing times without losing
        the sight or personal and universal goals.
      </p>
      <p>
        Cambridge International Schools aim to be with them in the journey of
        self-discovering during the school years and motivate them to set higher
        standards in all spheres of life.
      </p>
      <p>
        We invite you to experience the encouraging, concerned and joyous
        atmosphere here. The team would be happy to take you around the school.
      </p>
      <p>
        <strong>Warm regards,</strong>
        <br />
        <strong>Deepa Dogra</strong>
      </p>
    </div>
  </main>
)
