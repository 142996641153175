import React from "react"
import ReactPlayer from "react-player"
import { Grid } from "@material-ui/core"
import { Helmet } from "react-helmet"

export const Caie = () => (
  <main>
  <div>
    <Helmet>
    <title>
    CAIE | Cambridge International Foundation School 

         </title>
    </Helmet>

    </div>
    <Grid container style={{ margin: "10px 2px" }}>
      <div
        className="teamContainer pageContainer pageContainer1"
        style={{ marginBottom: "30px" }}
      >
        <h1
          style={{
            color: "#e95721",
            fontSize: "35px",
            fontWeight: "bold",
            fontFamily: "sans-serif",
          }}
        >
          Cambridge Assessment International Education (CAIE)
        </h1>

        <p>
          CAIE is a part of the University of Cambridge, United Kingdom and
          share with them a commitment to excellence in education. CAIE is
          recognized by the Office of Qualifications and Examination Regulations
          (Ofqual) the regulator of examinations in England to award
          qualifications. CAIE offers programs in the primary, secondary and
          pre-university years.
        </p>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12} md={6}>
            <h2
              className="spaceAdjust"
              style={{
                color: "#e95721",
                fontSize: "23px",
                fontWeight: "bold",
                fontFamily: "sans-serif",
              }}
            >
              Key Characteristics —
            </h2>

            <ul className="b">
              <li>World class standard education</li>
              <li>Progressive and flexible programs</li>
              <li>Multiple subject options</li>
              <li>Challenging assessment high success outcomes</li>
              <li>Balance of theory and practicals</li>
              <li>
                University of Cambridge qualification certificates from Grade 6
                onwards
              </li>
              <li>Community service</li>
            </ul>
          </Grid>

          <Grid item xs={12} sm={12} md={6} className="imageAdjust">
            <img src={require("../assets/icons/components.png")} alt="CAIE" />

            <h2
              align="center"
              style={{
                color: "#e95721",

                fontWeight: "bold",
                fontFamily: "sans-serif",
                fontSize: "20px",
              }}
            >
              4 components of Cambridge
            </h2>
          </Grid>
        </Grid>

        <p className="spaceAdjust">
          <a
            href="https://www.ibo.org/school/049046/"
            style={{ fontWeight: "bold", fontSize: "15px" }}
          >
            Read about CAIE ⇝
          </a>
        </p>

        <p>
          At Cambridge English, we provide the world’s leading range of
          qualifications and tests for learners and teachers of English.
          Globally-recognised by more than 20,000 leading universities,
          employers and governments, our research-based assessments are a mark
          of excellence that open doors.
        </p>

        <Grid container spacing={1} style={{ margin: "10px 0px" }}>
          <Grid item sm={12} xs={12} md={6}>
            <ReactPlayer url="https://youtu.be/ZQWQHQmwxY0" width="100%" />
          </Grid>

          <Grid item sm={12} xs={12} md={6}>
            <ReactPlayer
              url="https://www.youtube.com/watch?v=mcH3ngjsH44"
              width="100%"
            />
          </Grid>
        </Grid>

        <Grid container spacing={1} style={{ margin: "10px 0px" }}>
          <Grid item sm={12} xs={12} md={6}>
            <ReactPlayer
              url="https://www.youtube.com/watch?v=LHCbNzV50BU"
              width="100%"
            />
          </Grid>

          <Grid item sm={12} xs={12} md={6}>
            <ReactPlayer
              url="https://www.youtube.com/watch?v=vrfx_akpd0U"
              width="100%"
            />
          </Grid>
        </Grid>
      </div>
    </Grid>
  </main>
)
