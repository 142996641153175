import React from "react"
import { Helmet } from "react-helmet"

export const Calender = () => (
  <main>
  
  <div>
    <Helmet>
    <title>
    Calender | Cambridge International Foundation School 

         </title>
    </Helmet>

    </div>
    <div style={{ marginLeft: "60px" }}>
      <h1
        style={{
          color: "#e95721",
          fontSize: "35px",
          fontWeight: "bold",
          fontFamily: "sans-serif",
        }}
      >
        School Calender 2021-22
      </h1>

      <iframe
        title="Calendar"
        src="https://calendar.google.com/calendar/embed?src=1qg66j04k2tqjirdcp7hkk7bmk%40group.calendar.google.com&ctz=Asia%2FKolkata"
        style={{ borderWidth: 0 }}
        width="800"
        height="600"
        frameBorder="0"
        scrolling="no"
      />
    </div>
  </main>
)
