import React from "react"
import { Grid } from "@material-ui/core"
import { Helmet } from "react-helmet"


export const Policy = () => (
  <main>
  <div>
    <Helmet>
    <title>
     Policy & Procedure | Cambridge International Foundation School 

         </title>
    </Helmet>

    </div>

    <Grid container justify="center" style={{ margin: "10px 2px" }}>
      <div
        className="teamContainer pageContainer pageContainer1"
        style={{ marginBottom: "30px" }}
      >
        <h1
          style={{
            color: "#e95721",
            fontSize: "35px",
            fontWeight: "bold",
            fontFamily: "sans-serif",
          }}
        >
          Welcome to the Admissions Desk
        </h1>

        <p>
          Choosing the right school for our children is not an easy task. This
          is why we believe that the first step of the admission process should
          be to visit our school and get a feel of the school.
        </p>
        <p>
          Today, Cambridge International Foundation School is an institution
          where global education is seamlessly integrated with positive social
          values, coupled with a creative freedom that equips every student with
          necessary life skills.
        </p>
        <p>
          Our suggestion to all of you is to surf our school’s website
          thoroughly. The website spells out most of the information that
          parents and students need to know before applying. After checking the
          admission procedure, feel free to send us an e-mail for further
          details. We will be happy to give you all the information you require
          and to get you to know us better.
        </p>
        <p>We look forward to meeting you!</p>

        <p>
          <strong>Warm regards,</strong>
          <br />
          <strong>The Admission Office</strong>
        </p>
        <br />

        <h1
          style={{
            color: "#e95721",
            fontSize: "35px",
            fontWeight: "bold",
            fontFamily: "sans-serif",
          }}
        >
          Dates for Admission
        </h1>
        <p>
          <i style={{ fontSize: "110%", color: "#0ca685" }}>
            Admission for the session 2024-25 has been started.
          </i>{" "}
          We are accepting the applications for all classes. For any further
          details you can contact us or visit our school.
        </p>
        <p>
          The deadline for admission is March, this can be subject to change. We
          will update our website for any changes in dates or information about
          the seats. If the seats have been fulfilled, we will notify it on our
          official website and other media sources.
        </p>
      </div>
    </Grid>
  </main>
)
