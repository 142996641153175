import React from "react"



import { Grid } from "@material-ui/core"
import { Helmet } from "react-helmet"


export const NewsLetter = () => (
  <main>
  <div>
    <Helmet>
    <title>
    Newsletter | Cambridge International Foundation School 

         </title>
         
    </Helmet>
    
    <iframe src="https://newsletter-cifs.netlify.app/" height="1000" width="100%" loading='eager' allowfullscreen="true" title="NewsLetter"></iframe>
    </div>
    
  </main>
)

