import React from "react"
import { Helmet } from "react-helmet"

// ----------------------------------------------------------------------------
// ------------------------------------------------------------------ Component
// ----------------------------------------------------------------------------
/** Page */
export const ChairmanPage = () => (
  <main>

  <div>
    <Helmet>
    <title>
    Chairman's Message | Cambridge International Foundation School 

         </title>
    </Helmet>

    </div>
    <div className="row">
      <div className="col teamContainer pageContainer pageContainer1">
        <h1
          style={{
            color: "#e95721",
            fontSize: "35px",
            fontWeight: "bold",
            fontFamily: "sans-serif",
          }}
        >
          {" "}
          Chairman's Message
        </h1>

        <p>
          We are living in extraordinary times. Upheavals and intentions surpass
          our imagination. We at Learning Wings Education Systems TM nurture a
          dream to provide education that helps our students to be able to dream
          to be the instruments of change and do best as per their capacity.
        </p>
        <p>
          We aim to faster and fiercely protect the bright spontaneity in each
          child. Our commitment is to make learning a joyous journey and not a
          burden.
        </p>
      </div>
      <div
        className="col "
        className="pageContainer pageContainer1 teamImage spaceAdjust"
      >
        <img
          style={{ border: "2.5px solid #fcbc3c" }}
          src={require("../assets/images//ajayBhatiaAltSquare.jpg")}
          alt="Mr. Ajay Bhatia"
          loader="gradient"
          Width="300px"
          Height="300px"
          gradientPreset="life"
        />
        <p align="center">
          <strong>Mr. Ajay Bhatia</strong>
          <br />
          Chairman
        </p>
      </div>
    </div>
    <div className="teamContainer pageContainer pageContainer1">
      <p className="spaceAdjust">
        <i style={{ fontSize: "150%", color: "#0ca685" }}>
          "Our commitment is to make learning a joyous journey and not a
          burden."
        </i>
      </p>
      <p>
        Children will be encouraged to develop their instruments of knowledge by
        using all possible sources from modern technology to traditional methods
        and gain essential skills to be successful in whatever they choose to
        be.
      </p>
      <p>
        World-class facilities in sensitively designed classrooms to
        state-of-the-art play grounds are the extension of our ethos to provide
        learners with the best in all areas of learning. Students should feel
        challenged, became inquirers and help redefine the conventions and
        prejudices to be pioneers of causes for all aspects of life.
      </p>
      <p>
        On behalf of LWES team, I welcome you to our school and assure our
        commitment to provide best education.
      </p>
      <p>
        <strong>Warm regards,</strong>
        <br />
        <strong>Ajay Bhatia</strong>
      </p>
    </div>
  </main>
)
