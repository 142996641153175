import React from "react"
import { Grid } from "@material-ui/core"
import { Helmet } from "react-helmet"

export const DanceMusic = () => (
  <main>
  <div>
    <Helmet>
    <title>
    Dance & Music | Cambridge International Foundation School 

         </title>
    </Helmet>

    </div>
    <Grid container justify="center" style={{ margin: "10px 2px" }}>
      <div
        className="teamContainer pageContainer pageContainer1"
        style={{ marginBottom: "30px" }}
      >
        <h1
          style={{
            color: "#e95721",
            fontSize: "35px",
            fontWeight: "bold",
            fontFamily: "sans-serif",
          }}
        >
          Dance and Music
        </h1>
        <p>
          Music in totality seems to be the song of the soul. It helps to
          provide wings to the mind to take off a flight in the world of rhyme
          with the help of imagination. A well-furnished Music room boasts of
          its reverence in providing the students prospects to explore and
          create their own composition.
        </p>
        <p>
          <i style={{ fontSize: "150%", color: "#0ca685" }}>
            "Music and Dance is the strongest form of magic."
          </i>
        </p>

        <div className="row">
          <div className="col">
            <img
              style={{
                border: "solid #fcbc3c",
              }}
              src={require("../../assets/images/musicdance1.jpg")}
              alt="Our-Story"
              loader="gradient"
              gradientPreset="life"
            />
          </div>

          <div className="col">
            <img
              style={{
                border: "solid #fcbc3c",
              }}
              src={require("../../assets/images/musicdance4.jpg")}
              alt="Our-Story"
              loader="gradient"
              gradientPreset="life"
            />
          </div>
          <div className="col">
            <img
              style={{
                border: "solid #fcbc3c",
              }}
              src={require("../../assets/images/musicdance3.jpg")}
              alt="Our-Story"
              loader="gradient"
              gradientPreset="life"
            />
          </div>
        </div>

        <div className="row">
          <div className="col">
            <img
              style={{
                border: "solid #fcbc3c",
              }}
              src={require("../../assets/images/musicdance2.jpg")}
              alt="Our-Story"
              loader="gradient"
              gradientPreset="life"
            />
          </div>

          <div className="col">
            <img
              style={{
                border: "solid #fcbc3c",
              }}
              src={require("../../assets/images/musicdance5.jpg")}
              alt="Our-Story"
              loader="gradient"
              gradientPreset="life"
            />
          </div>
        </div>
      </div>
    </Grid>
  </main>
)
