import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import { Grid, TextField, TextareaAutosize } from "@material-ui/core"
import { Formik, Field, Form } from "formik"
import Button from "react-bootstrap/Button"
import { Helmet } from "react-helmet"


const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),

    color: theme.palette.text.secondary,
  },
  enquiry: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
    height: "100%",
  },
  textFieldContainer: {
    height: 15,
    padding: 8,
  },
  textAreaContainer: {
    padding: 8,
    width: "100%",
  },
}))

export const Admission = () => {
  const classes = useStyles()
  return (
    <>
    <div>
    <Helmet>
    <title>
    Apply Online | Cambridge International Foundation School 

         </title>
    </Helmet>

    </div>
      <Grid container justify="center" style={{ margin: "10px 2px" }}>
        <div
          className="teamContainer pageContainer pageContainer1"
          style={{ marginBottom: "30px" }}
        >
          <h1
            style={{
              color: "#e95721",
              fontSize: "35px",
              fontWeight: "bold",
              fontFamily: "sans-serif",
            }}
          >
            Admission Process
          </h1>

          <p>
            From 2020 our admission process is digitised for your convenience.
            We follow the regular process every year. The candidate must be
            registered prior to the closing admission date. The last date will
            be announced by the school. But usually this is in March.
          </p>
          <ol>
            <li>
              <strong style={{ color: "#0ca685" }}>
                Fill our the intent form:
              </strong>{" "}
              Please fill out the intent form below and let us know your
              interest in admissions to our school. We will get back to you
              within 2 weeks with an appointment date if there are seats
              available.
            </li>
            <li>
              <strong style={{ color: "#0ca685" }}>Meet us in-person:</strong>{" "}
              We would like to meet the candidate and you, their parents or
              guardians. You can collect the prospectus, a detailed
              registeration form and more documents from the school on the same
              day. There is a small fee for the prospectus.
            </li>
            <li>
              <strong style={{ color: "#0ca685" }}>
                We will send you a response in 2 weeks:{" "}
              </strong>{" "}
              After our appointment you will get a response from us within 2
              weeks.
            </li>
            <li>
              <strong style={{ color: "#0ca685" }}>
                Pay fees and submit all documents to accept your offer:
              </strong>{" "}
              If the candidate is accepted by us you will have 2 weeks to settle
              the first fee installment.
            </li>
          </ol>

          <h1
            style={{
              color: "#e95721",
              fontSize: "35px",
              fontWeight: "bold",
              fontFamily: "sans-serif",
            }}
          >
            Intent Form
          </h1>
          <Formik
            initialValues={{ email: "", password: "" }}
            validate={values => {
              const errors = {}
              if (!values.email) {
                errors.email = "Required"
              } else if (
                !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
              ) {
                errors.email = "Invalid email address"
              }
              return errors
            }}
            onSubmit={(values, { setSubmitting }) => {
              let {
                email,
                comment,
                mobileNumber,
                candidateName,
                admissionsSoughtFor,
                password,
                guardianName,
              } = values

              // Mock some delay
              setTimeout(() => {
                fetch(
                  `https://script.google.com/macros/s/AKfycbyS-tK6CkAUFcUb9O2sNEtkYZgH-J8-EUzn8QH0fCqmoHL_O1I/exec?email=${email}&name=${guardianName}&comment=${comment}&candidateName=${candidateName}&admissionsSoughtFor=${admissionsSoughtFor}&mobileNumber=${mobileNumber}&callback=?`,
                  {
                    method: "GET",
                    mode: "no-cors",
                  }
                )
                  .then(response => {
                    setSubmitting(false)
                  })
                  .catch(error => {
                    setSubmitting(false)
                  })
              }, 0)
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
            }) => (
              <Form onSubmit={handleSubmit}>
                <Grid
                  container
                  spacing={3}
                  className={classes.formInputContainer}
                  justify="center"
                >
                  <Grid item xs={10} lg={8} xl={8}>
                    <Field name="guardianName">
                      {({ field, meta }) => (
                        <TextField
                          placeholder="Parent or Guardian's name"
                          variant="outlined"
                          required
                          error={meta.touched && meta.error ? true : false}
                          inputProps={{
                            className: classes.textFieldContainer,
                          }}
                          fullWidth
                          helperText={
                            meta.touched && meta.error ? meta.error : ""
                          }
                          {...field}
                        />
                      )}
                    </Field>
                  </Grid>
                  <Grid item xs={10} lg={8} xl={8}>
                    <Field name="email">
                      {({ field, meta }) => (
                        <TextField
                          placeholder="Parent or Guardian's email"
                          variant="outlined"
                          required
                          error={meta.touched && meta.error ? true : false}
                          inputProps={{
                            className: classes.textFieldContainer,
                          }}
                          fullWidth
                          helperText={
                            meta.touched && meta.error ? meta.error : ""
                          }
                          {...field}
                        />
                      )}
                    </Field>
                  </Grid>
                  <Grid item xs={10} lg={8} xl={8}>
                    <Field name="mobileNumber">
                      {({ field, meta }) => (
                        <TextField
                          placeholder="Parent or Guardian's mobile number"
                          variant="outlined"
                          required
                          error={meta.touched && meta.error ? true : false}
                          inputProps={{
                            className: classes.textFieldContainer,
                          }}
                          fullWidth
                          helperText={
                            meta.touched && meta.error ? meta.error : ""
                          }
                          {...field}
                        />
                      )}
                    </Field>
                  </Grid>
                  <Grid item xs={10} lg={8} xl={8}>
                    <Field name="candidateName">
                      {({ field, meta }) => (
                        <TextField
                          placeholder="Candidate's Name"
                          variant="outlined"
                          required
                          error={meta.touched && meta.error ? true : false}
                          inputProps={{
                            className: classes.textFieldContainer,
                          }}
                          fullWidth
                          helperText={
                            meta.touched && meta.error ? meta.error : ""
                          }
                          {...field}
                        />
                      )}
                    </Field>
                  </Grid>
                  <Grid item xs={10} lg={8} xl={8}>
                    <Field name="admissionsSoughtFor">
                      {({ field, meta }) => (
                        <TextField
                          placeholder="Class to which admission is sought for"
                          variant="outlined"
                          required
                          error={meta.touched && meta.error ? true : false}
                          inputProps={{
                            className: classes.textFieldContainer,
                          }}
                          fullWidth
                          helperText={
                            meta.touched && meta.error ? meta.error : ""
                          }
                          {...field}
                        />
                      )}
                    </Field>
                  </Grid>
                  <Grid item xs={10} lg={8} xl={8}>
                    <Field name="comment">
                      {({ field, meta }) => (
                        <TextareaAutosize
                          rowsMax={3}
                          placeholder="When would you like to meet us? Do you have any comments or questions for us? Do you have any specific requests?"
                          variant="outlined"
                          required
                          error={meta.touched && meta.error ? true : false}
                          inputProps={{
                            className: classes.textAreaContainer,
                          }}
                          style={{ width: "100%" }}
                          helperText={
                            meta.touched && meta.error ? meta.error : ""
                          }
                          {...field}
                        />
                      )}
                    </Field>
                  </Grid>
                </Grid>
                <Grid
                  container
                  spacing={3}
                  justify="center"
                  className={classes.formInputContainer}
                >
                  <Grid item xs="auto">
                    <Button
                      className="button6"
                      variant="inherit"
                      type="submit"
                      disabled={isSubmitting}
                    >
                      Submit
                    </Button>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>

          <h1
            style={{
              color: "#e95721",
              fontSize: "35px",
              fontWeight: "bold",
              fontFamily: "sans-serif",
            }}
          >
            Documents Required
          </h1>
          <p>
            Please note that we do not consider an application complete until
            all these documents have been submitted.
          </p>
          <ul>
            <li>
              Three Photographs of the student (one attached to application
              form)
            </li>
            <li>One photograph each of Mother & Father</li>
            <li>One photograph of Guardian if parents are living abroad</li>
            <li>Aadhar Card copy</li>
            <li>
              Attested copy of birth certificate of the student clearly
              specifying date of birth (issued by Municipal Corporation or any
              other Competent Authority)
            </li>
            <li>
              Transfer Certificate (if applicable) from the previous school
            </li>
            <li>Report/ Mark sheet needed from the previous school</li>
          </ul>
        </div>
      </Grid>
    </>
  )
}
