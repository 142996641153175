import React from "react"
import Gallery from "../gallery"
import t21 from "../../assets/titleimage/t21.png"
import { Grid } from "@material-ui/core"
import { Helmet } from "react-helmet"


export const Achievements = () => (
  <main>
  <div>
    <Helmet>
    <title>
    Achievements | Cambridge International Foundation School 

         </title>
    </Helmet>

    </div>
    
    <Grid container style={{ margin: "10px 2px" }}>
      <div
        className="teamContainer pageContainer pageContainer1 pageContainer3 alumniVideo"
        style={{ marginBottom: "30px" }}
      >
        <h1
          style={{
            color: "#e95721",
            fontSize: "35px",
            fontWeight: "bold",
            fontFamily: "sans-serif",
          }}
        >
          Awards & Achievements
        </h1>
        


        <div className="row">
      <div className="col teamContainer pageContainer pageContainer1">
      <i style={{ fontSize: "120%", color: "#black", paddingTop: "40px", paddingTop: "60px"}}>
        

      In the spirit of seizing opportunities rather than waiting for them to knock, the stage was set for Casthenic 2.0, a competition hosted by the esteemed DPS Gaziabad, an IB World School.
        Our students showcased their mettle, emerging victorious in not one, but two challenging categories: Future ICON and Quick Wit Quest. 
We extend our warmest congratulations to the five talented students from CIFS and we tip our hats to the esteemed parents who place their faith in our international curriculum and our school's philosophy, which in the words of Nelson Mandela, encourages students to 'shine as brightly as they were meant to, no matter where they are in the world.'
Their dedication and hard work paid off as 𝐆𝐮𝐧𝐢𝐭 𝐊𝐚𝐮𝐫 and 𝐃𝐢𝐥𝐩𝐫𝐞𝐞𝐭 𝐊𝐚𝐮𝐫 secured an impressive 2nd place in the Quickwit Quest. Additionally, 𝐒𝐚𝐫𝐛𝐣𝐢𝐭 𝐒𝐢𝐧𝐠𝐡 𝐁𝐚𝐥 𝐚𝐧𝐝 𝐉𝐚𝐬𝐫𝐚𝐣 𝐊𝐚𝐥𝐫𝐚 showed their quick wit and intellect, earning 3rd place in the Quickwit Quest as well. 
𝐔𝐩𝐤𝐢𝐫𝐚𝐭 𝐒𝐢𝐧𝐠𝐡 𝐌𝐮𝐥𝐭𝐚𝐧𝐢 demonstrated exceptional talent in the Future Icon category, bagging 2nd place.  
        </i>
        </div>
        

        <div className="row">
      <div className="col teamContainer pageContainer pageContainer1">
      <div align="left">
        <img
         
          style={{
            background: "transparent",
           
          }}
          src={require("../../assets/icons/achieve8.jpg")}
          alt="Our-Story"
          loader="gradient"
          gradientPreset="life"
          width={400} height={400}
        />
        </div>



        


        <br/>
        <br/>
        </div>
        </div>
        </div>






<div className="row">
      <div className="col teamContainer pageContainer pageContainer1">
      <i style={{ fontSize: "120%", color: "#black", paddingTop: "40px", paddingTop: "60px"}}>
        CIFS is thrilled to announce and celebrate the remarkable achievements of our talented student-athletes in various sports events. These achievements not only reflect their dedication and hard work but also bring honor to our school.
Here are some notable accomplishments by our students:
𝐎𝐯𝐢𝐲𝐚 𝐀𝐫𝐨𝐫𝐚 (IBDP JR)- 23rd district skating championship 
Gold medal- in 500m and in 1000m. 
𝐊𝐮𝐦𝐚𝐫 𝐒𝐡𝐚𝐮𝐫𝐲𝐚 (CHECKPOINT-7)-Runner up in district badminton championship organized by INDIAN OIL.
These achievements are a testament to the commitment and perseverance of our students, as well as the support and guidance provided by our dedicated coaches and staff.
        </i>
        </div>
        

        <div className="row">
      <div className="col teamContainer pageContainer pageContainer1">
      <div align="left">
        <img
         
          style={{
            background: "transparent",
           
          }}
          src={require("../../assets/icons/achieve9.jpg")}
          alt="Our-Story"
          loader="gradient"
          gradientPreset="life"
          width={600} height={300}
        />
        </div>



        


        <br/>
        <br/>
        </div>
        </div>
        </div>





<div className="row">
      <div className="col teamContainer pageContainer pageContainer1">
      <i style={{ fontSize: "120%", color: "#black", paddingTop: "40px", paddingTop: "60px"}}>
        CIFS is elated to announce the exceptional achievement of our IBDP school team in the IB League contest hosted at Jayshree Periwal International School, Jaipur. Through steadfast commitment, ideation, and collaboration, our students demonstrated their prowess across the domains of STEM (Science, Technology, Engineering, and Mathematics), Entrepreneurship, and Art.
The STEM team, comprised of 𝐀𝐫𝐨𝐡𝐢 𝐌𝐚𝐡𝐚𝐣𝐚𝐧, 𝐒𝐚𝐫𝐛𝐣𝐢𝐭 𝐒𝐢𝐧𝐠𝐡 𝐁𝐚𝐥, 𝐆𝐮𝐧𝐢𝐭 𝐊𝐚𝐮𝐫, 𝐚𝐧𝐝 𝐃𝐢𝐥𝐩𝐫𝐞𝐞𝐭 𝐊𝐚𝐮𝐫, who chose the theme "Energy from lightning" and introduced "Power Spark," an ingenious initiative for a power generation plant in Odisha. Their project not only demonstrated a deep understanding of complex concepts but also highlighted their ability to apply theoretical knowledge to real-world challenges. 
The entrepreneurship team, consisting of 𝐕𝐚𝐧𝐬𝐡 𝐑𝐚𝐣 𝐆𝐚𝐮𝐭𝐚𝐦, 𝐑𝐡𝐲𝐭𝐡𝐦 𝐒𝐨𝐮𝐥, 𝐉𝐚𝐬𝐦𝐢𝐫𝐚𝐡 𝐊𝐚𝐮𝐫, 𝐂𝐡𝐡𝐚𝐯𝐢, 𝐚𝐧𝐝 𝐉𝐚𝐬𝐫𝐚𝐣, conceived the ground-braking platform "Edu Sense," seamlessly fusing Education and Mental Health with cutting-edge AI technology.
The art team was leaded by 𝐎𝐯𝐢𝐲𝐚 𝐀𝐫𝐨𝐫𝐚, who created a canvas masterpiece spotlighting Women Empowerment and resilience.   
        </i>
        </div>
        

        <div className="row">
      <div className="col teamContainer pageContainer pageContainer1">
      <div align="left">
        <img
         
          style={{
            background: "transparent",
           
          }}
          src={require("../../assets/icons/achieve10.jpg")}
          alt="Our-Story"
          loader="gradient"
          gradientPreset="life"
          width={600} height={300}
        />
        </div>



        


        <br/>
        <br/>
        </div>
        </div>
        </div>





<div className="row">
      <div className="col teamContainer pageContainer pageContainer1">
      <i style={{ fontSize: "120%", color: "#black", paddingTop: "40px", paddingTop: "60px"}}>
        CIFS is delighted to announce the wonderful results of Chandigarh Speech & Debate League 2023, one of the most prestigious public speaking and debating tournaments in the Chandigarh region which was organized by the Indian Debating League. Our students *𝐒𝐚𝐢𝐧𝐚 𝐆𝐚𝐫𝐠 , 𝐍𝐚𝐧𝐝𝐢𝐧𝐢 𝐀𝐠𝐠𝐚𝐫𝐰𝐚𝐥 from PYP 5 and 𝐘𝐮𝐯𝐫𝐚𝐣 𝐆𝐮𝐩𝐭𝐚 from Checkpoint VI* had participated in World School Debating Championship (WSDC) under Junior Category in this event and  won *Gold Medals*.
We are super proud of our students who participated with utmost zeal and passion.🎉🎊🎖️🌟 
        </i>
        </div>
        

        <div className="row">
      <div className="col teamContainer pageContainer pageContainer1">
      <div align="left">
        <img
         
          style={{
            background: "transparent",
           
          }}
          src={require("../../assets/icons/achieve11.jpg")}
          alt="Our-Story"
          loader="gradient"
          gradientPreset="life"
          width={600} height={400}
        />
        </div>



        


        <br/>
        <br/>
        </div>
        </div>
        </div>


        <div className="row">
      <div className="col teamContainer pageContainer pageContainer1">
      <i style={{ fontSize: "120%", color: "#black", paddingTop: "40px", paddingTop: "60px"}}>
        SOF olympiad is a prestigious international competitive exam for school students. It aims to promote scientific reasoning, logical thinking, and analytical skills among young minds. 
Going further with it, the students of CIFS made their mark in SOF Olympiad. The following students have brought laurels to the school:
Naman Gupta from Grade 3 has achieved International Rank 2 and Zonal Rank 2 in SOF International Mathematics Olympiad in Punjab/ Chandigarh zone. His awards include IMO International Silver Medal, Gift Certificate voucher for Rs. 1000/-, Certificate of outstanding performance and Certificate of Merit in National Science Olympiad.
Ridhaan Garg from Grade 6 has achieved Certificate of Zonal Excellence in International Mathematics Olympiad.
Gunnar Kaur from Grade 3 has achieved Certificate of Merit in IMO.
Yuvansh Gupta from Grade 3 has achieved Certificate of Merit in IMO.
        </i>
        </div>
        

        <div className="row">
      <div className="col teamContainer pageContainer pageContainer1">
      <div align="left">
        <img
         
          style={{
            background: "transparent",
           
          }}
          src={require("../../assets/icons/achieve12.jpg")}
          alt="Our-Story"
          loader="gradient"
          gradientPreset="life"
          width={400} height={400}
        />
        </div>



        


        <br/>
        <br/>
        </div>
        </div>
        </div>






         <div className="row">
      <div className="col teamContainer pageContainer pageContainer1">
      <i style={{ fontSize: "120%", color: "#black", paddingTop: "40px", paddingTop: "60px"}}>
        Our swift swimmers, 𝗦𝗮𝗻𝗮 𝗡𝗮𝗿𝗮𝗻𝗴 (𝗜𝗚𝗖𝗦𝗘-𝗫) and 𝗔𝗿𝗼𝗵𝗶 𝗠𝗮𝗵𝗮𝗷𝗮𝗻 (𝗜𝗕𝗗𝗣-𝗫𝗜), made our school proud at the District Swimming Championship held at Sports College, Jalandhar on 25th July 2023. 
Sana Narang achieved gold medals in 100m backstroke, 50 mts freestyle and 50 mts backstroke; a silver medal in 50m butterfly; a bronze medal in 200 mts individual medley. 
Arohi Mahajan won an impressive 2 gold medals in 200 mts freestyle and 400 mts freestyle; 2 silver medals in 100 mts freestyle and 100 mts backstroke; one bronze medal in 100 mts breaststroke. 
The commissioner division Jalandhar honoured the students with first position trophy for under-17 girls in Jalandhar district. 
Both swimmers showed great excellence and also appeared for the 34th Sub-Junior and 46th Junior Punjab State Swimming championship, held at Mohali.
        </i>
        </div>
        

        <div className="row">
      <div className="col teamContainer pageContainer pageContainer1">
      <div align="left">
        <img
         
          style={{
            background: "transparent",
           
          }}
          src={require("../../assets/icons/achieve13.jpg")}
          alt="Our-Story"
          loader="gradient"
          gradientPreset="life"
          width={600} height={500}
        />
        </div>



        


        <br/>
        <br/>
        </div>
        </div>
        </div>






         <div className="row">
      <div className="col teamContainer pageContainer pageContainer1">
      <i style={{ fontSize: "120%", color: "#black", paddingTop: "40px", paddingTop: "60px"}}>
        Congratulations on the incredible achievements of our students at the ASN G20 INTERNATIONAL FEST in New Delhi! We are immensely proud to announce that all 5 of our students, Saina Garg(V), Nandini Aggarwal(V), Ridhan Garg(VII), Udaybir Singh(VII), and Shiraz Grewal(VII), have showcased their exceptional talents and emerged as winners in their respective categories. Their hard work, dedication, and determination have truly paid off, and they have made us all proud.
Competing on an international platform like the ASN G20 INTERNATIONAL FEST not only enhances their skills but also fosters a sense of global awareness and collaboration.
Let's continue to nurture and empower our students to become well-rounded individuals who can make a positive impact on society.
        </i>
        </div>
        

        <div className="row">
      <div className="col teamContainer pageContainer pageContainer1">
      <div align="left">
        <img
         
          style={{
            background: "transparent",
           
          }}
          src={require("../../assets/icons/achieve14.jpg")}
          alt="Our-Story"
          loader="gradient"
          gradientPreset="life"
          width={500} height={400}
        />
        </div>



        


        <br/>
        <br/>
        </div>
        </div>
        </div>










        <div align="center">
        <img
         
          style={{
            background: "transparent",
           
          }}
          src={require("../../assets/icons/achieve1.png")}
          alt="Our-Story"
          loader="gradient"
          gradientPreset="life"
          width={1000} height={500}
        />
        </div>
        <br/>
        <i style={{ fontSize: "120%", color: "#black" }}>
        The potentials of realism, conviction and persistence delineate achievers.
        The students of CIFS, Sana Narang and Ryan Singh Narang have once again proved their mettle by winning medals 🏅 
        in District Swimming Championship 2022, held at Police DAV, Jalandhar.
        Sana of Grade IX, has bagged Gold medal in 100 m Freestyle, Bronze medal 
        in 200 m Freestyle, Silver medal in both 50 m Freestyle & 50 m Butterfly categories whereas Ryan of Grade VI 
        has bagged Silver medal in all the categories of 50 m Freestyle, 100 m Freestyle, 200 m Freestyle, 50 m Butterfly and 50 m Breast Stroke.
        Cambridge family feels elated and proud at the commendable #success of both the students. We congratulate 
        the proud parents and wish Best of Luck for the future success of the students.
        </i>
        <br/>
        <br/>
        <br/>
        <br/>


        <div className="row">
      <div className="col teamContainer pageContainer pageContainer1">
      <i style={{ fontSize: "120%", color: "#black", paddingTop: "40px", paddingTop: "60px"}}>
        Following the intuition  and having courage to pursue one’s dreams are the two unique features of our students.
      CIFS is proud to accolade the achievement of Sana Narang, a student of Grade 9 who has added another feather to 
      the School Cap by winning three Bronze medals 🏅 in ‘45th Punjab Junior State Swimming and Water Polo Championship’ 
      held at Guru Nanak Dev University, Amritsar. She participated as a member of Girls team for Jalandhar and emerged victorious in 
      100m Medley Relay, 100m Freestyle Relay and 200m Freestyle Relay.
      Cambridge family congratulates the proud parents and the child, wishing her success in her future endeavors. 
        </i>
        </div>
        

        <div className="row">
      <div className="col teamContainer pageContainer pageContainer1">
      <div align="left">
        <img
         
          style={{
            background: "transparent",
           
          }}
          src={require("../../assets/icons/achieve2.png")}
          alt="Our-Story"
          loader="gradient"
          gradientPreset="life"
          width={700} height={400}
        />
        </div>



        


        <br/>
        <br/>
        </div>
        </div>
        </div>



        <div className="row">
      <div className="col teamContainer pageContainer pageContainer1">
      <i style={{ fontSize: "120%", color:"#black", paddingBottom: "120px"}}>
            Champions are not made on playing grounds. Champions are made from something they have deep 
            inside them-a desire,a dream,a vision'
            Skating is an excellent sport not only for physical conditioning but also increases
            one’s balance and agility. MANKIRAT SINGH of IB PYP Grade 1 participated in 15th 
            Open Roller Skating Championship 2022-23 that was held on Sunday,15 May 2022 at Skating Rink, Golbagh, Amritsar 
            and won GOLD MEDAL in 10 lap race. He showcased an energetic and enthusiastic performance which was applauded 
            by the audience. We wish him luck in his future endeavours too.
        </i>
        </div>
        

        <div className="row">
      <div className="col teamContainer pageContainer pageContainer1">
      <div align="left">
        <img
         
          style={{
            background: "transparent",
           
          }}
          src={require("../../assets/icons/achieve3.PNG")}
          alt="Our-Story"
          loader="gradient"
          gradientPreset="life"

          width={500} height={400}
        />
        </div>

        

        


        <br/>
        <br/>
        </div>
        </div>
        </div>

         <div className="row">
      <div className="col teamContainer pageContainer pageContainer1">
      <i style={{ fontSize: "120%", color:"#black", paddingBottom: "120px"}}>
            "Winning a competition, a hundred times, gives the same happiness as the first."
            Saina Garg of IB PYP Grade 4 stands out in the Sports Carnival held at Gymkhana Club, Jalandhar by 
            bagging first position in the Badminton Championship, Under-11(Girls) Singles. The entire CIFS fraternity 
            feels proud and is committed to providing our budding #sports persons with the best of facilities and training 
            to groom them as adept sportspersons.
        </i>
        </div>
        

        <div className="row">
      <div className="col teamContainer pageContainer pageContainer1">
      <div align="left">
        <img
         
          style={{
            background: "transparent",
           
          }}
          src={require("../../assets/icons/achieve4.PNG")}
          alt="Our-Story"
          loader="gradient"
          gradientPreset="life"

          width={400} height={400}
        />
        </div>


        <br/>
        <br/>
        </div>
        </div>
        </div>


         <div className="row">
      <div className="col teamContainer pageContainer pageContainer1">
      <i style={{ fontSize: "120%", color:"#black", paddingBottom: "500px"}}>
            "Gold medals aren’t really made of gold. They’re made of sweat, determination, and a 
            hard-to-find alloy called guts"
              Kudos to the Winners 🥇 🏅
              Budding skaters of CIFS Mankirat Singh of IB PYP 1 and Oviya Arora of IGCSE 10 brought laurels 
              to their alma mater as both bagged #Gold Medal in Open Roller Skating Championship -2022 organized under 
              the auspices of The Wheels in Amritsar on May 22, 2022. They showed their outstanding performance in 500 meter 
              race and made us proud. The school management and the entire CIFS fraternity extends heartiest congratulations 
              to them and their parents on this remarkable achievement.
        </i>
        </div>
        

        <div className="row">
      <div className="col teamContainer pageContainer pageContainer1">
      <div align="left">
        <img
         
          style={{
            background: "transparent",
           
          }}
          src={require("../../assets/icons/achieve5.PNG")}
          alt="Our-Story"
          loader="gradient"
          gradientPreset="life"

          width={500} height={400}
        />
        </div>


        <br/>
        <br/>
        </div>
        </div>
        </div>



         <div className="row">
      <div className="col teamContainer pageContainer pageContainer1">
      <i style={{ fontSize: "120%", color:"#black", paddingBottom: "500px"}}>
            The achievers simply use their ability, competitive spirit, motivation for striving hard and 
            turning their dreams🏆 to reality. Keeping the wholesome development of a child as the priority, 
            CIFS always believes in inculcating amongst the students the values of; being open minded and 
            taking responsibility for their learning. The evidence for the same were the students who have proved 
            their flair by bagging gold, silver and bronze medals in District Level Roller Skating Championship 
            held at Police DAV School, Jalandhar. Oviya of Grade X and Mankirat of Grade I - PYP won a Gold and a 
            Silver medal whereas Manveer from Grade IV - PYP won a #Bronze medal. Cambridge family congratulated the 
            proud parents and wish the children all the best for their future endeavours.
        </i>
        </div>
        

        <div className="row">
      <div className="col teamContainer pageContainer pageContainer1">
      <div align="left">
        <img
         
          style={{
            background: "transparent",
           
          }}
          src={require("../../assets/icons/achieve6.jpg")}
          alt="Our-Story"
          loader="gradient"
          gradientPreset="life"

          width={500} height={400}
        />
        </div>


        <br/>
        <br/>
        </div>
        </div>
        </div>


        <div className="row">
      <div className="col teamContainer pageContainer pageContainer1">
      <i style={{ fontSize: "120%", color:"#black", paddingBottom: "500px"}}>
            Challenges are the doors to excellence, and Arohi Mahajan, an IGCSE student at CIFS 
            in Grade 10, demonstrated her aptitude and precision by competing in the Punjab Khed Mela 
            at the Sports College in Jalandhar and taking first place🏆 in the 200-meter freestyle and 
            second place in the 200-meter breaststroke swimming competitions rising the pride✨ of the school.
            Moreover, she won 3rd position at SD Model School, Jalandhar Cantt by participating in 19th 
            Punjab Kesari Chess Championship, U-17 Girl’s category. Her performance provided a crucial message 
            for all the youngsters that it is only will power that paves the way to success.
        </i>
        </div>
        

        <div className="row">
      <div className="col teamContainer pageContainer pageContainer1">
      <div align="left">
        <img
         
          style={{
            background: "transparent",
           
          }}
          src={require("../../assets/icons/achieve7.jpg")}
          alt="Our-Story"
          loader="gradient"
          gradientPreset="life"

          width={500} height={400}
        />
        </div>


        <br/>
        <br/>
        </div>
        </div>
        </div>




  
      </div>
    </Grid>
  </main>
)
