import React from "react"
import Gallery from "../gallery"
import t21 from "../../assets/titleimage/t21.png"
import { Grid } from "@material-ui/core"
import { Helmet } from "react-helmet"


export const AnnualFunction = () => (
  <main>
  <div>
    <Helmet>
    <title>
    Annual Function 2022 | Cambridge International Foundation School 

         </title>
    </Helmet>

    </div>
    
    <Grid container style={{ margin: "10px 2px" }}>
      <div
        className="teamContainer pageContainer pageContainer1 pageContainer3 alumniVideo"
        style={{ marginBottom: "30px" }}
      >
        <h1
          style={{
            color: "#e95721",
            fontSize: "35px",
            fontWeight: "bold",
            fontFamily: "sans-serif",
          }}
        >
          Annual Function 2022
        </h1>
        
        
        


        <div align="center">
        <img
         
          style={{
            background: "transparent",
           
          }}
          src={require("../../assets/images/af1.png")}
          alt="Our-Story"
          loader="gradient"
          gradientPreset="life"
        />
        </div>
        <br />
        <br />

        <div align="center">
        <img
         
          style={{
            background: "transparent",
           
          }}
          src={require("../../assets/images/af2.png")}
          alt="Our-Story"
          loader="gradient"
          gradientPreset="life"
        />
        </div>
        <br />
        <br />

        <div align="center">
        <img
         
          style={{
            background: "transparent",
           
          }}
          src={require("../../assets/images/af3.png")}
          alt="Our-Story"
          loader="gradient"
          gradientPreset="life"
        />
        </div>
        <br />
        <br />

        <div align="center">
        <img
         
          style={{
            background: "transparent",
           
          }}
          src={require("../../assets/images/af4.png")}
          alt="Our-Story"
          loader="gradient"
          gradientPreset="life"
        />
        </div>
        <br />
        <br />

        <div align="center">
        <img
         
          style={{
            background: "transparent",
           
          }}
          src={require("../../assets/images/af5.png")}
          alt="Our-Story"
          loader="gradient"
          gradientPreset="life"
        />
        </div>
        <br />
        <br />

        <div align="center">
        <img
         
          style={{
            background: "transparent",
           
          }}
          src={require("../../assets/images/af6.png")}
          alt="Our-Story"
          loader="gradient"
          gradientPreset="life"
        />
        </div>
        <br />
        <br/>
        

        <div align="center">
        <img
         
          style={{
            background: "transparent",
           
          }}
          src={require("../../assets/images/af7.png")}
          alt="Our-Story"
          loader="gradient"
          gradientPreset="life"
        />
        </div>
        <br />
        <br />

        <div align="center">
        <img
         
          style={{
            background: "transparent",
           
          }}
          src={require("../../assets/images/af8.png")}
          alt="Our-Story"
          loader="gradient"
          gradientPreset="life"
        />
        </div>
        <br />
        <br />


        <div align="center">
        <img
         
          style={{
            background: "transparent",
           
          }}
          src={require("../../assets/images/af9.png")}
          alt="Our-Story"
          loader="gradient"
          gradientPreset="life"
        />
        </div>
        <br />
        <br />


        <div align="center">
        <img
         
          style={{
            background: "transparent",
           
          }}
          src={require("../../assets/images/af10.png")}
          alt="Our-Story"
          loader="gradient"
          gradientPreset="life"
        />
        </div>


      

  
      </div>
    </Grid>
  </main>
)
