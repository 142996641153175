import React from "react"
import Gallery from "../gallery"

export const Organisation = () => (
  <main>
    <div style={{ marginLeft: "200px", marginRight: "200px" }}>
      <h1 style={{ color: "#e95721", fontWeight: "bold", fontFamily: "Times" }}>
        Organisation
      </h1>

      <p>
        <i style={{ fontSize: "150%", color: "#0ca685" }}>
          We are a part of the Learning Wings family.
        </i>
      </p>
      <p>
        Learning Wings is a global organisation devoted to high-quality
        education that meets the demands of parents, students and education
        providers the world over. It is also a leading provider of school
        management services and works closely with schools to raise student
        achievements. It's flagship schools - Cambridge International School -
        spread across North India is showing children the process of realizing
        their potential.
      </p>

      <p>
        <i>
          <a
            href="https://www.learningwings.org/"
            style={{ fontWeight: "bold", fontSize: "20px" }}
          >
            Visit the Learning Wings website.
          </a>
        </i>
      </p>

      <div>
        <Gallery />
      </div>
    </div>
  </main>
)
