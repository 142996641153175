import React from "react"
import { Grid } from "@material-ui/core"
import { Helmet } from "react-helmet"

export const ScienceLab = () => (
  <main>
   <div>
    <Helmet>
    <title>
    Science Lab | Cambridge International Foundation School 

         </title>
    </Helmet>

    </div>
    <Grid container style={{ margin: "10px 2px" }}>
      <div className="teamContainer pageContainer pageContainer1">
        <h1
          style={{
            color: "#e95721",
            fontSize: "35px",
            fontWeight: "bold",
            fontFamily: "sans-serif",
          }}
        >
          Science Lab
        </h1>
        <p>
          Science, being the study of transformation, requires a lot of actions
          of mixing, heating or cooling, applying pressure, observations etc. to
          understand the impact of reaction or change as a whole. The science
          lab houses all the necessary chemicals and equipment to enable the
          pupils perform various experiments to enhance their learning with real
          life experience.
        </p>
        <p>
          <i style={{ fontSize: "150%", color: "#0ca685" }}>
            "The science of today is the technology of tomorrow."
          </i>
        </p>

        

        <div className="row">
          <div className="col">
            <img
              style={{
                border: "solid #fcbc3c",
              }}
              src={require("../../assets/images/sc1.jpg")}
              alt="Our-Story"
              loader="gradient"
              gradientPreset="life"
            />
          </div>

          <div className="col">
            <img
              style={{
                border: "solid #fcbc3c",
              }}
              src={require("../../assets/images/sc2.jpg")}
              alt="Our-Story"
              loader="gradient"
              gradientPreset="life"
            />
          </div>
          <div className="col">
            <img
              style={{
                border: "solid #fcbc3c",
              }}
              src={require("../../assets/images/sc3.jpg")}
              alt="Our-Story"
              loader="gradient"
              gradientPreset="life"
            />
          </div>
        </div>

        <div className="row">
          <div className="col">
            <img
              style={{
                border: "solid #fcbc3c",
              }}
              src={require("../../assets/images/sc4.jpg")}
              alt="Our-Story"
              loader="gradient"
              gradientPreset="life"
            />
          </div>

          <div className="col">
            <img
              style={{
                border: "solid #fcbc3c",
              }}
              src={require("../../assets/images/sc5.jpg")}
              alt="Our-Story"
              loader="gradient"
              gradientPreset="life"
            />
          </div>
          <div className="col">
            <img
              style={{
                border: "solid #fcbc3c",
              }}
              src={require("../../assets/images/sc6.jpg")}
              alt="Our-Story"
              loader="gradient"
              gradientPreset="life"
            />
          </div>
        </div>
      </div>
    </Grid>
  </main>
)
