import React from "react"
import Button from "@material-ui/core/Button"
import { withRouter } from "react-router-dom"
import { Grid } from "@material-ui/core"
import { Helmet } from "react-helmet"

const Infrastructure = ({ history }) => (
  <main>
   <div>
    <Helmet>
    <title>
    Infrastructure | Cambridge International Foundation School 

         </title>
    </Helmet>

    </div>
    <Grid container justify="center" style={{ margin: "10px 2px" }}>
      <div
        className="teamContainer pageContainer pageContainer1"
        style={{ marginBottom: "30px" }}
      >
        <h1
          style={{
            color: "#e95721",
            fontSize: "35px",
            fontWeight: "bold",
            fontFamily: "sans-serif",
          }}
        >
          Infrastructure
        </h1>
        <p>
          Young men,…Live in the serene peace of laboratories and libraries. Say
          to yourself first:
          <i>
            ‘What have I done for my instruction? And, as you gradually advance,
            ‘What have I done for my country?’ – Louis Pasteur
          </i>
          <br />
          Believing in the above words, CIFS encourages its charges to be
          self-reliant and responsible for their own learning, keeping the
          global view to be their major concern. To enable the students explore
          and soar high in the sky well equipped laboratories are an integral
          part of the infra-structure.
        </p>
      </div>
      <div className="row">
        <div className="col teamContainer pageContainer pageContainer1">
          <div className="container1">
            <img
              src={require("../../assets/images/art.jpg")}
              alt="Avatar"
              className="image1"
              style={{ width: "100%" }}
            />
            <div className="middle1" styl>
              <Button
                variant="inherit"
                className="button6"
                style={{
                  background: "#0ca685",
                  color: "white",
                  fontFamily: "sans-serif",
                  fontSize: "20px",
                  textTransform: "capitalize",
                }}
                onClick={() => history.push("/infrastructure/art-and-craft")}
              >
                <i>Art & Craft </i>
              </Button>
            </div>
          </div>
          <div className="container1">
            <img
              src={require("../../assets/images/ict.jpg")}
              alt="Avatar"
              className="image1"
              style={{ width: "100%" }}
            />
            <div className="middle1">
              <Button
                variant="inherit"
                className="button6"
                style={{
                  background: "#0ca685",
                  color: "white",
                  fontFamily: "sans-serif",

                  fontSize: "20px",
                  textTransform: "capitalize",
                }}
                onClick={() => history.push("/infrastructure/ict-lab")}
              >
                <i> ICT Lab</i>
              </Button>
            </div>
          </div>
        </div>
        <div className="col ">
          <div className="container1">
            <img
              src={require("../../assets/images/Science-Lab.jpg")}
              alt="Avatar"
              className="image1"
              style={{ width: "100%" }}
            />
            <div className="middle1">
              <Button
                variant="inherit"
                className="button6"
                style={{
                  background: "#0ca685",
                  color: "white",
                  fontFamily: "sans-serif",

                  fontSize: "20px",
                  textTransform: "capitalize",
                }}
                onClick={() => history.push("/infrastructure/science-lab")}
              >
                <i>Science Lab</i>
              </Button>
            </div>
          </div>
          <div className="container1">
            <img
              src={require("../../assets/images/MathsLab.jpg")}
              alt="Avatar"
              className="image1"
              style={{ width: "100%" }}
            />
            <div className="middle1">
              <Button
                variant="inherit"
                className="button6"
                style={{
                  background: "#0ca685",
                  color: "white",
                  fontFamily: "sans-serif",

                  fontSize: "20px",
                  textTransform: "capitalize",
                }}
                onClick={() => history.push("/infrastructure/maths-lab")}
              >
                <i>Maths Lab</i>
              </Button>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col teamContainer pageContainer pageContainer1">
          <div className="container1">
            <img
              src={require("../../assets/images/dance.JPG")}
              alt="Avatar"
              className="image1"
              style={{ width: "100%" }}
            />
            <div className="middle1">
              <Button
                variant="inherit"
                className="button6"
                style={{
                  background: "#0ca685",
                  color: "white",
                  fontFamily: "sans-serif",

                  fontSize: "20px",
                  textTransform: "capitalize",
                }}
                onClick={() => history.push("/infrastructure/dance-music")}
              >
                <i>Dance & music</i>
              </Button>
            </div>
          </div>
          <div className="container1">
            <img
              src={require("../../assets/images/library.JPG")}
              alt="Avatar"
              className="image1"
              style={{ width: "100%" }}
            />
            <div className="middle1">
              <Button
                variant="inherit"
                className="button6"
                style={{
                  background: "#0ca685",
                  color: "white",
                  fontFamily: "sans-serif",

                  fontSize: "20px",
                  textTransform: "capitalize",
                }}
                onClick={() => history.push("/infrastructure/library")}
              >
                <i>library</i>
              </Button>
            </div>
          </div>
        </div>
        <div className="col">
          <div className="container1">
            <img
              src={require("../../assets/images/sports.JPG")}
              alt="Avatar"
              className="image1"
              style={{ width: "100%" }}
            />
            <div className="middle1">
              <Button
                variant="inherit"
                className="button6"
                style={{
                  background: "#0ca685",
                  color: "white",
                  fontFamily: "sans-serif",

                  fontSize: "20px",
                  textTransform: "capitalize",
                }}
                onClick={() => history.push("/infrastructure/sports")}
              >
                <i>Sports</i>
              </Button>
            </div>
          </div>
          <div className="container1">
            <img
              src={require("../../assets/images/Buses.jpg")}
              alt="Avatar"
              className="image1"
              style={{ width: "100%" }}
            />
            <div className="middle1">
              <Button
                variant="inherit"
                className="button6"
                style={{
                  background: "#0ca685",
                  color: "white",
                  fontFamily: "sans-serif",

                  fontSize: "20px",
                  textTransform: "capitalize",
                }}
                onClick={() => history.push("/infrastructure/security")}
              >
                <i>Security</i>
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Grid>
  </main>
)

export default withRouter(Infrastructure)
