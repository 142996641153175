import React from "react"
import { Grid } from "@material-ui/core"
import { Helmet } from "react-helmet"

export const Library = () => (
  <main>
  <div>
    <Helmet>
    <title>
    Library | Cambridge International Foundation School 

         </title>
    </Helmet>

    </div>
    <Grid container justify="center" style={{ margin: "10px 2px" }}>
      <div
        className="teamContainer pageContainer pageContainer1"
        style={{ marginBottom: "30px" }}
      >
        <h1
          style={{
            color: "#e95721",
            fontSize: "35px",
            fontWeight: "bold",
            fontFamily: "sans-serif",
          }}
        >
          Library
        </h1>
        <p>
          <i style={{ fontSize: "150%", color: "#0ca685" }}>
            "Information is the heartbeat of meaningful learning in schools."
          </i>
        </p>

        <p>
          Books are the best weapons to rule the world through wisdom and
          knowledge. To provide the students an opportunity to have an easy
          access to all the resources for boosting their knowledge and
          experimenting with their ideas we have a well-equipped library which
          is filled with both print and e-books besides the journals, newspapers
          and all other relevant material.
        </p>

        <div className="row">
          <div className="col">
            <img
              style={{
                border: "solid #fcbc3c",
              }}
              src={require("../../assets/images/lib1.jpeg")}
              alt="Our-Story"
              loader="gradient"
              gradientPreset="life"
            />
          </div>

          <div className="col">
            <img
              style={{
                border: "solid #fcbc3c",
              }}
              src={require("../../assets/images/lib2.jpeg")}
              alt="Our-Story"
              loader="gradient"
              gradientPreset="life"
            />
          </div>
          <div className="col">
            <img
              style={{
                border: "solid #fcbc3c",
              }}
              src={require("../../assets/images/lib3.JPG")}
              alt="Our-Story"
              loader="gradient"
              gradientPreset="life"
            />
          </div>
        </div>

        

          
        
      </div>
    </Grid>
  </main>
)
