import React, { Component } from "react"
import "./blog.css"
import ReactRoundedImage from "react-rounded-image"
import mridul from "../../assets/blogs/mridul.jpg"
import ajayBhatiaAltSquare from "../../assets/images/ajayBhatiaAltSquare.jpg"
import deepaDograPortrait from "../../assets/images/deepaDograPortrait.jpg"
import nittai from "../../assets/images/nittai.jpg"
import mehar1 from "../../assets/images/mehar1.jpg"
import mehar from "../../assets/images/mehar.jpg"
import riya from "../../assets/images/riya.jpg"
import apram from "../../assets/images/apram.jpg"
import al8 from "../../assets/blogs/al8.mp4"
import al9 from "../../assets/blogs/al9.mp4"
import al10 from "../../assets/blogs/al10.mp4"

import Button from "react-bootstrap/Button"
import CustomizedDialogs from "../../pages/demo"
import t23 from "../../assets/titleimage/t23.png"
import { Grid } from "@material-ui/core"
import { Helmet } from "react-helmet"
import ReactPlayer from "react-player"


export const Blog = () => {
  return (
    <main>
    <div>
    <Helmet>
    <title>
    Blogs | Cambridge International Foundation School 

         </title>
    </Helmet>

    </div>
    <div align="center">
      <img src={t23} alt="blog" />
      </div>
      <Grid container justify="center" style={{ margin: "10px 2px" }}>
        <div
          className="teamContainer pageContainer1 pageContainer3 alumniVideo"
         style={{ marginBottom: "30px" }}
        >
          <h1
            style={{
              color: "#e95721",
              fontSize: "35px",
              fontWeight: "bold",
              fontFamily: "sans-serif",
            }}
          >
            Blogs
          </h1>
          <h3
            style={{ color: "#0ca685", fontSize: "25px" }}
          >
            <i>Every little step creates the future.</i>
          </h3>
          <p style={{ color: "#6c757d" }}>
            The proud Cambridgians who have carved themselves a comfortable
            niche away from their homes in different parts of the world, felt
            overwhelmed while narrating their personal experiences during their
            study period in school. Words, stark with emotions made them pour
            out their heart in the form of blogs written by them.
          </p>
         <Grid container spacing={4} style={{paddingTop:"30px"}}>
          <Grid item xs={12} sm={12} md={4}  align="center" className="blogAdjust">
          
                <ReactRoundedImage
                  image={nittai}
                  roundedColor="#fcbc3c"
                  imageWidth="180"
                  imageHeight="180"
                  roundedSize="1"
                  borderRadius="55"
                  hoverColor="black"
                />
              
              <h3
                align="center"
                style={{
                  color: "#e95721",
                  fontWeight: "bold",
                 
                  fontFamily: "sans-serif",
                }}
              >
                NITTAI AGGARWAL
              </h3>
              <h1
                align="center"
                style={{
                  color: "#0ca685",
                  fontSize: "20px",
                  fontWeight: "bold",
                }}
              >
                FH Aachen university, Germany
              </h1>
              <p
                className="card-text text-secondary"
                style={{
                  color: "#8C001A",
                  fontSize: "16px",
                  textAlign: "justify",
                }}
              >
                I joined Cambridge International school during its commencement year, and have spent
                10 years with the Cambridge Family. My journey with CIS was ideal. At CIS as a
                student, I always had the liberty to explore, fail, and learn from my mistakes without
                ever worrying about anything...
              </p>
              <CustomizedDialogs
                name={"Nittai Aggarwal"}
                text={[
                  `I joined Cambridge International school during its commencement year, and have spent
                  10 years with the Cambridge Family. My journey with CIS was ideal. At CIS as a
                  student, I always had the liberty to explore, fail, and learn from my mistakes without
                  ever worrying about anything. During my journey at CIS I have met the most supportive
                  teachers that some students can only dream about. They were there for me every time I
                  wanted them. Cambridge was the only school in neighboring cities which offered
                  International Baccalaureate, and Cambridge International Examinations board of
                  education. I was lucky to grab this opportunity.
                  Pursuing the IB Diploma completely changed my perspective regarding my daily
                  lifestyle . It gave me an important head start to my academic and professional life. I
                  entered IB as a shy and introvert person. The fear of failing was the biggest challenge I
                  faced during my DP. After my Diploma I ended up being an open minded,
                  knowledgeable, risk taker and a balanced person. .
                  For my bachelors I had always wanted to pursue Mechanical Engineering in Germany.
                  The biggest challenge to study in Germany is the “GERMAN” language. It was all
                  because of the IB diploma I was able to channel my capabilities and I took the risk of
                  studying in German. I registered myself for the “Freshman Institute at FH Aachen”
                  entrance test. The Freshman Year is the simultaneous technical and linguistic
                  bridge between schools in home country and German university studies. Freshman year
                  offer international applicant’s unique access to university programs in Germany. I
                  cleared the test and opted for a German taught University after the Freshman Year. So,
                  during my freshman year I studied German till C1 level. I passed my Freshman year in
                  October 2020.
                  Now, I am pursuing my Bachelors in Science (Mechanical Engineering) from
                  “Fachhochschule Aachen, Germany”, which is a completely German University.

“Dreamt, Dared, Did”`,
                ]}
              />
          </Grid>

          <Grid item xs={12} sm={12} md={4}  align="center">
            
              <ReactRoundedImage
                image={mehar1}
                roundedColor="#fcbc3c"
                imageWidth="180"
                imageHeight="180"
                roundedSize="1"
                borderRadius="55"
                hoverColor="black"
              />

              <h3
                align="center"
                style={{
                  color: "#e95721",
                  fontWeight: "bold",
                  
                  fontFamily: "sans-serif",
                }}
              >
                MEHAR MAHAJAN
              </h3>
              <h1
                align="center"
                style={{
                  color: "#0ca685",
                  fontSize: "20px",
                  fontWeight: "bold",
                }}
              >
                Concordia University, Quebec
              </h1>
              <p
                className="card-text text-secondary"
                style={{
                  color: "#8C001A",
                  fontSize: "16px",
                  textAlign: "justify",
                }}
              >
                  With the two years that I’ve spent at Cambridge International Foundation School (CIFS) pursuing International Baccalaureate
                 Diploma Programme, are ineffable to be jotted. I’ve seen myself growing with the utmost scintillating attributes 
                 all due to the environment I was encompassed by at CIFS...
                
              </p>
              <CustomizedDialogs
                name={"Mehar Mahajan"}
                text={[
                  `With the two years that I’ve spent at Cambridge International Foundation School (CIFS) pursuing International Baccalaureate
                 Diploma Programme, are ineffable to be jotted. I’ve seen myself growing with the utmost scintillating attributes 
                 all due to the environment I was encompassed by at CIFS. 
                  These two years instilled me with the confidence of trying my hand at variations without fearing failure and if it ever did,
                  I learnt not to succumb to it. The school has been utterly amiable of the vivid ideas and perspectives brought by the students.
                  This made us believe that the institution promises guidance with path directed to silver-lining than teaching to restricted and 
                  compressed methodologies.
                    Knowledge is ineluctable in the corridors of CIFS, as it promises to behold the originality of a student yet embarks a 
                  new skill each time. 
                  My deepest gratitude to Ms. Rashmi Saini (Principal) and the promising faculty of CIFS for not letting me vacillate and 
                  providing me with an 
                  opportunity to flourish every time and live-by the motto of dream, dare and do.`,
                ]}
              />
           </Grid>

          <Grid item xs={12} sm={12} md={4}  align="center" >
              <ReactRoundedImage
                image={mridul}
                roundedColor="#fcbc3c"
                imageWidth="180"
                imageHeight="180"
                roundedSize="1"
                borderRadius="55"
                hoverColor="black"
              />
              <h3
                align="center"
                style={{
                  color: "#e95721",
                  
                  fontWeight: "bold",
                  fontFamily: "sans-serif",
                }}
              >
                MRIDUL AGGARWAL
              </h3>
              <h1
                align="center"
                style={{
                  color: "#0ca685",
                  fontSize: "20px",
                  fontWeight: "bold",
                }}
              >
                University of Victoria, Canada
              </h1>
              <p
                className="card-text text-secondary"
                style={{
                  color: "#8C001A",
                  fontSize: "16px",
                  textAlign: "justify",
                }}
              >
                Completing the Diploma Programme not only prepared me tremendously for University,
                 but also granted me crucial credits for 1st year courses which will help me to complete my degree on time and
                  save me alot of money.IB diploma  also developed advanced speaking skills in me like...
              </p>
              <CustomizedDialogs
                name={"Mridul Aggarwal"}
                text={[
                  `Completing the Diploma Programme not only prepared me tremendously for University,
                  but also granted me crucial credits for 1st year courses which will help me to complete my degree on time and
                  save me alot of money. IB diploma  also developed advanced speaking skills in me like: during in-class presentations 
                  and Individual Oral Commentary’s (IOC) that made me great at public speaking. It also helped me obtain analytical writing 
                  skills while composing our extended essay and Internal Assessments, and doing countless excercises and assignments in my 
                  ib subjects. College is still very difficult, but the skills and thought processes IB students acquire in the DP set us apart 
                  from other university students and make it easier to balance and excel in a multitude of different activities.`,
                ]}
              />
            </Grid>
            </Grid>
      

          <Grid container spacing={4}>
          <Grid item xs={12} sm={12} md={4} align="center" >
              <ReactRoundedImage
                image={mehar}
                roundedColor="#fcbc3c"
                imageWidth="180"
                imageHeight="180"
                roundedSize="1"
                borderRadius="55"
                hoverColor="black"
              />

              <h3
                align="center"
                style={{
                  color: "#e95721",
                  paddingTop: "20px",
                  fontWeight: "bold",
                  fontFamily: "sans-serif",
                }}
              >
                JAIPREET GOINDI
              </h3>
              <h1
                align="center"
                style={{
                  color: "#0ca685",
                  fontSize: "20px",
                  fontWeight: "bold",
                }}
              >
                University of Toronto, Canada
              </h1>
              <p
                className="card-text text-secondary"
                style={{
                  color: "#8C001A",
                  fontSize: "16px",
                  textAlign: "justify",
                }}
              >
               I was a part of Cambridge International Foundation School from 2018-20 batch.
               I am pursuing Bachelors of Kinesiology from University of Toronto, Canada. Although a school gives you much more than just formal education, 
               it does eventually direct you to your career goal in life...
                
              </p>
              <CustomizedDialogs
                name={"Gaipreet Goindi"}
                text={[
                  `I was a part of Cambridge International Foundation School from 2018-20 batch.
               I am pursuing Bachelors of Kinesiology from University of Toronto, Canada. Although a school gives you much more than just formal education, 
               it does eventually direct you to your career goal in life.
                There are uncountable ways my school affected me,
                 but these are the ones that made me who I am, and as thankful as I am for being
                  a part of this magnificent school, I couldn’t be grateful enough to my parents, 
                  for making the right choice for me.`,
                ]}
              />
            </Grid>
         
          
           <Grid item xs={12} sm={12} md={4}  align="center">
          
                <ReactRoundedImage
                  image={riya}
                  roundedColor="#fcbc3c"
                  imageWidth="180"
                  imageHeight="180"
                  roundedSize="1"
                  borderRadius="55"
                  hoverColor="black"
                />
              
              <h3
                align="center"
                style={{
                  color: "#e95721",
                  fontWeight: "bold",
                  paddingTop: "20px",
                  fontFamily: "sans-serif",
                }}
              >
                RIYA BANGER
              </h3>
              <h1
                align="center"
                style={{
                  color: "#0ca685",
                  fontSize: "20px",
                  fontWeight: "bold",
                }}
              >
                George Brown College, Toronto
              </h1>
              <p
                className="card-text text-secondary"
                style={{
                  color: "#8C001A",
                  fontSize: "16px",
                  textAlign: "justify",
                }}
              >
                I selected IB mostly because I wanted to be academically impaired.
                I wasn't really sure about what I would get out of my academics as a sophomore very frankly, but I ended up getting a lot out of IB in terms of 
                critical thinking and writing skills and, most significantly, reasoning about why 
              and how we learn what we do in school...
              </p>
              <CustomizedDialogs
                name={"Riya Banger"}
                text={[
                  `I selected IB mostly because I wanted to be academically impaired.
                I wasn't really sure about what I would get out of my academics as a 
              sophomore very frankly, but I ended up getting a lot out of IB in terms of 
              critical thinking and writing skills and, most significantly, reasoning about why and how we learn what we do in school. The best part of the IB program was having to raise questions about the bigger picture. It's so much better than memorizing facts and rehashing 
              them. It was thinking about why we learned, what we did and how to 
          think about what we were learning in a bigger context. In a lot of 
          respects, it has changed my perspective about schooling and academic, and I am very grateful for that.

`,
                ]}
              />
          </Grid>


          <Grid item xs={12} sm={12} md={4}  align="center">
          
                <ReactRoundedImage
                  image={apram}
                  roundedColor="#fcbc3c"
                  imageWidth="180"
                  imageHeight="180"
                  roundedSize="1"
                  borderRadius="55"
                  hoverColor="black"
                />
              
              <h3
                align="center"
                style={{
                  color: "#e95721",
                  fontWeight: "bold",
                  paddingTop: "20px",
                  fontFamily: "sans-serif",
                }}
              >
                APRAMVIR
              </h3>
              <h1
                align="center"
                style={{
                  color: "#0ca685",
                  fontSize: "20px",
                  fontWeight: "bold",
                }}
              >
                Kwantlen Polytechnic University, BC
              </h1>
              <p
                className="card-text text-secondary"
                style={{
                  color: "#8C001A",
                  fontSize: "16px",
                  textAlign: "justify",
                }}
              >
                Apramvir, alumni of Cambridge International Foundation School talks about the 
                positive energy and the motivational vibes of the staff and the school which helped 
                him to get  admission in Kwantlen Polytechnic  University , British Columbia, Canada...
                
              </p>
              <CustomizedDialogs
                name={"Apramvir"}
                text={[
                  `Apramvir, alumni of Cambridge International Foundation School talks about the positive energy and the motivational vibes of the 
                  staff and the school which helped him to get  admission in Kwantlen Polytechnic  University , British Columbia, Canada. Moreover, 
                  studying in IB has got him the privilege to transfer his credits and reduction in his course and fee for the currently pursuing course of Marketing.

`,
                ]}
              />
          </Grid>

          
            </Grid>
          
        </div>
      </Grid>


      <div style={{ paddingBottom:"20px"}}>
      <i style={{ fontSize: "200%", color: "#e95721" , paddingBottom:"50px", marginLeft:"50px"}}>
        Testimonials of our Parents
      </i>
      </div>

      <Grid container spacing={1}>
          <Grid item sm={12} xs={12} md={6}>
        <ReactPlayer
         width="100%"
          url={al8}
          style={{ border: "3px solid #fcbc3c"}}
          controls
          type="video/mp4"
        />
    </Grid>

    <Grid item sm={12} xs={12} md={6}>
        <ReactPlayer
         width="100%"
          url={al9}
          style={{ border: "3px solid #fcbc3c" }}
          controls
          type="video/mp4"
        />
      </Grid>

    </Grid>

    <Grid container spacing={1}>
          <Grid item xs={12} sm={12} md={6}>
        <ReactPlayer
         width="100%"
          url={al10}
          style={{ border: "3px solid #fcbc3c" }}
          controls
          type="video/mp4"
        />
      </Grid>
      
      </Grid>



    </main>
  )
}
