import React from "react"
import { Grid } from "@material-ui/core"
import { Helmet } from "react-helmet"

export const Security = () => (
  <main>
   <div>
    <Helmet>
    <title>
    Security | Cambridge International Foundation School 

         </title>
    </Helmet>

    </div>
    <Grid container justify="center" style={{ margin: "10px 2px" }}>
      <div
        className="teamContainer pageContainer pageContainer1"
        style={{ marginBottom: "30px" }}
      >
        <h1
          style={{
            color: "#e95721",
            fontSize: "35px",
            fontWeight: "bold",
            fontFamily: "sans-serif",
          }}
        >
          Security
        </h1>
        <p>
          For the safety and security of the students, the school gates are
          manned 24 hours. Furthermore two female and five male guards are
          deployed round the clock. Self going students are not allowed to leave
          the school without valid gate pass and even the parents are issued
          with Parent Identity Card which they have to show to the school
          authority before taking their wards. A student boards/gets off the
          school bus only at specific stop mentioned by the parents. This
          ensures the safety of the students. Visitors are not allowed to enter
          in the school premises without valid permission from the school
          authorities. As mandated by Indian law, the school has relevant CCTV
          cameras.
        </p>

        <div className="row">
          <div className="col">
            <img
              style={{
                border: "solid #fcbc3c",
              }}
              src={require("../../assets/images/Buses 2.jpg")}
              alt="Our-Story"
              loader="gradient"
              gradientPreset="life"
            />
          </div>

          <div className="col">
            <img
              style={{
                border: "solid #fcbc3c",
              }}
              src={require("../../assets/images/staff1.jpg")}
              alt="Our-Story"
              loader="gradient"
              gradientPreset="life"
            />
          </div>
        </div>
      </div>
    </Grid>
  </main>
)
