import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import { Paper, Grid } from "@material-ui/core"
import aboutOptions from "./aboutSection"
import { withRouter } from "react-router-dom"
import Button from "react-bootstrap/Button"
import { Link } from "react-router-dom"

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),

    color: theme.palette.text.secondary,
  },
  enquiry: {
    padding: theme.spacing(2),

    color: theme.palette.text.secondary,
    height: "100%",
  },
  aboutSection: {
    margin: 0,
  },
}))

const GridWrapper = props => {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      {props.title && (
        <Grid container spacing={0} alignItems="center" justify="center">
          {props.title}
        </Grid>
      )}

      <Grid container spacing={1}>
        <Grid item xs={16} sm={8}>
          <Paper elevation={0} className={classes.paper}>
            <props.children />
          </Paper>
        </Grid>
        <Grid item xs={14} sm={4}>
          <Paper elevation={0} className={classes.enquiry}>
            <div
              style={{
                marginLeft: "40px",
                fontSize: "15px",
                color: "blue",
                background: "#f4f0ec",
                padding: "10px",
                marginRight: "80px",
                color: "#e95721",
              }}
            >
              {aboutOptions.map(option => (
                <div
                  style={{
                    cursor: "pointer",
                  }}
                  onClick={() => props.history.push(option.link)}
                >
                  {option.title}
                </div>
              ))}
            </div>

            <div
              style={{
                marginTop: "40px",
                marginLeft: "40px",
                color: "#e95721",
                fontWeight: "bold",
                fontSize: "25px",
                fontFamily: "sans-serif",
                paddingBottom:"30px"
              }}
            >
              Admission Enquiry
            </div>

            <p style={{ marginLeft: "40px", marginRight: "30px"}}>
              For assistance and help or to
              <br />
              see our campus, <br />
              please visit us at:
              <br />
              <i>
                <b>
                  Urban Estate, Phase I<br />
                  Jalandhar, 144002
                  <br />
                  Punjab
                  <br />
                  India
                  <br />
                </b>
              </i>
              <br />
              <p style={{ marginRight: "30px"}}>
                You can also write an email to us at:
                <br/>
                <i>
                  <b> admissions@cifsjal.school</b>
                </i>
              </p>
              <p style={{ marginRight: "30px" }}>
                Or, you can call us at these numbers between 9AM and 2PM Monday
                to Friday:
                <br />
                Phone no. : <br />
                <i>
                  <b>0181-4611888, 0181-4622888</b>
                </i>
              </p>
              <p style={{ marginRight: "30px" }}>
                Do you wish to meet us and have a look around? You can request a
                guided tour by contacting us. Please note that the appointment
                is subject to availability.
                
              </p>

              <Link to="./contact" target="_blank">
              <Button className="button6" variant="inherit" style={{marginLeft: '70px'}}>
              <b>Contact ⇝</b>

            </Button>{" "}
            </Link>
            </p>
          </Paper>
        </Grid>
      </Grid>
    </div>
  )
}

export default withRouter(GridWrapper)
