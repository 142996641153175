import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import { Grid, TextField, TextareaAutosize } from "@material-ui/core"
import { Formik, Field, Form } from "formik"
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
} from "react-google-maps"
import Button from "react-bootstrap/Button"
import { Helmet } from "react-helmet"


const MapComponent = withScriptjs(
  withGoogleMap(props => (
    <GoogleMap
      defaultZoom={12}
      defaultCenter={{ lat: 31.29624, lng: 75.593571 }}
    >
      <Marker position={{ lat: 31.29624, lng: 75.593571 }} />
    </GoogleMap>
  ))
)

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),

    color: theme.palette.text.secondary,
  },
  enquiry: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
    height: "100%",
  },
  textFieldContainer: {
    height: 15,
    padding: 8,
  },
  textAreaContainer: {
    padding: 8,
    width: "100%",
  },
}))

export const Contact = () => {
  const classes = useStyles()
  return (
    <>
    <div>
    <Helmet>
    <title>
     Contact Us | Cambridge International Foundation School 

         </title>
    </Helmet>

    </div>

       <Grid container justify="center" style={{ margin: "10px 2px" }}>
        <div
          className="teamContainer pageContainer pageContainer1"
          style={{ marginBottom: "30px" }}
        >
        <h1
          style={{
            color: "#e95721",
            fontSize: "35px",
            fontWeight: "bold",
            fontFamily: "sans-serif",
          }}
        >
          Contact Us
        </h1>

        <p>
          Do you wish to meet us and have a look around? You can request a
          guided tour by contacting us. Please note that the appointment is
          subject to availability.
        </p>

        <br />
        <h1
          style={{
            color: "#e95721",
            fontSize: "35px",
            fontWeight: "bold",
            fontFamily: "sans-serif",
          }}
        >
          Write to Us
        </h1>
        <br />
        <Formik
          initialValues={{ email: "", password: "" }}
          validate={values => {
            const errors = {}
            if (!values.email) {
              errors.email = "Required"
            } else if (
              !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
            ) {
              errors.email = "Invalid email address"
            }
            return errors
          }}
          onSubmit={(values, { setSubmitting }) => {
            let { email, name, comment, mobileNumber, purpose } = values

            // Mock some delay
            setTimeout(() => {
              fetch(
                `https://script.google.com/macros/s/AKfycbzo76nFZn7ddQ6UzM0F4o45Y0bOwtyX7PqUJKF6z6nRBR-Fr6I/exec?email=${email}&name=${name}&comment=${comment}&purpose=${purpose}&mobileNumber=${mobileNumber}&callback=?`,
                {
                  method: "GET",
                  mode: "no-cors",
                }
              )
                .then(response => {
                  setSubmitting(false)
                })
                .catch(error => {
                  setSubmitting(false)
                })
            }, 0)
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
          }) => (
            <Form onSubmit={handleSubmit}>
              <Grid
                container
                spacing={3}
                className={classes.formInputContainer}
                justify="center"
              >
                <Grid item xs={10} lg={8} xl={8}>
                  <Field name="name">
                    {({ field, meta }) => (
                      <TextField
                        placeholder="Name"
                        variant="outlined"
                        required
                        error={meta.touched && meta.error ? true : false}
                        inputProps={{
                          className: classes.textFieldContainer,
                        }}
                        fullWidth
                        helperText={
                          meta.touched && meta.error ? meta.error : ""
                        }
                        {...field}
                      />
                    )}
                  </Field>
                </Grid>
                <Grid item xs={10} lg={8} xl={8}>
                  <Field name="email">
                    {({ field, meta }) => (
                      <TextField
                        placeholder="Email"
                        variant="outlined"
                        required
                        error={meta.touched && meta.error ? true : false}
                        inputProps={{
                          className: classes.textFieldContainer,
                        }}
                        fullWidth
                        helperText={
                          meta.touched && meta.error ? meta.error : ""
                        }
                        {...field}
                      />
                    )}
                  </Field>
                </Grid>
                <Grid item xs={10} lg={8} xl={8}>
                  <Field name="mobileNumber">
                    {({ field, meta }) => (
                      <TextField
                        placeholder="Mobile number"
                        variant="outlined"
                        required
                        error={meta.touched && meta.error ? true : false}
                        inputProps={{
                          className: classes.textFieldContainer,
                        }}
                        fullWidth
                        helperText={
                          meta.touched && meta.error ? meta.error : ""
                        }
                        {...field}
                      />
                    )}
                  </Field>
                </Grid>

                <Grid item xs={10} lg={8} xl={8}>
                  <Field name="comment">
                    {({ field, meta }) => (
                      <TextareaAutosize
                        rowsMax={3}
                        placeholder="Your questions or comments.."
                        variant="outlined"
                        required
                        error={meta.touched && meta.error ? true : false}
                        inputProps={{
                          className: classes.textAreaContainer,
                        }}
                        style={{ width: "100%" }}
                        helperText={
                          meta.touched && meta.error ? meta.error : ""
                        }
                        {...field}
                      />
                    )}
                  </Field>
                </Grid>
              </Grid>
              <Grid
                container
                spacing={3}
                justify="center"
                className={classes.formInputContainer}
              >
                <Grid item xs="auto">
                  <Button
                    className="button6"
                    variant="inherit"
                    type="submit"
                    disabled={isSubmitting}
                  >
                    Submit
                  </Button>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>

        <h1
          style={{
            color: "#e95721",
            fontSize: "35px",
            fontWeight: "bold",
            fontFamily: "sans-serif",
          }}
        >
          On the Map
        </h1>

        <MapComponent
          googleMapURL="https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=AIzaSyDXoNnzaAoLMctYUbh966PLVDBlcTPpcWo"
          loadingElement={<div style={{ height: `100%` }} />}
          containerElement={<div style={{ height: `250px` }} />}
          mapElement={<div style={{ height: `100%` }} />}
        />
      </div>
      </Grid>
    </>
  )
}
