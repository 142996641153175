import React from "react"
import { Grid } from "@material-ui/core"

const KnowUs = () => (

  <div className="homeAlign" style={{padding:"10px 50px" }}>
    <h1 align="center"
      style={{
        color: "#e95721",
        fontSize: "35px",
        fontWeight: "bold",
        fontFamily: "sans-serif",
       
      }}
    >
      What is a World School-&nbsp;
      <i style={{ fontSize: "90%", color: "#0ca685" }}>
        Global standards of education
      </i>
    </h1>
    <div align="center" className="row">
      <div className="col">
        <img
          src={require("../../assets/icons/11.png")}
          alt="line"
          width="170rem"
          height="160rem"
        />
        <h3
          align="center"
          style={{
            color: "#e95721",
            fontFamily: "sans-serif",
            fontSize: "17px",
            fontWeight: "bold",
            
          }}
        >
          OUR PHILOSOPHY
        </h3>
        <p
          className="card-text text-secondary"
          style={{ color: "#8C001A", fontSize: "13px" }}
        >
          The International Baccalaureate aims to develop inquiring,
          knowledgeable and caring young people who help to create a better and
          more peaceful world through intercultural understanding and respect.
        </p>
      </div>
      <div align="center" className="col">
        <img
          src={require("../../assets/icons/22.png")}
          alt="line"
          width="170rem"
          height="160rem"
        />
        <h3
          align="center"
          style={{
            color: "#e95721",
            fontFamily: "sans-serif",
            fontSize: "17px",
            fontWeight: "bold",
           
          }}
        >
          THE CURRICULUM
        </h3>
        <p
          className="card-text text-secondary"
          style={{ color: "#8C001A", fontSize: "13px" }}
        >
          The curriculum is designed in such a manner that the learners are
          facilitated with every possible opportunity to observe, critically
          analyze, frame a hypothesis and create a theory.
        </p>
      </div>
      <div align="center" className="col">
        <img
          src={require("../../assets/icons/33.png")}
          alt="line"
          width="170rem"
          height="160rem"
        />
        <h3
          align="center"
          style={{
            color: "#e95721",
            fontFamily: "sans-serif",
            fontSize: "17px",
            fontWeight: "bold",
           
          }}
        >
          ADMISSIONS
        </h3>
        <p
          className="card-text text-secondary"
          style={{ color: "#8C001A", fontSize: "13px" }}
        >
          We try to provide every child the opportunity to create his own niche
          wherein he can explore and learn on his own pace. Along with
          individual learning they learn to work in collaboration right from
          their early years.{" "}
        </p>
      </div>
      <div align="center" className="col">
        <img
          src={require("../../assets/icons/44.png")}
          alt="line"
          width="170rem"
          height="160rem"
        />
        <h3
          align="center"
          style={{
            color: "#e95721",
            fontFamily: "sans-serif",
            fontSize: "17px",
            fontWeight: "bold",
           
          }}
        >
          BEYOND ACADEMICS
        </h3>
        <p
          className="card-text text-secondary"
          style={{ color: "#8C001A", fontSize: "13px" }}
        >
          To impart holistic development to the pupils, CIFS ensures to organize
          a number of co-curricular and extra- curricular activities
          (well-embedded in its curriculum) for the students.
        </p>
      </div>
    </div>
    </div>

)

export default KnowUs
