import React from "react"
import { Helmet } from "react-helmet"

export const AboutUs = () => (
  <main>
  <div>
    <Helmet>
    <title>
     About Us | Cambridge International Foundation School 

         </title>
    </Helmet>

    </div>
    <div className="pageContainer pageContainer1">
      <h1
        style={{
          color: "#e95721",
          fontWeight: "bold",
          fontFamily: "sans-serif",
          fontSize: "35px",
        }}
      >
        About Us
      </h1>

      <p>
        Cambridge International Foundation School is the First World School
        of Learning Wings Education Systems. It was started with a lofty
        dream of empowering young learners to be the leaders in all capacities
        and shape this world to be a better place. Our students are equipped to
        create a better future with all vitality, energy and purpose. And as we
        reflect, we feel proud and content with their success and most
        importantly their ambition to play their part in the world and make it
        good for all.
      </p>
      <p>
        <i style={{ fontSize: "150%", color: "#0ca685" }}>
          "Forget the theories and go to the core" – Sri M, Chief Patron LWES.
        </i>
      </p>

      <p>
        The guiding words of our chief patron act as the beaconing light in
        guiding us towards the betterment of the society and the world at large
        by imparting education instilled with knowledge, skill, compassion and
        spirit.
      </p>

      <h1
        style={{
          color: "#e95721",
          fontWeight: "bold",
          fontFamily: "sans-serif",
          fontSize: "35px",
        }}
      >
        Mission & Vision
      </h1>

      <p>
        The mission of Learning Wings Education Systems is to nurture the
        development of responsible, thoughtful citizens for life in an
        increasingly interdependent global society by creating environments in
        which students are challenged to explore, to create and to make
        decisions.
        <br />
        Mission of LWES is manifested in our students through continuous
        practices of learning where each student is nurtured to be free of
        imposed prejudices, access opportunities to lead, participate beyond
        stereotypes, accept challenges and compete to succeed.
      </p>
      <p>
        <i style={{ fontSize: "150%", color: "#0ca685" }}>
          Our Motto – Dream. Dare. Do
        </i>
      </p>
      <p>
        Motto is fulfilled by providing high quality academic and co-scholastic
        learning for each student to explore and dream for a better world and
        achieve beyond imagination.
      </p>
    </div>
  </main>
)
