import React from "react"
import ReactPlayer from "react-player"
import { Grid } from "@material-ui/core"
import { Helmet } from "react-helmet"

export const EarlyYears = () => (
  <main>
  <div>
    <Helmet>
    <title>
    Early Years | Cambridge International Foundation School 

         </title>
    </Helmet>

    </div>
    <Grid container style={{ margin: "10px 2px" }}>
      <div
        className="teamContainer pageContainer pageContainer1"
        style={{ marginBottom: "30px" }}
      >
        <h1
          style={{
            color: "#e95721",
            fontSize: "35px",
            fontWeight: "bold",
            fontFamily: "sans-serif",
          }}
        >
          PYP Early Years
        </h1>

        <p>
                    PYP Early Years began with a belief that each person is a unique individual on a journey . We discover and nurture a multitude of 
          hidden talents amongst these individuals for them to unleash their truest potential while they are on their respective 
          journeys. We believe that values as trust , love, gratitude, respect and self discipline build one's character and the 
          foundation is laid at a very early age. Our teaching methods not only cater to each student's learning requirements and 
          styles but also inculcate a value system while corresponding technical learning in a creative manner. 
          Our mission is to seek the talent, challenge the intellect and nurture each student's mind and body to embrace cultural 
          differences and encourage mutual love and respect.
        </p>
        

        <img style={{ paddingLeft: "40px" }} src={require("../assets/icons/medium.JPG")} alt="Early Years" />
        <br/>
        <br/>

        <h1
          style={{
            color: "#fcbc3c",
            fontSize: "25px",
            fontWeight: "bold",
            fontFamily: "sans-serif",
          }}
        >
          Sensory Learning
        </h1>

        <p>
        Let's Play and Learn. Learning through light board is based on 
        self directed activity and hands on learning.
        It provides a platform where children can express themselves and learn new concepts. 
        </p>

        <ReactPlayer
              url="https://youtu.be/ZI00KHbeR9M"
              width="80%"
            />
            <br/>
            <br/>
            <h2
              className="spaceAdjust"
              style={{
                color: "#e95721",
                fontSize: "23px",
                fontWeight: "bold",
                fontFamily: "sans-serif",
              }}
            >
              Early Years Age Groups —
            </h2>

           <h2
              className="spaceAdjust"
              style={{
                color: "#0ca685",
                fontSize: "20px", 
                fontFamily: "sans-serif",
              }}
            >
              <li>18 - 24 Months: <i> Infants </i></li>
              <li>24 - 36 Months:<i> Toddlers</i></li>
              <li>3 - 4 Years: <i>Foundation </i></li>
              <li>4 - 5 Years: <i>Beginners </i></li>
              <li>5 - 6 Years:<i> Advanced</i> </li>
              </h2>
            
            <img style={{ paddingTop: "-1000px" }} src={require("../assets/icons/css1.png")} alt="Early Years" />
         
            <br/>
            <br/>

                <h1
          style={{
            color: "#e95721",
            fontSize: "35px",
            fontWeight: "bold",
            fontFamily: "sans-serif",
          }}
        >
          Professional staff that cares 
        </h1>

        <Grid container spacing={1}>
          <Grid item xs={12} sm={12} md={6}>
            <h2
              className="spaceAdjust"
              style={{
                color: "#black",
                fontSize: "15px",
                fontWeight: "bold",
                fontFamily: "sans-serif",
              }}
            >
           Our teacher-to-child ratios meet international 
          requirements and ensure our staff have time to 
          provide the attention each child needs.
            </h2>

            <ul className="b">
              <li>18-24 Months – 6 children to 1 Teacher</li>
              <li>24-36 Months – 6 children to 1 Teacher</li>
              <li>3-4 Years – 25 Children to 1 Teacher and 1 Warden </li>
              <li>4-5 Years – 25 Children to 1 Teacher and 1 Warden</li>
              <li>5-6 Years – 25 Children to 1 Teacher and 1 Warden </li>
              
            </ul>

               <p>
          We are proud of our professional team of teachers. They have been chosen for their ability to care for educate and nurture 
young children. The staff is passionate about their work and dedicated to the children in their care. The staff is given the 
opportunity to advance their career with us through internal and external training courses and regular appraisals. We 
ensure everyone undertakes a rigorous induction programme and training course familiarizing them with our 
organization and their role within it; they also attend weekly staff meetings where good practice and knowledge is shared 
with colleagues. 

        </p>
          </Grid>

          <Grid item xs={12} sm={12} md={6} className="imageAdjust">
            <img src={require("../assets/icons/teacher1.jpg")} alt="CAIE" />
          </Grid>
        </Grid>

        <br/>


     
        <br/>
        <img style={{ paddingLeft: "100px" }} src={require("../assets/icons/learningareas.JPG")} alt="Early Years" />
 <br/>
  <br/>
   <br/>
        <h1
          style={{
            color: "#e95721",
            fontSize: "35px",
            fontWeight: "bold",
            fontFamily: "sans-serif",
          }}
        >
          Safety is our Priority
        </h1>

        <Grid container spacing={1}>
          <Grid item xs={12} sm={12} md={6}>
            <h2
              className="spaceAdjust"
              style={{
                color: "#black",
                fontSize: "15px",
                fontWeight: "bold",
                fontFamily: "sans-serif",
              }}
            >
           Children's safety and well being is our top priority. 
            Careful planning goes into our infrastructure and 
            environment to ensure the security of children. 
            </h2>


               <p>
                Our robust security policy ensures the maximum protection of our children. We operate a secure, 
                sign in/out entry system. No child is allowed to 
      leave the school with any person who is not a nominated contact person with suitable ID. Our 
      schools have 24x7 CCTV surveillance to support vigilance. To ensure safety, children are
      accompanied by wardens and conductors in vans and buses. A registered qualified school 
      nurse is present in school at all times.

        </p>
          </Grid>

          <Grid item xs={12} sm={12} md={6} className="imageAdjust">
            <img src={require("../assets/icons/safety.jpg")} alt="CAIE" />
          </Grid>
        </Grid>

        <br/>

        <br/>
        <img style={{ paddingLeft: "25px" }} src={require("../assets/icons/parentsasp.JPG")} alt="Early Years" />
 <br/>

 <img style={{ paddingLeft: "30px" }} src={require("../assets/icons/matters.JPG")} alt="Early Years" />
 <br/>
 


        

        
         
       
      </div>
    </Grid>
  </main>
)
