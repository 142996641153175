import React, { useState } from "react"
import { Grid, Button, Paper } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import { withRouter } from "react-router-dom"
import { Dropdown, Menu } from "semantic-ui-react"
import "./header.css"
import { Facebook, YouTube } from "@material-ui/icons"
import InstagramIcon from "@material-ui/icons/Instagram"
import TwitterIcon from "@material-ui/icons/Twitter"
import DehazeRoundedIcon from "@material-ui/icons/DehazeRounded"
import { Link } from "react-router-dom"

const useStyles = makeStyles(theme => ({
  logo: {
    height: 55,
    margin: 15,
    paddingLeft: "45px",
    [theme.breakpoints.down("xs")]: {
      paddingLeft: 0,
    },
  },
  paper: {
    textAlign: "center",
    width: "100%",
    position: "fixed",
    zIndex: 9999,
    backgroundColor: "white",
    top: 0,
  },
  button: {
    color: "#e95721",
    fontFamily: "sans-serif",
    fontWeight: "bold",
    fontSize: "18px",
    
  },
  buttonLink: {
    color: "#e95721",
    fontFamily: "sans-serif",
    fontWeight: "bold",
    fontSize: "18px",
    paddingTop: "12px",
    paddingLeft: "25px",
  },
  toggleIcon: {
    display: "none",
    [theme.breakpoints.down("xs")]: {
      display: "inline",
      position: "absolute",
      right: 10,
      top: 40,
    },
  },
  menuShow: {
    paddingTop: 30,
    [theme.breakpoints.down("xs")]: {
      display: "inline",
      marginTop: 60,
    },
  },
  menuHide: {
    paddingTop: 30,
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  socialLinks: {
    display: "flex",
    justifyContent: "flex-end",
    paddingLeft: 100,
  },
}))

const Header = ({ history }) => {
  const classes = useStyles()
  const [showMenu, setShowMenu] = useState(false)
  const navigate = route => {
    setShowMenu(false)
    history.push(route)
  }
  return (
    <Paper className={classes.paper} elevation={3}>
      <Grid container spacing={0}>
        <Grid item xs={2} sm={2} md={2}>
          <div>
            <Link to="/">
              <img
                className={classes.logo}
                src={require("../../assets/images/CIFS-Logo.png")}
                alt="line"
              />
            </Link>
          </div>
          <a
            className={classes.toggleIcon}
            onClick={() => {
              setShowMenu(!showMenu)
            }}
          >
            <DehazeRoundedIcon
              style={{
                fontSize: "37px",
              }}
            />
          </a>
        </Grid>
        <Grid
          item
          xs={10}
          sm={10}
          md={10}
          justify="center"
          alignItems="center"
          className={showMenu ? classes.menuShow : classes.menuHide}
        >
          <Menu horizontal stackable>
            <Button
              className={classes.button}
              onClick={() => navigate("/")}
              color="primary"
            >
              Home
            </Button>
            <Dropdown
              text="SCHOOL"
              pointing="top"
              className={classes.buttonLink}
            >
              <Dropdown.Menu>
                <Dropdown.Item onClick={() => navigate("/AboutUs")}>
                  About us
                </Dropdown.Item>
                <Dropdown.Item>
                  <Dropdown text="Team">
                    <Dropdown.Menu>
                      <Dropdown.Item onClick={() => navigate("/chairman")}>
                        Chairman
                      </Dropdown.Item>
                      <Dropdown.Item onClick={() => navigate("/CAO")}>
                        CAO
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => navigate("/principal-message")}
                      >
                        Principal
                      </Dropdown.Item>

                      <Dropdown.Item
                        onClick={() => navigate("/earlyyearsdean")}
                      >
                        Dean Sparkling Stars
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </Dropdown.Item>
                <Dropdown.Item onClick={() => navigate("/infrastructure")}>
                  Infrastructure
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
            <Dropdown
              text="ACADEMICS"
              pointing="top"
              className={classes.buttonLink}
            >
              <Dropdown.Menu>
                <Dropdown.Item>
                  <Dropdown text="Curriculum">
                    <Dropdown.Menu>
                    <Dropdown.Item onClick={() => navigate("/earlyyears")}>
                        PYP Early Years
                      </Dropdown.Item>
                      <Dropdown.Item onClick={() => navigate("/pyp")}>
                        PYP
                      </Dropdown.Item>
                      <Dropdown.Item onClick={() => navigate("/caie")}>
                        CAIE
                      </Dropdown.Item>
                      <Dropdown.Item onClick={() => navigate("/ibdp")}>
                        DP
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </Dropdown.Item>
                <Dropdown.Item onClick={() => navigate("/faculty")}>
                  Faculty
                </Dropdown.Item>
                <Dropdown.Item onClick={() => navigate("/corevalues")}>
                  Core Values
                </Dropdown.Item>

                <Dropdown.Item onClick={() => navigate("/policies")}>
                  Policies
                </Dropdown.Item>

                <Dropdown.Item onClick={() => navigate("/sat")}>
                  SAT Exam
                </Dropdown.Item>

                <Dropdown.Item onClick={() => navigate("/ap")}>
                  AP Exam
                </Dropdown.Item>
                
              </Dropdown.Menu>
            </Dropdown>
            <Dropdown
              text="EXPLORE"
              pointing="top"
              className={classes.buttonLink}
            >
              <Dropdown.Menu>
                <Dropdown.Item onClick={() => navigate("/storyhighlights")}>
                  Life at CIFS
                </Dropdown.Item>
                <Dropdown.Item onClick={() => navigate("/casprojects")}>
                  CAS Projects
                </Dropdown.Item>
                <Dropdown.Item onClick={() => navigate("/OurStory")}>
                  Our Story
                </Dropdown.Item>
                <Dropdown.Item onClick={() => navigate("/blog")}>
                  Blogs
                </Dropdown.Item>
                <Dropdown.Item onClick={() => navigate("/summercamp")}>
                  Summer Camp
                </Dropdown.Item>
                 
              </Dropdown.Menu>
            </Dropdown>
            <Dropdown
              text="ADMISSIONS"
              pointing="top"
              className={classes.buttonLink}
            >
              <Dropdown.Menu>
                <Dropdown.Item onClick={() => navigate("/policyAndProcedure")}>
                  Policy & Procedure
                </Dropdown.Item>
                <Dropdown.Item onClick={() => navigate("/admission")}>
                  Apply Online
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
            <Dropdown text="MORE" pointing="top" className={classes.buttonLink}>
              <Dropdown.Menu>
                <Dropdown.Item onClick={() => navigate("/contact")}>
                  Get in touch
                </Dropdown.Item>
                <Dropdown.Item onClick={() => navigate("/payFeesOnline")}>
                  Pay Fee online
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
            <div className={classes.socialLinks} >
              <a href="https://www.facebook.com/Cambridge-International-Foundation-School-Jalandhar-2260011530783847">
                <Facebook
                  style={{
                    color: "black",
                    paddingLeft: "10px",
                    fontSize: "35px",
                  }}
                />
              </a>
              <a href="https://www.instagram.com/lwescambridgeschools/">
                <InstagramIcon
                  style={{
                    color: "black",
                    paddingLeft: "10px",
                    fontSize: "35px",
                  }}
                />
              </a>
              <a href="https://twitter.com/cambridge_jal?s=08">
                <TwitterIcon
                  style={{
                    color: "black",
                    paddingLeft: "10px",
                    fontSize: "35px",
                  }}
                />
              </a>
              <a href="https://www.youtube.com/channel/UCJrs1psY46djYBSTIFb9fqg">
                <YouTube
                  style={{
                    color: "black",
                    paddingLeft: "10px",
                    fontSize: "37px",
                  }}
                />
              </a>
            </div>
          </Menu>
        </Grid>
      </Grid>
    </Paper>
  )
}

export default withRouter(Header)
