import React from "react"
import ReactPlayer from "react-player"
import { Grid } from "@material-ui/core"
import { Helmet } from "react-helmet"

export const Ibdp = () => (
  <main>
  <div>
    <Helmet>
    <title>
    IBDP | Cambridge International Foundation School 

         </title>
    </Helmet>

    </div>
    <Grid container style={{ margin: "10px 2px" }}>
      <div
        className="teamContainer pageContainer pageContainer1 "
        style={{ marginBottom: "30px" }}
      >
        <h1
          style={{
            color: "#e95721",
            fontSize: "35px",
            fontWeight: "bold",
            fontFamily: "sans-serif",
          }}
        >
          International Baccalaureate Diploma Programme(IBDP)
        </h1>
        <h2
          style={{
            color: "#e95721",
            fontWeight: "bold",
            fontSize: "23px",
            fontFamily: "sans-serif",
          }}
        >
          What is IB?
        </h2>

        <p>
          <i style={{ fontSize: "150%", color: "#0ca685" }}>
            International Baccalaureate Diploma (IBDP) program is an assessed
            programme for students aged 16 to 19 years.
          </i>
        </p>

        <p>
          International Baccalaureate (IB) Geneva, Switzerland provides programs
          of international education that focus on the intellectual, emotional
          and social skills needed to succeed in a rapidly globalizing world. IB
          programs emphasize students' personal development through academic
          rigor. The IB aims to develop inquiring, knowledgeable and caring
          young people.
        </p>

        <p>
          The programme addresses the intellectual, social, emotional and
          physical well-being of students and is respected by leading
          universities across the globe.
        </p>

        <Grid container spacing={1}>
          <Grid item xs={12} sm={12} md={6}>
            <h2
              className="spaceAdjust"
              style={{
                color: "#e95721",
                fontSize: "23px",
                fontWeight: "bold",
                fontFamily: "sans-serif",
              }}
            >
              Why the IB Diploma Programme is ideal preparation for university.
              (DP)
            </h2>

            <ul className="b">
              <li>It increases academic opportunity.</li>
              <li>IB students care about more than just result.</li>
              <li>
                It encourages you to become a confident and independent learner.
              </li>
              <li>The IB encourages critical thinking.</li>
              <li>Gradutes are globally minded.</li>
              <li>It's an international qualification.</li>
              <li>DP students have proven time management skills.</li>
              <li>It assesses more than examination techniques.</li>
              <li>Subjects are not tought in isolation.</li>
              <li>It encourages breadth and depth of learning.</li>
            </ul>
          </Grid>

          <Grid item xs={12} sm={12} md={6} className="imageAdjust">
            <br />
            <br />
            <img src={require("../assets/icons/ibdp.png")} alt="pyp" />
          </Grid>
        </Grid>

        <h2
          className="spaceAdjust"
          style={{
            color: "#e95721",
            fontSize: "150%",
            fontWeight: "bold",
            fontFamily: "sans-serif",
          }}
        >
          University pathways- Flexible Subject Options
        </h2>

        <Grid container spacing={1} style={{ margin: "10px 0px" }}>
          <Grid item sm={12} xs={12} md={6}>
            <i style={{ fontSize: "150%", color: "#0ca685" }}>3 CORE</i>
            <br />
            <br />
            <img
              width="250px"
              height="300px"
              src={require("../assets/icons/core3.png")}
              alt="pyp"
            />
          </Grid>

          <Grid item sm={12} xs={12} md={6} className="spaceAdjust">
            <i style={{ fontSize: "150%", color: "#0ca685" }}>6 GROUPS</i>
            <br />
            <br />
            <img
              width="2300px"
              height="350px"
              src={require("../assets/icons/core5.png")}
              alt="pyp"
            />
          </Grid>
        </Grid>
        <p className="spaceAdjust">
          <a
            href="https://www.ibo.org/programmes/diploma-programme/"
            style={{ fontWeight: "bold", fontSize: "15px" }}
          >
            Read about IBDP ⇝
          </a>
        </p>
        <p>
          Through the Diploma Programme (DP) core, students reflect on the
          nature of knowledge, complete independent research and undertake a
          project that often involves community service.
        </p>

        <Grid container spacing={1} style={{ margin: "10px 0px" }}>
          <Grid item sm={12} xs={12} md={6}>
            <ReactPlayer
              url="https://www.youtube.com/watch?v=kk6zrCQK6rk"
              width="100%"
            />
          </Grid>

          <Grid item sm={12} xs={12} md={6}>
            <ReactPlayer
              url="https://www.youtube.com/watch?v=O9yxmoKZkHY"
              width="100%"
            />
          </Grid>
        </Grid>
      </div>
    </Grid>
  </main>
)
