import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import { Paper, Grid } from "@material-ui/core"
import aboutOptions from "./aboutSection2"
import { withRouter } from "react-router-dom"

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),

    color: theme.palette.text.secondary,
  },
  enquiry: {
    padding: theme.spacing(2),

    color: theme.palette.text.secondary,
    height: "100%",
  },
  aboutSection2: {
    margin: 0,
  },
}))

const GridWrapper4 = props => {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      {props.title && (
        <Grid container spacing={0} alignItems="center" justify="center">
          {props.title}
        </Grid>
      )}

      <Grid container spacing={1}>
        <Grid item xs={16} sm={8}>
          <Paper elevation={0} className={classes.paper}>
            <props.children />
          </Paper>
        </Grid>
        <Grid item xs={14} sm={4}>
          <Paper elevation={0} className={classes.enquiry}>
            <div
              style={{
                marginLeft: "40px",
                fontSize: "15px",
                color: "blue",
                background: "#f4f0ec",
                padding: "10px",
                marginRight: "80px",
                color: "#e95721",
              }}
            >
              {aboutOptions.map(option => (
                <div
                  style={{
                    cursor: "pointer",
                  }}
                  onClick={() => props.history.push(option.link)}
                >
                  {option.title}
                </div>
              ))}
            </div>
          </Paper>
        </Grid>
      </Grid>
    </div>
  )
}

export default withRouter(GridWrapper4)
