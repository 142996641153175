import React from "react"

import value1 from "../../assets/icons/value1.jpg"
import value2 from "../../assets/icons/value2.jpg"
import value3 from "../../assets/icons/value3.jpg"
import value4 from "../../assets/icons/value4.jpg"
import value5 from "../../assets/icons/value5.jpg"
import t20 from "../../assets/titleimage/t20.png"
import { Grid } from "@material-ui/core"
import { Helmet } from "react-helmet"


export const coreValues = () => (
  <main>
  <div>
    <Helmet>
    <title>
    Core Values | Cambridge International Foundation School 

         </title>
    </Helmet>

    </div>
    <div align="center" >
    <img src={t20} alt="Our-Story" />
    </div>
    <Grid container style={{ margin: "10px 2px" }}>
      <div
        className="teamContainer pageContainer pageContainer1 "
        style={{ marginBottom: "30px" }}
      >
        <h1
          style={{
            color: "#e95721",
            fontSize: "35px",
            fontWeight: "bold",
            fontFamily: "sans-serif",
          }}
        >
          Core Values
        </h1>
        <p>
          <i style={{ fontSize: "150%", color: "#0ca685" }}>
            Rooted in traditions, looking ever onwards…
          </i>
        </p>

        <p className="card-text text-secondary">
          At Cambridge International School, the goal is to nurture the dreams
          of tender hearts. Every child is equipped with the confidence to
          pursue the dreams and realize them. In this world of turmoil and
          unrest they learn to pave their own path to success.
        </p>
      </div>
      <Grid container spacing={1} style={{ margin: "10px 0px" }}>
        <Grid align="center" item sm={12} xs={12} md={6}>
          <img
            style={{
              border: "solid #fcbc3c",
              width: "250px",
            }}
            src={value1}
            alt="Our-Story"
            loader="gradient"
            gradientPreset="life"
          />
        </Grid>

        <Grid item sm={12} xs={12} md={6}>
          <h2
            style={{
              color: "#e95721",
              fontSize: "30px",
              fontWeight: "bold",
              fontFamily: "sans-serif",
            }}
          >
            1. Academic Excellence
          </h2>
          <p className="card-text text-secondary">
            Our challenging, broad-based academic program, which promotes
            critical thinking and creativity, prepares students for success in
            university and in life.
          </p>
        </Grid>

        <Grid
          align="center"
          item
          sm={12}
          xs={12}
          md={6}
          className="spaceAdjust"
        >
          <img
            style={{
              border: "solid #fcbc3c",
              width: "250px",
            }}
            src={value2}
            alt="Our-Story"
            loader="gradient"
            gradientPreset="life"
          />
        </Grid>
        <Grid item sm={12} xs={12} md={6}>
          <h2
            style={{
              color: "#e95721",
              fontSize: "30px",
              fontWeight: "bold",
              fontFamily: "sans-serif",
            }}
          >
            2. Continuously Onwards
          </h2>
          <p className="card-text text-secondary">
            We foster ethical and spiritual growth in a community founded upon
            integrity, mutual respect, environmental stewardship and service to
            others. Athletics, the arts, outdoor education and community service
            lead to the development of healthy, self-confident individuals by
            encouraging students to participate, to strive for excellence, and
            to develop strong leadership skills.
          </p>
        </Grid>
        <Grid
          align="center"
          item
          sm={12}
          xs={12}
          md={6}
          className="spaceAdjust"
        >
          <img
            style={{
              border: "solid #fcbc3c",
              width: "250px",
            }}
            src={value3}
            alt="Our-Story"
            loader="gradient"
            gradientPreset="life"
          />
        </Grid>
        <Grid item sm={12} xs={12} md={6}>
          <h2
            style={{
              color: "#e95721",
              fontSize: "30px",
              fontWeight: "bold",
              fontFamily: "sans-serif",
            }}
          >
            3. Global Citizenship
          </h2>
          <p className="card-text text-secondary">
            Being one of the leading school, recognized by all national and
            international boards with international teaching standards and
            holistic development we prepare our students for global exposure.
          </p>
        </Grid>
        <Grid
          align="center"
          item
          sm={12}
          xs={12}
          md={6}
          className="spaceAdjust"
        >
          <img
            style={{
              border: "solid #fcbc3c",
              width: "250px",
            }}
            src={value4}
            alt="Our-Story"
            loader="gradient"
            gradientPreset="life"
          />
        </Grid>
        <Grid item sm={12} xs={12} md={6}>
          <h2
            style={{
              color: "#e95721",
              fontSize: "30px",
              fontWeight: "bold",
              fontFamily: "sans-serif",
            }}
          >
            4. Individual Integrity
          </h2>
          <p className="card-text text-secondary">
            Without individual integrity no leader can succeed. Here, in
            Learning Wings Education System, we believe that at the individual
            level integrity more than ethics, it's more about the character. It
            is those characteristics of an individual that are consistently
            considerate, compassionate, transparent, honest, and ethical. Our
            students are reliable, the one we can count on to do consistently
            what is right, and they are defenders of what is fair, just and
            acceptable.
          </p>
        </Grid>
        <Grid
          align="center"
          item
          sm={12}
          xs={12}
          md={6}
          className="spaceAdjust"
        >
          <img
            align="center"
            style={{
              border: "solid #fcbc3c",
              width: "250px",
            }}
            src={value5}
            alt="Our-Story"
            loader="gradient"
            gradientPreset="life"
          />
        </Grid>
        <Grid item sm={12} xs={12} md={6} style={{ paddingRight: "10px" }}>
          <h2
            style={{
              color: "#e95721",
              fontSize: "30px",
              fontWeight: "bold",
              fontFamily: "sans-serif",
            }}
          >
            5. Committed to serve
          </h2>
          <p className="card-text text-secondary">
            Cambridge International School sets high standards in everything
            that we do, but we also enjoy the challenge. Our highly dedicated
            staff and low teacher/student ratios allow for the development of
            positive relationships between staff and students. This helps to
            enhance the learning environment and allows us to encourage students
            to try new things and stretch their boundaries. Most significantly
            though, we know that it's the people who make a huge difference. Our
            faculty is exceptional, and we are highly committed to ensuring that
            our teachers are up-to date with the latest in educational research,
            so in turn, our students reap the benefits of their on-going
            learning.
          </p>
        </Grid>
      </Grid>
    </Grid>
  </main>
)
