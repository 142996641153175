import React from "react"
import Card from "react-bootstrap/Card"
import Button from "react-bootstrap/Button"
import TextLoop from "react-text-loop"
import { Link } from "react-router-dom"
import t25 from "../../assets/titleimage/t25.png"
import t33 from "../../assets/titleimage/t33.png"
import { Grid } from "@material-ui/core"
import { Helmet } from "react-helmet"
import Blink from 'react-blink-text';


export const StoryHighlights = () => (
  <main>
   <div>
    <Helmet>
    <title>
    Story Highlights | Cambridge International Foundation School 

         </title>
    </Helmet>
 </div>
 <div align="center">
    <img src={t33} alt="blog" />
</div>
    <Grid container justify="center" style={{ margin: "10px 2px" }}>
      <div
        className="teamContainer pageContainer1 pageContainer3 alumniVideo"
        style={{ marginBottom: "30px" }}
      >
        <h1
          style={{
            color: "#e95721",
            fontSize: "35px",
            fontWeight: "bold",
            fontFamily: "sans-serif",
          }}
        >
          Cambridge Community Stories
        </h1>
        <p style={{ fontSize: "150%", color: "#0ca685" }}>
          <i>Stories of&nbsp;</i>
          <TextLoop>
            <span>
              <strong>
                <i>Empowerment</i>
              </strong>
            </span>
            <span>
              <strong>
                <i>Opportunity</i>
              </strong>
            </span>
            <span>
              <strong>
                <i>Achievement</i>
              </strong>
            </span>
          </TextLoop>
        </p>
        <p className="card-text text-secondary">
          Since its founding, Cambridge International Foundation School has
          consistently educated hundreds of students, empowering them to evolve
          into individuals of substance. Be they doctors, engineers, teachers,
          nurses, homemakers, scientists or corporate chiefs. CIFS students are
          sure to make their mark. Here are some stories from our community that
          prove that...
        </p>



        {/* <Link to="./annualfunction" style={{
                  fontSize: "20px",
                  color: "#0000ff",
                  fontWeight: "bold",
                  fontStyle: "italic",
                  fontFamily: "sans-serif",
                  paddingTop: "10px",
                  paddingBottom: "20px"
                }} target="_blank">
                <Blink color='#01579B' text='Annual Function 2022' fontSize='80' > </Blink>
              </Link>  
        <br/>  

  {/*<Link to="./achievements" style={{
                  fontSize: "20px",
                  color: "#0000ff",
                  fontWeight: "bold",
                  fontStyle: "italic",
                  fontFamily: "sans-serif",
                  paddingTop: "10px",
                  paddingBottom: "20px"
                }} target="_blank">
                <Blink color='#01579B' text='Awards & Achievements' fontSize='80' > </Blink>
              </Link>
        <br/> */}


        
<Grid container spacing={1}>





<Grid item xs={12} sm={12} md={4}>
            <Card
              style={{
                border: "solid #fcbc3c",
              }}
            >
              <Card.Img
                variant="top"
                src={require("../../assets/stories/family.JPG")}
              />


              <h4
                align="center"
                style={{
                  fontSize: "18px",
                  color: "#e95721",
                  fontWeight: "bold",
                  fontStyle: "italic",
                  fontFamily: "sans-serif",
                  paddingTop: "10px",
                  paddingBottom: "1px"
                }}
              >
                International Family Day
              </h4>

              <Card.Text
                className="card-text text-secondary"
                align="center"
                style={{
                  fontSize: "16px",
                  textAlign: "justify",
                  padding: "10px 10px 10px 10px ",
                }}
              >
International Family Day was celebrated  to honor the importance of families and their role in society. It’s a day to recognize the diversity of families and to appreciate the love, support, and strength they provide. Our school was excited to join this global celebration with a variety of activities that promote family unity and cultural awareness.  

                <a
                  target="_blank"
                  href="https://www.facebook.com/permalink.php?story_fbid=pfbid02QgbH4wRe7QV9wujMZqPLr5DvswJZWRTXmhRCuEWaH8Qiftbhgbf4bAhvctmr9Dmpl&id=100064064347005"
                >
                  {" "}
                  <u>More</u> <br />
                </a>
              </Card.Text>
            </Card>
          </Grid>


  
  <Grid item xs={12} sm={12} md={4}>
            <Card
              style={{
                border: "solid #fcbc3c",
              }}
            >
              <Card.Img
                variant="top"
                src={require("../../assets/stories/farewell.jpg")}
              />


              <h4
                align="center"
                style={{
                  fontSize: "18px",
                  color: "#e95721",
                  fontWeight: "bold",
                  fontStyle: "italic",
                  fontFamily: "sans-serif",
                  paddingTop: "10px",
                  paddingBottom: "1px"
                }}
              >
            A Memorable Farewell
          
              </h4>

              <Card.Text
                className="card-text text-secondary"
                align="center"
                style={{
                  fontSize: "16px",
                  textAlign: "justify",
                  padding: "10px 10px 10px 10px ",
                }}
              >
The air at CIFS today filled with a bittersweet mix of nostalgia and excitement as students and teachers gathered to bid farewell to another graduating class at our school. With hearts full of memories and eyes shimmering with anticipation, we embarked on a journey to make this farewell a truly unforgettable experience.

                <a
                  target="_blank"
                  href="https://www.facebook.com/permalink.php?story_fbid=pfbid0bYBtyJjRkjtapVDpHP3L9BgyZAeo16Mudcyw5EZMG6xxWdLQzrifvueNM3KEx9fWl&id=100064064347005"
                >
                  {" "}
                  <u>More</u> <br />
                </a>
              </Card.Text>
            </Card>
          </Grid>

  
   <Grid item xs={12} sm={12} md={4}>
            <Card
              style={{
                border: "solid #fcbc3c",
              }}
            >
              <Card.Img
                variant="top"
                src={require("../../assets/stories/basketball.JPG")}
              />


              <h4
                align="center"
                style={{
                  fontSize: "18px",
                  color: "#e95721",
                  fontWeight: "bold",
                  fontStyle: "italic",
                  fontFamily: "sans-serif",
                  paddingTop: "10px",
                  paddingBottom: "1px"
                }}
              >
             Interhouse Basketball Competition
          
              </h4>

              <Card.Text
                className="card-text text-secondary"
                align="center"
                style={{
                  fontSize: "16px",
                  textAlign: "justify",
                  padding: "10px 10px 10px 10px ",
                }}
              >
                      What an EPIC showdown!
            The dust has settled, the cheers have echoed, and the courts have witnessed some jaw-dropping action! Our Interhouse Basketball Competition was an absolute blast!
                Huge congratulations to all the teams for their outstanding performance and sportsmanship. 

                <a
                  target="_blank"
                  href="https://www.facebook.com/permalink.php?story_fbid=pfbid02khPKTrs3uurJRBCoCdtpZqRQvWacgAHuDT44cdaASE6UaUjZmbVooaLoSbRcAoFzl&id=100064064347005"
                >
                  {" "}
                  <u>More</u> <br />
                </a>
              </Card.Text>
            </Card>
          </Grid>






          <Grid item xs={12} sm={12} md={4}>
            <Card
              style={{
                border: "solid #fcbc3c",
              }}
            >
              <Card.Img
                variant="top"
                src={require("../../assets/stories/studentcouncil.jpg")}
              />


              <h4
                align="center"
                style={{
                  fontSize: "18px",
                  color: "#e95721",
                  fontWeight: "bold",
                  fontStyle: "italic",
                  fontFamily: "sans-serif",
                  paddingTop: "10px",
                  paddingBottom: "1px"
                }}
              >
                Student Council 2024-25
              </h4>

              <Card.Text
                className="card-text text-secondary"
                align="center"
                style={{
                  fontSize: "16px",
                  textAlign: "justify",
                  padding: "10px 10px 10px 10px ",
                }}
              >
The much awaited moment, the declaration of results for the Student Council 2024-25 arrived, bringing with it celebration and a sense of camaraderie among the students.
Cambridge International Foundation School, congratulates the newly elected student council members.Your dedication and leadership will undoubtedly make a positive impact on the school community.  

                <a
                  target="_blank"
                  href="https://www.facebook.com/permalink.php?story_fbid=pfbid02QgbH4wRe7QV9wujMZqPLr5DvswJZWRTXmhRCuEWaH8Qiftbhgbf4bAhvctmr9Dmpl&id=100064064347005"
                >
                  {" "}
                  <u>More</u> <br />
                </a>
              </Card.Text>
            </Card>
          </Grid>


  
  <Grid item xs={12} sm={12} md={4}>
            <Card
              style={{
                border: "solid #fcbc3c",
              }}
            >
              <Card.Img
                variant="top"
                src={require("../../assets/stories/talenthunt.JPG")}
              />


              <h4
                align="center"
                style={{
                  fontSize: "18px",
                  color: "#e95721",
                  fontWeight: "bold",
                  fontStyle: "italic",
                  fontFamily: "sans-serif",
                  paddingTop: "10px",
                  paddingBottom: "1px"
                }}
              >
            Talent hunt: A Vibrant Spectrum of Creativity
          
              </h4>

              <Card.Text
                className="card-text text-secondary"
                align="center"
                style={{
                  fontSize: "16px",
                  textAlign: "justify",
                  padding: "10px 10px 10px 10px ",
                }}
              >
In a celebration of creativity and flair, our recent talent hunt extravaganza brought forth an array of captivating performances across various artistic domains. 
The music segment resonated with the beats of Bollywood, the soulful melodies of Punjabi tunes, and the contemporary rhythms of Western music.

                <a
                  target="_blank"
                  href="https://www.facebook.com/permalink.php?story_fbid=pfbid02jvcKx14Z81T8bo3AzhFgxvtihoQgff9PNmEWcnYUnAt3CMQtwqmCnS2AuejC9Uz6l&id=100064064347005"
                >
                  {" "}
                  <u>More</u> <br />
                </a>
              </Card.Text>
            </Card>
          </Grid>

  
   <Grid item xs={12} sm={12} md={4}>
            <Card
              style={{
                border: "solid #fcbc3c",
              }}
            >
              <Card.Img
                variant="top"
                src={require("../../assets/stories/earthday.JPG")}
              />


              <h4
                align="center"
                style={{
                  fontSize: "18px",
                  color: "#e95721",
                  fontWeight: "bold",
                  fontStyle: "italic",
                  fontFamily: "sans-serif",
                  paddingTop: "10px",
                  paddingBottom: "1px"
                }}
              >
             Celebrating World Earth Day
          
              </h4>

              <Card.Text
                className="card-text text-secondary"
                align="center"
                style={{
                  fontSize: "16px",
                  textAlign: "justify",
                  padding: "10px 10px 10px 10px ",
                }}
              >
              As the sun rises on this special Earth Day morning, students gathered in school's assembly area and offered prayers with hearts full of gratitude and minds open to the beauty and responsibility of our planet. Today, we immersed ourselves in the beauty and importance of our planet's "vibrant ecosystem".

                <a
                  target="_blank"
                  href="https://www.facebook.com/permalink.php?story_fbid=pfbid05cWhVYNP1n6xbJyFxCaH85zAw93DFCdqGTEjdjRQsT4CTiK1RBc9KG9AxXbWaYydl&id=100064064347005"
                >
                  {" "}
                  <u>More</u> <br />
                </a>
              </Card.Text>
            </Card>
          </Grid>







<Grid item xs={12} sm={12} md={4}>
            <Card
              style={{
                border: "solid #fcbc3c",
              }}
            >
              <Card.Img
                variant="top"
                src={require("../../assets/stories/Republicday2024.jpg")}
              />


              <h4
                align="center"
                style={{
                  fontSize: "18px",
                  color: "#e95721",
                  fontWeight: "bold",
                  fontStyle: "italic",
                  fontFamily: "sans-serif",
                  paddingTop: "10px",
                  paddingBottom: "1px"
                }}
              >
Republic Day Celebrations
              </h4>

              <Card.Text
                className="card-text text-secondary"
                align="center"
                style={{
                  fontSize: "16px",
                  textAlign: "justify",
                  padding: "10px 10px 10px 10px ",
                }}
              >
The whole campus reverberated with patriotic ardor and enthusiasm and was furnished in the colors of the 𝐓𝐢𝐫𝐚𝐧𝐠𝐚. In special assembly, the participants put forth their enthralling performances and made the event a massive success. 

                <a
                  target="_blank"
                  href="https://www.facebook.com/permalink.php?story_fbid=782487863896685&id=100064064347005"
                >
                  {" "}
                  <u>More</u> <br />
                </a>
              </Card.Text>
            </Card>
          </Grid>


  
  <Grid item xs={12} sm={12} md={4}>
            <Card
              style={{
                border: "solid #fcbc3c",
              }}
            >
              <Card.Img
                variant="top"
                src={require("../../assets/stories/Lohri2024.jpg")}
              />


              <h4
                align="center"
                style={{
                  fontSize: "18px",
                  color: "#e95721",
                  fontWeight: "bold",
                  fontStyle: "italic",
                  fontFamily: "sans-serif",
                  paddingTop: "10px",
                  paddingBottom: "1px"
                }}
              >
Lohri Celebrations
          
              </h4>

              <Card.Text
                className="card-text text-secondary"
                align="center"
                style={{
                  fontSize: "16px",
                  textAlign: "justify",
                  padding: "10px 10px 10px 10px ",
                }}
              >
Let there be light, let there be warmth, let there be excitement. The warmth and celebrations of Lohri infuse us with high spirits. Following the spirit of the festival and the excitement it brings, CIFS celebrated the Lohri and Makarsakranti in full swing.

                <a
                  target="_blank"
                  href="https://www.facebook.com/permalink.php?story_fbid=774303054715166&id=100064064347005"
                >
                  {" "}
                  <u>More</u> <br />
                </a>
              </Card.Text>
            </Card>
          </Grid>

  
   <Grid item xs={12} sm={12} md={4}>
            <Card
              style={{
                border: "solid #fcbc3c",
              }}
            >
              <Card.Img
                variant="top"
                src={require("../../assets/stories/award1.jpg")}
              />


              <h4
                align="center"
                style={{
                  fontSize: "18px",
                  color: "#e95721",
                  fontWeight: "bold",
                  fontStyle: "italic",
                  fontFamily: "sans-serif",
                  paddingTop: "10px",
                  paddingBottom: "1px"
                }}
              >
             Local student receives International Honor of US National School Society
          
              </h4>

              <Card.Text
                className="card-text text-secondary"
                align="center"
                style={{
                  fontSize: "16px",
                  textAlign: "justify",
                  padding: "10px 10px 10px 10px ",
                }}
              >
Marutt Kalra, a student of Cambridge International Foundation School, Jalandhar, Punjab receives an international honor for the superior academic achievement by US National Society of High School Scholars.

                <a
                  target="_blank"
                  href="https://www.facebook.com/permalink.php?story_fbid=773737124771759&id=100064064347005"
                >
                  {" "}
                  <u>More</u> <br />
                </a>
              </Card.Text>
            </Card>
          </Grid>

  
          <Grid item xs={12} sm={12} md={4}>
            <Card
              style={{
                border: "solid #fcbc3c",
              }}
            >
              <Card.Img
                variant="top"
                src={require("../../assets/stories/NSO.jpg")}
              />


              <h4
                align="center"
                style={{
                  fontSize: "18px",
                  color: "#e95721",
                  fontWeight: "bold",
                  fontStyle: "italic",
                  fontFamily: "sans-serif",
                  paddingTop: "10px",
                  paddingBottom: "1px"
                }}
              >
             National Science Olympiad
          
              </h4>

              <Card.Text
                className="card-text text-secondary"
                align="center"
                style={{
                  fontSize: "16px",
                  textAlign: "justify",
                  padding: "10px 10px 10px 10px ",
                }}
              >
We are delighted to announce and extend our heartfelt congratulations to three exceptional students from Cambridge International Foundation School for their outstanding performance in the National Science Olympiad (SOF).

                <a
                  target="_blank"
                  href="https://www.facebook.com/permalink.php?story_fbid=771912741620864&id=100064064347005"
                >
                  {" "}
                  <u>More</u> <br />
                </a>
              </Card.Text>
            </Card>
          </Grid>



          <Grid item xs={12} sm={12} md={4}>
            <Card
              style={{
                border: "solid #fcbc3c",
              }}
            >
              <Card.Img
                variant="top"
                src={require("../../assets/stories/christmas.jpg")}
              />


              <h4
                align="center"
                style={{
                  fontSize: "18px",
                  color: "#e95721",
                  fontWeight: "bold",
                  fontStyle: "italic",
                  fontFamily: "sans-serif",
                  paddingTop: "10px",
                  paddingBottom: "1px"
                }}
              >
             Christmas Celebration
          
              </h4>

              <Card.Text
                className="card-text text-secondary"
                align="center"
                style={{
                  fontSize: "16px",
                  textAlign: "justify",
                  padding: "10px 10px 10px 10px ",
                }}
              >
             Captivated by the spirit of the season at CIFS! The school held a special assembly delving into the rich history of Christmas, sharing stories that have woven the magic of this festive time. As we gathered in unity, the tales of tradition and the warmth of community filled our hearts. 
             Wishing everyone a Happy New Year!

                <a
                  target="_blank"
                  href="https://www.facebook.com/permalink.php?story_fbid=pfbid02xFPzo8pSNLLkJHVRRZrVP4mZpn7vKFv8bmZ3HyPZjZ4J9pFf4JdMT7DydzYWpPzJl&id=100064064347005"
                >
                  {" "}
                  <u>More</u> <br />
                </a>
              </Card.Text>
            </Card>
          </Grid>




          <Grid item xs={12} sm={12} md={4} className="imageAdjust">
            <Card
              style={{
                border: "solid #fcbc3c",
              }}
            >
              <Card.Img
                variant="top"
                src={require("../../assets/stories/pingalwara.jpg")}
              />

              <h4
                align="center"
                style={{
                  fontSize: "18px",
                  color: "#e95721",
                  fontWeight: "bold",
                  fontStyle: "italic",
                  fontFamily: "sans-serif",
                  paddingTop: "10px",
                  paddingBottom: "1px"
                }}
              >
                Special Education training
              </h4>
              <Card.Text
                className="card-text text-secondary"
                align="center"
                style={{
                  fontSize: "16px",
                  textAlign: "justify",
                  padding: "10px 10px 10px 10px ",
                }}
              >
              Driven by a thirst for knowledge and empathy, a group of female students from Pingalwara ( Amritsar) enrolled in special education training at Cambridge International Foundation School. The school's staff offered valuable insights into their programs and facilities, providing a detailed look at the available courses and activities. 
                
                <a
                  target="_blank"
                  href="https://www.facebook.com/permalink.php?story_fbid=pfbid0251a7v8yHaMNXy1QNvCnWAwSqFrQHkudsrtoFeQcvdS5fVtWcm2m8YMubavXhVmNDl&id=100064064347005"
                >
                  {" "}
                  <u>More</u>{" "}
                </a>
              </Card.Text>
            </Card>
          </Grid>



          <Grid item xs={12} sm={12} md={4} className="imageAdjust">
            <Card
              style={{
                border: "solid #fcbc3c",
              }}
            >
              <Card.Img
                variant="top"
                src={require("../../assets/stories/bookfair.JPG")}
              />

              <h4
                align="center"
                style={{
                  fontSize: "18px",
                  color: "#e95721",
                  fontWeight: "bold",
                  fontStyle: "italic",
                  fontFamily: "sans-serif",
                  paddingTop: "10px",
                  paddingBottom: "1px"
                }}
              >
                Book Fair
              </h4>
              <Card.Text
                className="card-text text-secondary"
                align="center"
                style={{
                  fontSize: "16px",
                  textAlign: "justify",
                  padding: "10px 10px 10px 10px ",
                }}
              >
              CIFS always sets an example and makes new pavements to follow. To maintain the decorum CIFS organized a Book Fair 
              by Sunshine ,New Delhi,for two days on the school premises.Inauguration of this book fair was done by Academic Director Ms. Deepa Dogra. 
               
                    
                <a
                  target="_blank"
                  href="https://www.facebook.com/permalink.php?story_fbid=pfbid02Zk4KAh2u7onXy68SyV9f8BySdd72cSK9JU4a7JAzRWMFZAxFbrahkZ2H9NSnCFwsl&id=100064064347005"
                >
                  {" "}
                  <u>More</u>{" "}
                </a>
              </Card.Text>
            </Card>
          </Grid>



          <Grid item xs={12} sm={12} md={4}>
            <Card
              style={{
                border: "solid #fcbc3c",
              }}
            >
              <Card.Img
                variant="top"
                src={require("../../assets/stories/outreach.jpg")}
              />


              <h4
                align="center"
                style={{
                  fontSize: "18px",
                  color: "#e95721",
                  fontWeight: "bold",
                  fontStyle: "italic",
                  fontFamily: "sans-serif",
                  paddingTop: "10px",
                  paddingBottom: "1px"
                }}
              >
             Science Outreach Program
          
              </h4>

              <Card.Text
                className="card-text text-secondary"
                align="center"
                style={{
                  fontSize: "16px",
                  textAlign: "justify",
                  padding: "10px 10px 10px 10px ",
                }}
              >
             The students of CIFS bagged 3rd position at the National Innovation Conclave - Science Outreach Program at District Level. The students - 𝐃𝐡𝐚𝐢𝐫𝐲𝐚 𝐇𝐚𝐫𝐢 𝐚𝐧𝐝 𝐒𝐚𝐦𝐚𝐫 𝐃𝐡𝐞𝐬𝐢 (IGCSE 9) along with 𝐌𝐚𝐫𝐮𝐭𝐭 𝐊𝐚𝐥𝐫𝐚 𝐚𝐧𝐝 𝐀𝐫𝐨𝐡𝐢 𝐌𝐚𝐡𝐚𝐣𝐚𝐧 (IBDP Jr.), brought laurels to the school and were honoured with 5000/- rupees cash prize as well. The competition was organised by Chandigarh University. 

                <a
                  target="_blank"
                  href="https://www.facebook.com/permalink.php?story_fbid=pfbid0pMQ9dqgVYSwSWwLhyALha8rKd6puNkYSdaCeyfXCoFtTJiSpRMVkFq8xuHrjMHrl&id=100064064347005"
                >
                  {" "}
                  <u>More</u> <br />
                </a>
              </Card.Text>
            </Card>
          </Grid>




          <Grid item xs={12} sm={12} md={4} className="imageAdjust">
            <Card
              style={{
                border: "solid #fcbc3c",
              }}
            >
              <Card.Img
                variant="top"
                src={require("../../assets/stories/tennis.jpg")}
              />

              <h4
                align="center"
                style={{
                  fontSize: "18px",
                  color: "#e95721",
                  fontWeight: "bold",
                  fontStyle: "italic",
                  fontFamily: "sans-serif",
                  paddingTop: "10px",
                  paddingBottom: "1px"
                }}
              >
                 Lawn Tennis & Paddle Tennis Open Tournament 
              </h4>
              <Card.Text
                className="card-text text-secondary"
                align="center"
                style={{
                  fontSize: "16px",
                  textAlign: "justify",
                  padding: "10px 10px 10px 10px ",
                }}
              >
              Cambridge Innovative School recently hosted an engaging open tournament featuring both lawn tennis and paddle tennis. The event showcased the diverse talents of our students, fostering a spirit of healthy competition and sportsmanship. 
                
                <a
                  target="_blank"
                  href="https://www.facebook.com/permalink.php?story_fbid=pfbid02EceobwsfBzDkAwiAoyPAAAtZsBcE4J47N9WPnWg4fjFiG1t9TU8ZYikeeGD8Qo7Ml&id=100064064347005"
                >
                  {" "}
                  <u>More</u>{" "}
                </a>
              </Card.Text>
            </Card>
          </Grid>



          <Grid item xs={12} sm={12} md={4} className="imageAdjust">
            <Card
              style={{
                border: "solid #fcbc3c",
              }}
            >
              <Card.Img
                variant="top"
                src={require("../../assets/stories/aagaas.jpg")}
              />

              <h4
                align="center"
                style={{
                  fontSize: "18px",
                  color: "#e95721",
                  fontWeight: "bold",
                  fontStyle: "italic",
                  fontFamily: "sans-serif",
                  paddingTop: "10px",
                  paddingBottom: "1px"
                }}
              >
                Aagaas
              </h4>
              <Card.Text
                className="card-text text-secondary"
                align="center"
                style={{
                  fontSize: "16px",
                  textAlign: "justify",
                  padding: "10px 10px 10px 10px ",
                }}
              >
              Initiated and Headed by Jasmeh Kaur Rahi, 'Aagaas' stands as a profound endeavour to make the world a better place, and to extend a helping hand to those in need. The objective of this project is to implement impactful initiatives that contribute to the betterment of both the Earth and the society, aligning with the SDGs set by the United Nations.
               
                    
                <a
                  target="_blank"
                  href="https://www.facebook.com/permalink.php?story_fbid=pfbid0mx8Phy5AYoCd4d2xVJRNkrvTucVoaJQDSYctnVLvzF7meux9CC9i1bGA5Srn5iwyl&id=100064064347005"
                >
                  {" "}
                  <u>More</u>{" "}
                </a>
              </Card.Text>
            </Card>
          </Grid>







                    <Grid item xs={12} sm={12} md={4}>
            <Card
              style={{
                border: "solid #fcbc3c",
              }}
            >
              <Card.Img
                variant="top"
                src={require("../../assets/stories/woh.jpg")}
              />


              <h4
                align="center"
                style={{
                  fontSize: "18px",
                  color: "#e95721",
                  fontWeight: "bold",
                  fontStyle: "italic",
                  fontFamily: "sans-serif",
                  paddingTop: "10px",
                  paddingBottom: "1px"
                }}
              >
             Walk of Hope 2023
          
              </h4>

              <Card.Text
                className="card-text text-secondary"
                align="center"
                style={{
                  fontSize: "16px",
                  textAlign: "justify",
                  padding: "10px 10px 10px 10px ",
                }}
              >
             Grade 9 and 11 students enjoyed a lively afternoon at Urban Estate Central Plaza, organized by 𝐑𝐨𝐨𝐭𝐬 𝐨𝐟 𝐇𝐨𝐩𝐞 – Jalandhar. The plaza, adopted recently and greened during the "𝐖𝐚𝐥𝐤 𝐨𝐟 𝐇𝐨𝐩𝐞 𝟐𝟎𝟐𝟑" event, hosted thrilling games, a pot-lunch picnic, and a 𝐁𝐢𝐨-𝐞𝐧𝐳𝐲𝐦𝐞 making activity.  

                <a
                  target="_blank"
                  href="https://www.facebook.com/permalink.php?story_fbid=pfbid0371Yxz9bn5CsWMZx12ZdZgtXwzmzk66ft1Ea6SanoMe48jUecNwitvt9jqYyXFJMLl&id=100064064347005"
                >
                  {" "}
                  <u>More</u> <br />
                </a>
              </Card.Text>
            </Card>
          </Grid>




          <Grid item xs={12} sm={12} md={4} className="imageAdjust">
            <Card
              style={{
                border: "solid #fcbc3c",
              }}
            >
              <Card.Img
                variant="top"
                src={require("../../assets/stories/members.jpg")}
              />

              <h4
                align="center"
                style={{
                  fontSize: "18px",
                  color: "#e95721",
                  fontWeight: "bold",
                  fontStyle: "italic",
                  fontFamily: "sans-serif",
                  paddingTop: "10px",
                  paddingBottom: "1px"
                }}
              >
                 Meeting the visionary members
              </h4>
              <Card.Text
                className="card-text text-secondary"
                align="center"
                style={{
                  fontSize: "16px",
                  textAlign: "justify",
                  padding: "10px 10px 10px 10px ",
                }}
              >
              Immersed in the soul-stirring teachings of 𝐒𝐫𝐢 𝐌, the students at CIFS got an opportunity to meet the visionary members of the management. 𝐌𝐫𝐬. 𝐒𝐮𝐧𝐚𝐧𝐝𝐚 𝐀𝐥𝐢, 𝐌𝐫. 𝐀𝐚𝐤𝐚𝐬𝐡 𝐂𝐡𝐨𝐩𝐫𝐚 , 𝐀𝐢𝐬𝐡𝐚 𝐂𝐡𝐨𝐩𝐫𝐚 and 𝐌𝐫. 𝐀𝐣𝐚𝐲 𝐁𝐡𝐚𝐭𝐢𝐚,our dear Chairman interacted with students and staff. 
                
                <a
                  target="_blank"
                  href="https://www.facebook.com/permalink.php?story_fbid=pfbid02QMNt5wcFnfX2xRZAFULhf7hvFqBn7fjN7kPkJepWvey2SF4ucTaY1E3b8RBnnpvJl&id=100064064347005"
                >
                  {" "}
                  <u>More</u>{" "}
                </a>
              </Card.Text>
            </Card>
          </Grid>



          <Grid item xs={12} sm={12} md={4} className="imageAdjust">
            <Card
              style={{
                border: "solid #fcbc3c",
              }}
            >
              <Card.Img
                variant="top"
                src={require("../../assets/stories/chess.jpg")}
              />

              <h4
                align="center"
                style={{
                  fontSize: "18px",
                  color: "#e95721",
                  fontWeight: "bold",
                  fontStyle: "italic",
                  fontFamily: "sans-serif",
                  paddingTop: "10px",
                  paddingBottom: "1px"
                }}
              >
                Punjab State Amateur FIDE Rating Chess Championship
              </h4>
              <Card.Text
                className="card-text text-secondary"
                align="center"
                style={{
                  fontSize: "16px",
                  textAlign: "justify",
                  padding: "10px 10px 10px 10px ",
                }}
              >
              CIFS serves as the distinguished venue for the recently held 𝐏𝐮𝐧𝐣𝐚𝐛 𝐒𝐭𝐚𝐭𝐞 𝐀𝐦𝐚𝐭𝐞𝐮𝐫 𝐁𝐞𝐥𝐨𝐰 𝟐𝟑𝟎𝟎 𝐅𝐈𝐃𝐄 𝐑𝐚𝐭𝐢𝐧𝐠 𝐂𝐡𝐞𝐬𝐬 𝐂𝐡𝐚𝐦𝐩𝐢𝐨𝐧𝐬𝐡𝐢𝐩 𝟐𝟎𝟐𝟑. The Shree Radha Sports Academy, in collaboration with the 𝐏𝐮𝐧𝐣𝐚𝐛 𝐒𝐭𝐚𝐭𝐞 𝐂𝐡𝐞𝐬𝐬 𝐀𝐬𝐬𝐨𝐜𝐢𝐚𝐭𝐢𝐨𝐧, hosted the event. 
               
                    
                <a
                  target="_blank"
                  href="https://www.facebook.com/permalink.php?story_fbid=pfbid0V4jQiScJvopCRRkbefAMg15KnTeRzqbgqi1gVLPEd2vQSY3TEMZiEoLq9u6wFjsLl&id=100064064347005"
                >
                  {" "}
                  <u>More</u>{" "}
                </a>
              </Card.Text>
            </Card>
          </Grid>


























































          <Grid item xs={12} sm={12} md={4} className="imageAdjust">
            <Card
              style={{
                border: "solid #fcbc3c",
              }}
            >
              <Card.Img
                variant="top"
                src={require("../../assets/stories/janmashtami.JPG")}
              />

              <h4
                align="center"
                style={{
                  fontSize: "18px",
                  color: "#e95721",
                  fontWeight: "bold",
                  fontStyle: "italic",
                  fontFamily: "sans-serif",
                  paddingTop: "10px",
                  paddingBottom: "1px"
                }}
              >
                Janmashtami Celebration
              </h4>
              <Card.Text
                className="card-text text-secondary"
                align="center"
                style={{
                  fontSize: "16px",
                  textAlign: "justify",
                  padding: "10px 10px 10px 10px ",
                }}
              >
              कैम्ब्रिज इंटरनेशनल फाउंडेशन स्कूल में सांस्कृतिक पर्व जन्माष्टमी (बृजोत्सव) की धूम 
नन्द के घर आनंद भयो,
हाथी घोडा पालकी, 
की कन्हैया लाल की|
विश्व में सर्वमान्य महान कूटनीतिज्ञ, विद्वान्, राजनेता, पाञ्चजन्य शंख फूंकते, सुदर्शन घुमाते, अन्याय के खिलाफ रथ का टूटा पहिया उठाते, नायक, सलाहकार, महान योद्धा, रिश्ते निभाते, शांतिप्रिय, सौन्दर्यपूर्ण, त्वरित निर्णय लेने वाले, न्यायप्रिय, धर्मनिष्ठ, क्षमाशील.....
                
                <a
                  target="_blank"
                  href="https://www.facebook.com/permalink.php?story_fbid=pfbid02AQqQqS7VgyYGXgrPLoQf64Za98xkKXDkCCaRG3dqqcJmWHadcg7c81qhM5fTQsUYl&id=100064064347005"
                >
                  {" "}
                  <u>More</u>{" "}
                </a>
              </Card.Text>
            </Card>
          </Grid>



          <Grid item xs={12} sm={12} md={4} className="imageAdjust">
            <Card
              style={{
                border: "solid #fcbc3c",
              }}
            >
              <Card.Img
                variant="top"
                src={require("../../assets/stories/teach.JPG")}
              />

              <h4
                align="center"
                style={{
                  fontSize: "18px",
                  color: "#e95721",
                  fontWeight: "bold",
                  fontStyle: "italic",
                  fontFamily: "sans-serif",
                  paddingTop: "10px",
                  paddingBottom: "1px"
                }}
              >
                Teacher's Day Celebration
              </h4>
              <Card.Text
                className="card-text text-secondary"
                align="center"
                style={{
                  fontSize: "16px",
                  textAlign: "justify",
                  padding: "10px 10px 10px 10px ",
                }}
              >
               The Teacher's Day event celebrated at CIFS was a wonderful opportunity for students to express their appreciation to their teachers! 
               The event was filled with joy and appreciation as everyone participated in fun-filled games that brought laughter and camaraderie. 
                
                <a
                  target="_blank"
                  href="https://www.facebook.com/permalink.php?story_fbid=pfbid0o5vRC1z4yYvWQD5dXEe9Cd5ZJ1FKKxgrE79vS5vhbzRKa1c4dtjvsfoHCvPxSVxVl&id=100064064347005"
                >
                  {" "}
                  <u>More</u>{" "}
                </a>
              </Card.Text>
            </Card>
          </Grid>



          <Grid item xs={12} sm={12} md={4} className="imageAdjust">
            <Card
              style={{
                border: "solid #fcbc3c",
              }}
            >
              <Card.Img
                variant="top"
                src={require("../../assets/stories/cook.JPG")}
              />

              <h4
                align="center"
                style={{
                  fontSize: "18px",
                  color: "#e95721",
                  fontWeight: "bold",
                  fontStyle: "italic",
                  fontFamily: "sans-serif",
                  paddingTop: "10px",
                  paddingBottom: "1px"
                }}
              >
                No Fire All Flavours
              </h4>
              <Card.Text
                className="card-text text-secondary"
                align="center"
                style={{
                  fontSize: "16px",
                  textAlign: "justify",
                  padding: "10px 10px 10px 10px ",
                }}
              >
               Under the theme “How we express ourselves” parents of PYP 1 of CIFS Jalandhar donned their ‘Chef Caps’ to exhibit their culinary skills in ‘No Fire All Flavours ’. The Inquiries through this activity learned the Learner profiles – Open minded, Reflective, Communicator.

                
                <a
                  target="_blank"
                  href="https://www.facebook.com/permalink.php?story_fbid=pfbid02wDP3HiGyKGFYH9h59A2i5Pyc1cJGJf4ecUof7K5ftVN47vQNbvpHX1HfvwRq7EyDl&id=100064064347005"
                >
                  {" "}
                  <u>More</u>{" "}
                </a>
              </Card.Text>
            </Card>
          </Grid>



          <Grid item xs={12} sm={12} md={4} className="imageAdjust">
            <Card
              style={{
                border: "solid #fcbc3c",
              }}
            >
              <Card.Img
                variant="top"
                src={require("../../assets/stories/maths.JPG")}
              />

              <h4
                align="center"
                style={{
                  fontSize: "18px",
                  color: "#e95721",
                  fontWeight: "bold",
                  fontStyle: "italic",
                  fontFamily: "sans-serif",
                  paddingTop: "10px",
                  paddingBottom: "1px"
                }}
              >
                Quiz competition
              </h4>
              <Card.Text
                className="card-text text-secondary"
                align="center"
                style={{
                  fontSize: "16px",
                  textAlign: "justify",
                  padding: "10px 10px 10px 10px ",
                }}
              >
              The students of Grades 6 – 12 approached the quiz house-wise with enthusiasm, demonstrating their mathematical skills and problem-solving abilities. They actively participated in each of the 4 Rounds, showcasing their understanding of different mathematical concepts. Cedar House secured first place in the quiz for their deeply significant skills that they displayed throughout the competition. 

                
                <a
                  target="_blank"
                  href="https://www.facebook.com/permalink.php?story_fbid=pfbid02LDAYPMPkmM6NYJYnjQhP8jRJY5hfhGq7BjgaRMrstNrtpVM9JCQArEMFBe7mQ787l&id=100064064347005"
                >
                  {" "}
                  <u>More</u>{" "}
                </a>
              </Card.Text>
            </Card>
          </Grid>



          <Grid item xs={12} sm={12} md={4} className="imageAdjust">
            <Card
              style={{
                border: "solid #fcbc3c",
              }}
            >
              <Card.Img
                variant="top"
                src={require("../../assets/stories/photo.JPG")}
              />

              <h4
                align="center"
                style={{
                  fontSize: "18px",
                  color: "#e95721",
                  fontWeight: "bold",
                  fontStyle: "italic",
                  fontFamily: "sans-serif",
                  paddingTop: "10px",
                  paddingBottom: "1px"
                }}
              >
                World Photography Day
              </h4>
              <Card.Text
                className="card-text text-secondary"
                align="center"
                style={{
                  fontSize: "16px",
                  textAlign: "justify",
                  padding: "10px 10px 10px 10px ",
                }}
              >
              At CIFS, the campus was abuzz with creativity and artistry as we celebrated World Photography Day with immense zeal.
Students (6-12) showcased their talents through an exhibit that showcased their diverse perspectives. 
                
                <a
                  target="_blank"
                  href="https://www.facebook.com/permalink.php?story_fbid=pfbid02QMs4eoBw3zoAWtfLyfQXYBcFABmbSW2KthgycyYa34ueD2Fa4gTza9u5mwmHEaSAl&id=100064064347005"
                >
                  {" "}
                  <u>More</u>{" "}
                </a>
              </Card.Text>
            </Card>
          </Grid>


          <Grid item xs={12} sm={12} md={4} className="imageAdjust">
            <Card
              style={{
                border: "solid #fcbc3c",
              }}
            >
              <Card.Img
                variant="top"
                src={require("../../assets/stories/independence.JPG")}
              />

              <h4
                align="center"
                style={{
                  fontSize: "18px",
                  color: "#e95721",
                  fontWeight: "bold",
                  fontStyle: "italic",
                  fontFamily: "sans-serif",
                  paddingTop: "10px",
                  paddingBottom: "1px"
                }}
              >
                Independence Day Celebration
              </h4>
              <Card.Text
                className="card-text text-secondary"
                align="center"
                style={{
                  fontSize: "16px",
                  textAlign: "justify",
                  padding: "10px 10px 10px 10px ",
                }}
              >
              कैम्ब्रिज फाउंडेशन ने शहीदों को,  देश के जांबाज़ नायकों को,  देश के क्रांतिकारियों को स्मरण करते सादर नमन किया, जिनके कारण हम आज स्वतंत्र वायु में सांस ले रहे है|  हैं| आज की आजादी, कल के बलिदानों से मिली है|  तिरंगा जो आसमान की ऊंचाइयों को छूते हुए लहराता है तो हमारे चेहरे पर खुशी छा जाती है|
बच्चों ने भाषण, कविता, व नुक्कड़ नाटक के माध्यम से वीरों को नमन किया| 
                
                <a
                  target="_blank"
                  href="https://www.facebook.com/permalink.php?story_fbid=pfbid035oVGG5d3cNtdHv2AhcWVKdyHnHPYYUKXNnwNCDCaxsH4ikKQjrCPwBmEYRACrmEMl&id=100064064347005"
                >
                  {" "}
                  <u>More</u>{" "}
                </a>
              </Card.Text>
            </Card>
          </Grid>


          <Grid item xs={12} sm={12} md={4} className="imageAdjust">
            <Card
              style={{
                border: "solid #fcbc3c",
              }}
            >
              <Card.Img
                variant="top"
                src={require("../../assets/stories/prototype.JPG")}
              />

              <h4
                align="center"
                style={{
                  fontSize: "18px",
                  color: "#e95721",
                  fontWeight: "bold",
                  fontStyle: "italic",
                  fontFamily: "sans-serif",
                  paddingTop: "10px",
                  paddingBottom: "1px"
                }}
              >
                𝗣𝗥𝗢𝗧𝗢𝗧𝗬𝗣𝗘 𝗧𝗘𝗖𝗛𝗡𝗢𝗩𝗔𝗧𝗜𝗢𝗡
              </h4>
              <Card.Text
                className="card-text text-secondary"
                align="center"
                style={{
                  fontSize: "16px",
                  textAlign: "justify",
                  padding: "10px 10px 10px 10px ",
                }}
              >
              CIFS is thrilled to announce the results of 𝗣𝗥𝗢𝗧𝗢𝗧𝗬𝗣𝗘 𝗧𝗘𝗖𝗛𝗡𝗢𝗩𝗔𝗧𝗜𝗢𝗡 where students showcased their innovation along with creativity skills. 𝗩𝗶𝗯𝗵𝗼𝗿 𝗡𝗮𝗵𝗮𝗿 and 𝗦𝘂𝗸𝗵𝗺𝗮𝗻𝗶 𝗞𝗮𝘂𝗿 𝗠𝗮𝗿𝘄𝗮𝗵𝗮 wowed the judges with their prototype of "𝗛𝘂𝗺𝗮𝗻 𝗙𝗼𝗹𝗹𝗼𝘄𝗶𝗻𝗴 𝗥𝗼𝗯𝗼𝘁" and "𝗦𝗲𝗻𝘀𝗼𝗿 𝗗𝘂𝘀𝘁𝗯𝗶𝗻" respectively. The second position is secured by 𝗗𝗵𝗿𝘂𝘃 𝗞𝗼𝗰𝗵𝗮𝗿 with his prototype "𝗘𝗺𝗲𝗿𝗴𝗲𝗻𝗰𝘆 𝗘𝗹𝗲𝗰𝘁𝗿𝗶𝗰𝗶𝘁𝘆 𝗚𝗲𝗻𝗲𝗿𝗮𝘁𝗼𝗿".
                
                <a
                  target="_blank"
                  href="https://www.facebook.com/permalink.php?story_fbid=pfbid0NpXnRUMYv1uTK68TgSLwtq3tPhkE4i1Rj8bGZdjmbp6fLRawez2xmVUoViAc7uDLl&id=100064064347005"
                >
                  {" "}
                  <u>More</u>{" "}
                </a>
              </Card.Text>
            </Card>
          </Grid>


          <Grid item xs={12} sm={12} md={4} className="imageAdjust">
            <Card
              style={{
                border: "solid #fcbc3c",
              }}
            >
              <Card.Img
                variant="top"
                src={require("../../assets/stories/youthforum.jpg")}
              />

              <h4
                align="center"
                style={{
                  fontSize: "18px",
                  color: "#e95721",
                  fontWeight: "bold",
                  fontStyle: "italic",
                  fontFamily: "sans-serif",
                  paddingTop: "10px",
                  paddingBottom: "1px"
                }}
              >
                 Global Youth's Debate Event
              </h4>
              <Card.Text
                className="card-text text-secondary"
                align="center"
                style={{
                  fontSize: "16px",
                  textAlign: "justify",
                  padding: "10px 10px 10px 10px ",
                }}
              >
              At CIFS, the Global Youth's Debate event took place. Witnessing the engaging in a battle of wits was exhilarating as the students take the stage to tackle global topics that will keep you on the edge of your seat! There's no limit to the thought-provoking discussions that took place. 
                
                <a
                  target="_blank"
                  href="https://www.facebook.com/permalink.php?story_fbid=pfbid034pG9ft999CgqH3gtWLohN8JpF31F6oPw4XfNkLmJKBMAZpjcqeSvUkosswaySehNl&id=100064064347005"
                >
                  {" "}
                  <u>More</u>{" "}
                </a>
              </Card.Text>
            </Card>
          </Grid>


          

          


          <Grid item xs={12} sm={12} md={4} className="imageAdjust">
            <Card
              style={{
                border: "solid #fcbc3c",
              }}
            >
              <Card.Img
                variant="top"
                src={require("../../assets/stories/uk.JPG")}
              />

              <h4
                align="center"
                style={{
                  fontSize: "18px",
                  color: "#e95721",
                  fontWeight: "bold",
                  fontStyle: "italic",
                  fontFamily: "sans-serif",
                  paddingTop: "10px",
                  paddingBottom: "1px"
                }}
              >
                Exchange program
              </h4>
              <Card.Text
                className="card-text text-secondary"
                align="center"
                style={{
                  fontSize: "16px",
                  textAlign: "justify",
                  padding: "10px 10px 10px 10px ",
                }}
              >
              To provide the platform for our students to interact with students around the world and gain a broader perspective CIFS is organizing an 6 to 8 months program with partnership along UK's Gerrans School.
The first phase of Exchange program was organised through online cultural exchange program. This provided the platform for our students to interact with Pre primary and Primary students of UK.
                
                <a
                  target="_blank"
                  href="https://www.facebook.com/permalink.php?story_fbid=pfbid02e8DeXe5n7tQsBPbYYnvvt6TQ6XhHnUXP7FDYXWSuiSGdeDNg2TVCUwY9BhU1N4nol&id=100064064347005"
                >
                  {" "}
                  <u>More</u>{" "}
                </a>
              </Card.Text>
            </Card>
          </Grid>


          <Grid item xs={12} sm={12} md={4} className="imageAdjust">
            <Card
              style={{
                border: "solid #fcbc3c",
              }}
            >
              <Card.Img
                variant="top"
                src={require("../../assets/stories/material.JPG")}
              />

              <h4
                align="center"
                style={{
                  fontSize: "18px",
                  color: "#e95721",
                  fontWeight: "bold",
                  fontStyle: "italic",
                  fontFamily: "sans-serif",
                  paddingTop: "10px",
                  paddingBottom: "1px"
                }}
              >
                Trash To Treasure
              </h4>
              <Card.Text
                className="card-text text-secondary"
                align="center"
                style={{
                  fontSize: "16px",
                  textAlign: "justify",
                  padding: "10px 10px 10px 10px ",
                }}
              >
               
 CAS-Trash to Treasure-Students of the Primary Year Programme of CIFS engaged in creating treasure 
 out of trash and made wonderful projects like houses from carton boxes, piggy bank, 
 🔭telescope, puzzle, almirah from cardboards and dolls, bowls, masks from paper mache.
                <a
                  target="_blank"
                  href="https://www.facebook.com/permalink.php?story_fbid=pfbid0HDSYX11cAcTkm4nrh9XVjJn1sN5hr7T5WSRbPRMrdQUcXDoLZU7vT59XAPpR9Vfql&id=100064064347005"
                >
                  {" "}
                  <u>More</u>{" "}
                </a>
              </Card.Text>
            </Card>
          </Grid>
        


          <Grid item xs={12} sm={12} md={4}>
            <Card
              style={{
                border: "solid #fcbc3c",
              }}
            >
              <Card.Img
                variant="top"
                src={require("../../assets/stories/patterns.JPG")}
              />


              <h4
                align="center"
                style={{
                  fontSize: "18px",
                  color: "#e95721",
                  fontWeight: "bold",
                  fontStyle: "italic",
                  fontFamily: "sans-serif",
                  paddingTop: "10px",
                  paddingBottom: "1px"
                }}
              >
              Human patterns of movement
          
              </h4>

              <Card.Text
                className="card-text text-secondary"
                align="center"
                style={{
                  fontSize: "16px",
                  textAlign: "justify",
                  padding: "10px 10px 10px 10px ",
                }}
              >
             Human patterns of movement reflect the conditions of a changing world and impact the 
             cultural landscapes of both, the places people leave and the places they settle in.
             Learners of PYP 4 performed an activity to understand the push and pull factors which play 
             an important role in human migration.The happiness writ large on shining faces was evident 
             enough to know that the learning was imbibed to the core of their heart.

                <a
                  target="_blank"
                  href="https://www.facebook.com/permalink.php?story_fbid=pfbid02xedM8FXTabFfN1UDczsLQKqhmqAfLahRpF2LELDdF7wxq29o9xfvKVkZk1kG7AP9l&id=100064064347005"
                >
                  {" "}
                  <u>More</u> <br />
                </a>
              </Card.Text>
            </Card>
          </Grid>



          <Grid item xs={12} sm={12} md={4} className="imageAdjust">
            <Card
              style={{
                border: "solid #fcbc3c",
              }}
            >
              <Card.Img
                variant="top"
                src={require("../../assets/stories/pvr.jpg")}
              />

              <h4
                align="center"
                style={{
                  fontSize: "18px",
                  color: "#e95721",
                  fontWeight: "bold",
                  fontStyle: "italic",
                  fontFamily: "sans-serif",
                  paddingTop: "10px",
                  paddingBottom: "1px"
                }}
              >
                PVR excursion
              </h4>
              <Card.Text
                className="card-text text-secondary"
                align="center"
                style={{
                  fontSize: "16px",
                  textAlign: "justify",
                  padding: "10px 10px 10px 10px ",
                }}
              >
              Life's greatest adventure is learning through excursions. CIFS every year organises excursions 
              to give a joyful learning experience to the students. One such step was organising excursion to friends 
              PVR for a movie “DC League of Super Pets’’. 
              The toddlers and Grade I to V students enjoyed the show munching tit bits provided by school.
                
                <a
                  target="_blank"
                  href="https://www.facebook.com/permalink.php?story_fbid=pfbid0sRa3K532nRFz6phAKcHK6do7kgrt2rHv2WNkb8gjcwP92wL7zussF8EyMvxoHL1ml&id=100064064347005"
                >
                  {" "}
                  <u>More</u>{" "}
                </a>
              </Card.Text>
            </Card>
          </Grid>
        </Grid>






        <Grid container spacing={1}>
          
          <Grid item xs={12} sm={12} md={4} className="imageAdjust">
            <Card
              style={{
                border: "solid #fcbc3c",
              }}
            >
              <Card.Img
                variant="top"
                src={require("../../assets/stories/wayoflife.JPG")}
              />

              <h4
                align="center"
                style={{
                  fontSize: "18px",
                  color: "#e95721",
                  fontWeight: "bold",
                  fontStyle: "italic",
                  fontFamily: "sans-serif",
                  paddingTop: "10px",
                  paddingBottom: "1px"
                }}
              >
                Way of Life activity
              </h4>
              <Card.Text
                className="card-text text-secondary"
                align="center"
                style={{
                  fontSize: "16px",
                  textAlign: "justify",
                  padding: "10px 10px 10px 10px ",
                }}
              >
              CIFS always believes to inculcate ethical behaviour in the pupils so as to raise them as sympathetic,educated and socially adaptable beings. 
              To promote such values an activity, ‘Way of Life’ was organized wherein students presented the common behavioural issues and their solutions with their fellow students⭐️.
               The message was quite loud and clear to be humble and humane towards every member of the society.
                
                <a
                  target="_blank"
                  href="https://www.facebook.com/permalink.php?story_fbid=pfbid0H7mC3hQRjSWyNhPrDs911NSTMABaWWUDXEVGXt7bYdTgjiSkLVi7sVEmjaKvhkSWl&id=100064064347005"
                >
                  {" "}
                  <u>More</u>{" "}
                </a>
              </Card.Text>
            </Card>
          </Grid>

          <Grid item xs={12} sm={12} md={4} className="imageAdjust">
            <Card
              style={{
                border: "solid #fcbc3c",
              }}
            >
              <Card.Img
                variant="top"
                src={require("../../assets/stories/haryali.JPG")}
              />

              <h4
                align="center"
                style={{
                  fontSize: "18px",
                  color: "#e95721",
                  fontWeight: "bold",
                  fontStyle: "italic",
                  fontFamily: "sans-serif",
                  paddingTop: "10px",
                  paddingBottom: "1px"
                }}
              >
                Mission Haryali-2022
              </h4>
              <Card.Text
                className="card-text text-secondary"
                align="center"
                style={{
                  fontSize: "16px",
                  textAlign: "justify",
                  padding: "10px 10px 10px 10px ",
                }}
              >
                The delicious sunshine, the bracing wind, the refreshing rainfall and exhilarating snow, 
                all are the precious gifts that nature has bestowed upon us. Expressing gratitude towards Mother Nature 
                and to restore its glory and magnificence, under the ‘Mission Haryali-2022' organized by Apna Punjab Foundation’, 
                CIFS has taken an initiative to encourage the students to be the Saviours of our Mother Earth. 
                The students with global welfare in mind participated with zeal and planted saplings both at their homes as well as in school premises. The pious endeavor 
                seemed to fill every soul with a new energy and hope for clean and green environment.
                <a
                  target="_blank"
                  href="https://www.facebook.com/permalink.php?story_fbid=pfbid02DfUEWnKbfn8TkrV8R6mxFHsAEgy2xWhnfvBTk1K7pzBv8Vyti8d9hDgZz9AkSSthl&id=100064064347005"
                >
                  {" "}
                  <u>More</u>{" "}
                </a>
              </Card.Text>
            </Card>
          </Grid>
        



        
          <Grid item xs={12} sm={12} md={4}>
            <Card
              style={{
                border: "solid #fcbc3c",
              }}
            >
              <Card.Img
                variant="top"
                src={require("../../assets/stories/learningwalks.JPG")}
              />

              <h4
                align="center"
                style={{
                  fontSize: "18px",
                  color: "#e95721",
                  fontWeight: "bold",
                  fontStyle: "italic",
                  fontFamily: "sans-serif",
                  paddingTop: "10px",
                  paddingBottom: "1px"
                }}
              >
              Learning Walks
          
              </h4>

              <Card.Text
                className="card-text text-secondary"
                align="center"
                style={{
                  fontSize: "16px",
                  textAlign: "justify",
                  padding: "10px 10px 10px 10px ",
                }}
              >
              Education acts as a tool for cultivating the immensity of being. 
              The education provided to the learners empowers them to their highest potential✨ 
              in such a manner that they are able to reflect their learning independently. ‘Learning Walks’ for Grades VI to VIII 
              organized in CIFS witnessed the proud learners exhibiting their conceptual learning with the help of real life models, posters, charts and digital tools. 
              The exuberance that reverberated seemed to fill every soul with a poignant
melody.
                <a
                  target="_blank"
                  href="https://www.facebook.com/permalink.php?story_fbid=pfbid0qhCwx45FT9bgeRhYjsAFRGqjmNCDBCwcrb346ScYaooHaEsF9zk4zBn4fWLBSUhVl&id=100064064347005"
                >
                  {" "}
                  <u>More</u> <br />
                </a>
              </Card.Text>
            </Card>
          </Grid>
          </Grid>





           <Grid container spacing={1}>
          <Grid item xs={12} sm={12} md={4} className="imageAdjust">
            <Card
              style={{
                border: "solid #fcbc3c",
              }}
            >
              <Card.Img
                variant="top"
                src={require("../../assets/stories/artforms.JPG")}
              />

              <h4
                align="center"
                style={{
                  fontSize: "18px",
                  color: "#e95721",
                  fontWeight: "bold",
                  fontStyle: "italic",
                  fontFamily: "sans-serif",
                  paddingTop: "10px",
                  paddingBottom: "1px"
                }}
              >
                Guest lecture on various Art Forms
              </h4>
              <Card.Text
                className="card-text text-secondary"
                align="center"
                style={{
                  fontSize: "16px",
                  textAlign: "justify",
                  padding: "10px 10px 10px 10px ",
                }}
              >
               Conniving with IB- PYP CIFS organized a guest lecture on various Art Forms to inculcate the ATL skills amongst the students of PYP. 
               The resource person Ms. Gayatri Aggarwal enlightened the students about various forms of Art. The interactive session quite clearly 
               reflected Open-minded, Knowledgeable and Social learners who delved deep into the ocean of knowledge to grab the best pearl in accordance to their level. 
               The students trying their hands on art works were able to develop self-management skills.
                
                <a
                  target="_blank"
                  href="https://www.facebook.com/permalink.php?story_fbid=pfbid02uQMEc616uedYFacniChm18uSB3jjMfQ2GSTr6Axs5mJVCDg4Mp5wigtLYrauVBVal&id=100064064347005"
                >
                  {" "}
                  <u>More</u>{" "}
                </a>
              </Card.Text>
            </Card>
          </Grid>

          <Grid item xs={12} sm={12} md={4} className="imageAdjust">
            <Card
              style={{
                border: "solid #fcbc3c",
              }}
            >
              <Card.Img
                variant="top"
                src={require("../../assets/stories/explorica.jpg")}
              />

              <h4
                align="center"
                style={{
                  fontSize: "18px",
                  color: "#e95721",
                  fontWeight: "bold",
                  fontStyle: "italic",
                  fontFamily: "sans-serif",
                  paddingTop: "10px",
                  paddingBottom: "1px"
                }}
              >
                EXPLORICA event
              </h4>
              <Card.Text
                className="card-text text-secondary"
                align="center"
                style={{
                  fontSize: "16px",
                  textAlign: "justify",
                  padding: "10px 10px 10px 10px ",
                }}
              >
                To enhance social skills, intellectual skills, moral values, personality progress, and character appeal of students, 
                we at CIFS trigger the students by encouraging them to participate in co-curricular activities inside and outside the school.
                  At the EXPLORICA event hosted at Oakridge International School in Mohali, 15 students from our school actively participated and 
                  demonstrated their potential in four categories of Kaleidoscope, Actomania, Melodia, and Pulsation. 
                We feel elated to congratulate ☺️our winners and participants for their energetic performances
                <a
                  target="_blank"
                  href="https://www.facebook.com/permalink.php?story_fbid=pfbid02gjUViLPduhEeRtjj37NwUCMMjG2e3nru35g8QnGEapZBDLU2MyH2Gf9QPSaYHPohl&id=100064064347005"
                >
                  {" "}
                  <u>More</u>{" "}
                </a>
              </Card.Text>
            </Card>
          </Grid>
        

          <Grid item xs={12} sm={12} md={4} className="imageAdjust">
            <Card
              style={{
                border: "solid #fcbc3c",
              }}
            >
              <Card.Img
                variant="top"
                src={require("../../assets/stories/phulkari.jpg")}
              />

              <h4
                align="center"
                style={{
                  fontSize: "18px",
                  color: "#e95721",
                  fontWeight: "bold",
                  fontStyle: "italic",
                  fontFamily: "sans-serif",
                  paddingTop: "10px",
                  paddingBottom: "1px"
                }}
              >
                Plastic Pollution Awareness Session
              </h4>
              <Card.Text
                className="card-text text-secondary"
                align="center"
                style={{
                  fontSize: "16px",
                  textAlign: "justify",
                  padding: "10px 10px 10px 10px ",
                }}
              >
               We always encourage students to be open minded global citizens who would like to choose Prevention over 
               Destruction. Taking a step in this direction, 
               CIFS espoused with an organization, Phullkari and organized a session on Plastic Pollution Awareness. 
               In the session Ms. Nitika Luthra, Ms. Snigdha Mahajan and Ms. Adwaita Tiwari, in an interactive way created 
               awareness about ReCharkha i.e. giving a new spin to discarded plastic. The team Phullkari (Women of Jalandhar), 
               accentuate the students about Refuse , Reduce, Recycle, Reuse and Replenish mantra to reduce plastic on earth.
                <a
                  target="_blank"
                  href="https://www.facebook.com/permalink.php?story_fbid=pfbid022DLC3ioYHY8GEutgSR7mezsM9aFQbjR2CG2RjZQK4FyESchD6nQGX2RsqWxMTDQsl&id=2260011530783847"
                >
                  {" "}
                  <u>More</u>{" "}
                </a>
              </Card.Text>
            </Card>
          </Grid>
        </Grid>
        

      

<Grid container spacing={1}>
          <Grid item xs={12} sm={12} md={4}>
            <Card
              style={{
                border: "solid #fcbc3c",
              }}
            >
              <Card.Img
                variant="top"
                src={require("../../assets/stories/learning.jpg")}
              />

              <h4
                align="center"
                style={{
                  fontSize: "18px",
                  color: "#e95721",
                  fontWeight: "bold",
                  fontStyle: "italic",
                  fontFamily: "sans-serif",
                  paddingTop: "10px",
                  paddingBottom: "1px"
                }}
              >
               Learning  Walks
          
              </h4>

              <Card.Text
                className="card-text text-secondary"
                align="center"
                style={{
                  fontSize: "16px",
                  textAlign: "justify",
                  padding: "10px 10px 10px 10px ",
                }}
              >
              Education is the kindling of a flame , not the filling of the vessel. - Socrates
CIFS young learners exhibited  through “Learning  Walks”  that learning paths never stops and it is 
enriched by stimulation.  They showcased #conceptual understanding of their #curriculum using real 
life models and posters. The complete show was infused with energy and exuberance.
                <a
                  target="_blank"
                  href="https://www.facebook.com/permalink.php?story_fbid=pfbid027WKcYf17mmLn8fuGsgXCQruMGPUyYkFkgi4DUPtvJ9w7SmHa9Ya5aSc3t5LUUxyql&id=2260011530783847"
                >
                  {" "}
                  <u>More</u> <br />
                </a>
              </Card.Text>
            </Card>
          </Grid>

          <Grid item xs={12} sm={12} md={4} className="imageAdjust">
            <Card
              style={{
                border: "solid #fcbc3c",
              }}
            >
              <Card.Img
                variant="top"
                src={require("../../assets/stories/tech.jpg")}
              />

              <h4
                align="center"
                style={{
                  fontSize: "18px",
                  color: "#e95721",
                  fontWeight: "bold",
                  fontStyle: "italic",
                  fontFamily: "sans-serif",
                  paddingTop: "10px",
                  paddingBottom: "1px"
                }}
              >
                Tech Mania Activity
              </h4>
              <Card.Text
                className="card-text text-secondary"
                align="center"
                style={{
                  fontSize: "16px",
                  textAlign: "justify",
                  padding: "10px 10px 10px 10px ",
                }}
              >
                Keeping the essentiality of Tech education, the school organised Tech Mania, 
                an activity for the kids of Grade 6-12 to present their designing and creative abilities.
                The students with eagerness and enthusiasm entered into the realm of technology. 
                The students of Grade 6-8 created a LOGO of their own enterprises presenting their creative ideas, 
                meanwhile, Grade 9-10 students prepared a video by incorporating diverse tools and Grade 11-12
                students  designed a website on their subject groups.
                The students showcased the marvels of technology through their previously learned skills .
                
                <a
                  target="_blank"
                  href="https://www.facebook.com/permalink.php?story_fbid=pfbid02YggMQjKRvr8m8kmUFHZWiafxPCxbdLcCqn1BcKRRRrfJ9pVbK3eDfawn21JzEiMvl&id=2260011530783847"
                >
                  {" "}
                  <u>More</u>{" "}
                </a>
              </Card.Text>
            </Card>
          </Grid>

          <Grid item xs={12} sm={12} md={4} className="imageAdjust">
            <Card
              style={{
                border: "solid #fcbc3c",
              }}
            >
              <Card.Img
                variant="top"
                src={require("../../assets/stories/canada.JPG")}
              />

              <h4
                align="center"
                style={{
                  fontSize: "18px",
                  color: "#e95721",
                  fontWeight: "bold",
                  fontStyle: "italic",
                  fontFamily: "sans-serif",
                  paddingTop: "10px",
                  paddingBottom: "1px"
                }}
              >
                An educational and speculative session
              </h4>
              <Card.Text
                className="card-text text-secondary"
                align="center"
                style={{
                  fontSize: "16px",
                  textAlign: "justify",
                  padding: "10px 10px 10px 10px ",
                }}
              >
               In lieu of this CIFS took another initiative to arrange for an educational and speculative session at Cambridge Innovative School, for the students to 
               foster their knowledge and understanding about their future prospects. It is vital to make students aware of their 
               abilities to attain their goals since career selections play a key part in defining the growth and development of the students. 
               The session was conducted by representatives of leading International Universities. 
            The resource persons addressed the spectrum of diverse options and routes that are accessible for the students 
             and further dwelt upon the prerequisites for various courses. 
              
                <a
                  target="_blank"
                  href="https://www.facebook.com/permalink.php?story_fbid=pfbid0Vsjoja9rEZYPt4EKVFggsngt9N6PgKQaGuedUVCzZVFPa4eQxiP56wJdoTRaPkn5l&id=2260011530783847"
                >
                  {" "}
                  <u>More</u>{" "}
                </a>
              </Card.Text>
            </Card>
          </Grid>
        </Grid>





<Grid container spacing={1}>
          <Grid item xs={12} sm={12} md={4}>
            <Card
              style={{
                border: "solid #fcbc3c",
              }}
            >
              <Card.Img
                variant="top"
                src={require("../../assets/stories/session.jpg")}
              />

              <h4
                align="center"
                style={{
                  fontSize: "18px",
                  color: "#e95721",
                  fontWeight: "bold",
                  fontStyle: "italic",
                  fontFamily: "sans-serif",
                  paddingTop: "10px",
                  paddingBottom: "1px"
                }}
              >
                Session by Ms. Nikita Kohli and Mr. Harshit Nandy
          
              </h4>

              <Card.Text
                className="card-text text-secondary"
                align="center"
                style={{
                  fontSize: "16px",
                  textAlign: "justify",
                  padding: "10px 10px 10px 10px ",
                }}
              >
               Concentrating on this fast-moving and difficult environment, where learners need to build up their vision by focusing on varied research talents, 
               CIFS offered an instructional and interesting session 
               by Ms. Nikita Kohli at Duke University (pursuing Ph.D. ) and Mr. Harshit Nandy (IB Diploma State Topper, CIFS alumni).
              The students were made familiar with the research strategies and ways of strengthening their 
              application abilities for their targeted universities. Moreover, an introspective question session 
              was also done regarding admission strategies and overseas university prerequisites
                <a
                  target="_blank"
                  href="https://www.facebook.com/permalink.php?story_fbid=pfbid02MtFTeEAAoYL9N48NHkUxXX7TJurtgGUjRK9roM8Ei6wGoctLmHNXgYsAcWZwNTpcl&id=2260011530783847"
                >
                  {" "}
                  <u>More</u> <br />
                </a>
              </Card.Text>
            </Card>
          </Grid>

          <Grid item xs={12} sm={12} md={4} className="imageAdjust">
            <Card
              style={{
                border: "solid #fcbc3c",
              }}
            >
              <Card.Img
                variant="top"
                src={require("../../assets/stories/inquiry.jpg")}
              />

              <h4
                align="center"
                style={{
                  fontSize: "18px",
                  color: "#e95721",
                  fontWeight: "bold",
                  fontStyle: "italic",
                  fontFamily: "sans-serif",
                  paddingTop: "10px",
                  paddingBottom: "1px"
                }}
              >
                Essentials of Inquiry-based Learning
              </h4>
              <Card.Text
                className="card-text text-secondary"
                align="center"
                style={{
                  fontSize: "16px",
                  textAlign: "justify",
                  padding: "10px 10px 10px 10px ",
                }}
              >
                
                CIFS believes in the ongoing upgradation of the teachers and organised a session ‘Essentials of Inquiry-based Learning’ by an IB expert
                Ms. Banumathy Gopalkrishnan.It helped the teachers to refresh their awareness with several Approaches to Inquiry in order to make their classroom teaching more effective.
                The teachers avidly engaged in the activities and discussion all through the session.
                
                <a
                  target="_blank"
                  href="https://www.facebook.com/permalink.php?story_fbid=pfbid0JUMyUDUi2ijfwbQZSNrAkpc9VPcsww41ERVvSXUCfUHJKimr6mVC3SPptonqE2SSl&id=2260011530783847"
                >
                  {" "}
                  <u>More</u>{" "}
                </a>
              </Card.Text>
            </Card>
          </Grid>

          <Grid item xs={12} sm={12} md={4} className="imageAdjust">
            <Card
              style={{
                border: "solid #fcbc3c",
              }}
            >
              <Card.Img
                variant="top"
                src={require("../../assets/stories/ideation.JPG")}
              />

              <h4
                align="center"
                style={{
                  fontSize: "18px",
                  color: "#e95721",
                  fontWeight: "bold",
                  fontStyle: "italic",
                  fontFamily: "sans-serif",
                  paddingTop: "10px",
                  paddingBottom: "1px"
                }}
              >
                Theory of Knowledge exhibition ‘IDEATION’
              </h4>
              <Card.Text
                className="card-text text-secondary"
                align="center"
                style={{
                  fontSize: "16px",
                  textAlign: "justify",
                  padding: "10px 10px 10px 10px ",
                }}
              >
               CIFS adhering to the famous saying by Andreas Wanger: ‘only theory can turn a heap of facts into a 
               tower of knowledge’; organised Theory of Knowledge exhibition ‘IDEATION’ for the students of IBDP Sr. 
               under the guidance of Ms. Ritu Pandey. The learners displayed their knowledge and awareness of the subject 
               through their prompts and objects. Parents and teachers witnessed this thoughtful and analytical journey of 
               #learners into the different domains of knowledge. It inspired learners to become conscious of themselves 
               as thinkers,encouraged to get familiar with the complexity of knowledge, 
               to interact with everybody and comprehend the necessity to conduct ethically in an uncertain world

                <a
                  target="_blank"
                  href="https://www.facebook.com/permalink.php?story_fbid=pfbid02ZoLC2i81XSTYZmnctQvMK9z4kUHzUa53uDK1PFz1ykRy7dsQ2MX3Wio6Rcv9khWul&id=2260011530783847"
                >
                  {" "}
                  <u>More</u>{" "}
                </a>
              </Card.Text>
            </Card>
          </Grid>
        </Grid>

<Grid container spacing={1}>
          <Grid item xs={12} sm={12} md={4}>
            <Card
              style={{
                border: "solid #fcbc3c",
              }}
            >
              <Card.Img
                variant="top"
                src={require("../../assets/stories/atlweek.PNG")}
              />

              <h4
                align="center"
                style={{
                  fontSize: "18px",
                  color: "#e95721",
                  fontWeight: "bold",
                  fontStyle: "italic",
                  fontFamily: "sans-serif",
                  paddingTop: "10px",
                  paddingBottom: "1px"
                }}
              >
                ATL week
          
              </h4>

              <Card.Text
                className="card-text text-secondary"
                align="center"
                style={{
                  fontSize: "16px",
                  textAlign: "justify",
                  padding: "10px 10px 10px 10px ",
                }}
              >
              Learning how to learn is one of the most important life skills.
              On this note, IBPYP celebrated the ATL week, where PYPians from Grades 1 to 5 focused 
            on displaying their innate skills with various stimulating activities and interactive learning engagements, 
            as they tuned in to their respective transdisciplinary units.The week commenced with students using six thinking hats – a concept articulated by 
            Edward De Bono for brainstorming and #innovation, followed by managing themselves..
                <a
                  target="_blank"
                  href="https://www.facebook.com/permalink.php?story_fbid=4986013984850241&id=2260011530783847&__cft__[0]=AZW7JXxQWwRHvmfKnQt8ylgU4q1ZMyR91ol4YMX753reqx1ix3RKsQ5DZzLj6n8YPSMp3iAr5FUbhK8dJ-lUlIo8UN63hM6odo8Dpp60E7tyLH0dO-3gGsuUytOAQUodPLZWswN6b6f-xC3ZFaLmIODq&__tn__=%2CO%2CP-R"
                >
                  {" "}
                  <u>More</u> <br />
                </a>
              </Card.Text>
            </Card>
          </Grid>

          <Grid item xs={12} sm={12} md={4} className="imageAdjust">
            <Card
              style={{
                border: "solid #fcbc3c",
              }}
            >
              <Card.Img
                variant="top"
                src={require("../../assets/stories/ciaflo.PNG")}
              />

              <h4
                align="center"
                style={{
                  fontSize: "18px",
                  color: "#e95721",
                  fontWeight: "bold",
                  fontStyle: "italic",
                  fontFamily: "sans-serif",
                  paddingTop: "10px",
                  paddingBottom: "1px"
                }}
              >
                Awareness session with CIALFO
              </h4>
              <Card.Text
                className="card-text text-secondary"
                align="center"
                style={{
                  fontSize: "16px",
                  textAlign: "justify",
                  padding: "10px 10px 10px 10px ",
                }}
              >
                CIFS is committed to give the wholehearted awareness to the students in the field of education 
                and their #career building. Today the school organised an awareness session in association with 
                #CIALFO to guide the students of Grade 9 and 10 about the framing of their portfolios to start 
                their journey into their future goals. The students and parents enthusiastically attended this 
                informative session. They were further acquainted with the various ways to search for their appropriate universities as per the selection of their courses by conducting surveys.
                
                <a
                  target="_blank"
                  href="https://www.facebook.com/permalink.php?story_fbid=4982093068575666&id=2260011530783847"
                >
                  {" "}
                  <u>More</u>{" "}
                </a>
              </Card.Text>
            </Card>
          </Grid>

          <Grid item xs={12} sm={12} md={4} className="imageAdjust">
            <Card
              style={{
                border: "solid #fcbc3c",
              }}
            >
              <Card.Img
                variant="top"
                src={require("../../assets/stories/ngo.PNG")}
              />

              <h4
                align="center"
                style={{
                  fontSize: "18px",
                  color: "#e95721",
                  fontWeight: "bold",
                  fontStyle: "italic",
                  fontFamily: "sans-serif",
                  paddingTop: "10px",
                  paddingBottom: "1px"
                }}
              >
                Orientation session in collaboration with the NGO 'Habitat for Humanity
              </h4>
              <Card.Text
                className="card-text text-secondary"
                align="center"
                style={{
                  fontSize: "16px",
                  textAlign: "justify",
                  padding: "10px 10px 10px 10px ",
                }}
              >
               CIFS held an orientation session for IBDP (11th & 12th) students, based on Eric Yuan's words, "Empathy, humanity, 
               and support for one another is more essential than money, than growth." The event, which was organised in collaboration with the NGO 'Habitat for Humanity,' 
               was an attempt to instil in the children a sense of humanity and selflessness by motivating them to hold hands 🤝and brave numerous hardships in order to 
               support the homeless and help the girls continue in school.
                <a
                  target="_blank"
                  href="https://www.facebook.com/permalink.php?story_fbid=4957525984365708&id=2260011530783847"
                >
                  {" "}
                  <u>More</u>{" "}
                </a>
              </Card.Text>
            </Card>
          </Grid>
        </Grid>





<Grid container spacing={1}>
         

          <Grid item xs={12} sm={12} md={4} className="imageAdjust">
            <Card
              style={{
                border: "solid #fcbc3c",
              }}
            >
              <Card.Img
                variant="top"
                src={require("../../assets/stories/duties.PNG")}
              />

              <h4
                align="center"
                style={{
                  fontSize: "18px",
                  color: "#e95721",
                  fontWeight: "bold",
                  fontStyle: "italic",
                  fontFamily: "sans-serif",
                  paddingTop: "10px",
                  paddingBottom: "1px"
                }}
              >
                Appreciation Badges to PYP Student Council
              </h4>
              <Card.Text
                className="card-text text-secondary"
                align="center"
                style={{
                  fontSize: "16px",
                  textAlign: "justify",
                  padding: "10px 10px 10px 10px ",
                }}
              >
                It is rightly said that responsibility is demonstrated by actions and deeds; 
                not by words or information inserted in some glossy manner. 
                That's what has been reflected by our PYP Student Council. 
                They performed their duties diligently. Today they have been awarded with the badges by our 
                worthy Principal Ma'am as a token of appreciation.
                
                <a
                  target="_blank"
                  href="https://www.facebook.com/permalink.php?story_fbid=4869860576465583&id=2260011530783847"
                >
                  {" "}
                  <u>More</u>{" "}
                </a>
              </Card.Text>
            </Card>
          </Grid>

          <Grid item xs={12} sm={12} md={4} className="imageAdjust">
            <Card
              style={{
                border: "solid #fcbc3c",
              }}
            >
              <Card.Img
                variant="top"
                src={require("../../assets/stories/confab.PNG")}
              />

              <h4
                align="center"
                style={{
                  fontSize: "18px",
                  color: "#e95721",
                  fontWeight: "bold",
                  fontStyle: "italic",
                  fontFamily: "sans-serif",
                  paddingTop: "10px",
                  paddingBottom: "1px"
                }}
              >
                CONFAB-A Series of Talks
              </h4>
              <Card.Text
                className="card-text text-secondary"
                align="center"
                style={{
                  fontSize: "16px",
                  textAlign: "justify",
                  padding: "10px 10px 10px 10px ",
                }}
              >
               CIFS today held ‘CONFAB-A Series of Talks' in which the teachers and parents talked and thought 💭 about the #future possibilities for the learners. 
               They also reflect on the strategies and means to create comprehensive #
               development and changes in the learners of Grade 6,7, and 8. This, nonetheless, 
               led to the valuable insights and ideas on building up the prospective learners in a superior approach.

                <a
                  target="_blank"
                  href="https://www.facebook.com/permalink.php?story_fbid=4818552978263010&id=2260011530783847"
                >
                  {" "}
                  <u>More</u>{" "}
                </a>
              </Card.Text>
            </Card>
          </Grid>
        


          <Grid item xs={12} sm={12} md={4}>
            <Card
              style={{
                border: "solid #fcbc3c",
              }}
            >
              <Card.Img
                variant="top"
                src={require("../../assets/stories/careertalk.PNG")}
              />

              <h4
                align="center"
                style={{
                  fontSize: "18px",
                  color: "#e95721",
                  fontWeight: "bold",
                  fontStyle: "italic",
                  fontFamily: "sans-serif",
                  paddingTop: "10px",
                  paddingBottom: "1px"
                }}
              >
                Career Talk India 2022
          
              </h4>

              <Card.Text
                className="card-text text-secondary"
                align="center"
                style={{
                  fontSize: "16px",
                  textAlign: "justify",
                  padding: "10px 10px 10px 10px ",
                }}
              >
              In response, CIFS hosted a Career Talk India 2022 webinar today in partnership with the William Angliss Institute. 
              We are honoured to present this International Career Talk forum to our students via Study Melbourne Hub in collaboration with Bruce Bradfield, International Marketing Manager, 
              William Angliss Institute, and alumnus Vijay Murugan. They shared their professional advice on how to make the most of their time 🕰and possibilities while learning with one of
              Australia's leading hospitality training companies.
                <a
                  target="_blank"
                  href="https://www.facebook.com/permalink.php?story_fbid=4796384337146541&id=2260011530783847"
                >
                  {" "}
                  <u>More</u> <br />
                </a>
              </Card.Text>
            </Card>
          </Grid>
          </Grid>


          <Grid container spacing={1}>
          <Grid item xs={12} sm={12} md={4} className="imageAdjust">
            <Card
              style={{
                border: "solid #fcbc3c",
              }}
            >
              <Card.Img
                variant="top"
                src={require("../../assets/stories/iimun.PNG")}
              />

              <h4
                align="center"
                style={{
                  fontSize: "18px",
                  color: "#e95721",
                  fontWeight: "bold",
                  fontStyle: "italic",
                  fontFamily: "sans-serif",
                  paddingTop: "10px",
                  paddingBottom: "1px"
                }}
              >
                IIMUN session
              </h4>
              <Card.Text
                className="card-text text-secondary"
                align="center"
                style={{
                  fontSize: "16px",
                  textAlign: "justify",
                  padding: "10px 10px 10px 10px ",
                }}
              >
                CIFS in association with IIMUN conducted a Pro- bono session for students for the upcoming IIMUN session
                 in the month of May.
                 Students attended this session with great enthusiasm and exhibited immense interest.
                
                <a
                  target="_blank"
                  href="https://www.facebook.com/permalink.php?story_fbid=4777776429007332&id=2260011530783847"
                >
                  {" "}
                  <u>More</u>{" "}
                </a>
              </Card.Text>
            </Card>
          </Grid>

          <Grid item xs={12} sm={12} md={4} className="imageAdjust">
            <Card
              style={{
                border: "solid #fcbc3c",
              }}
            >
              <Card.Img
                variant="top"
                src={require("../../assets/stories/mathcir.PNG")}
              />

              <h4
                align="center"
                style={{
                  fontSize: "18px",
                  color: "#e95721",
                  fontWeight: "bold",
                  fontStyle: "italic",
                  fontFamily: "sans-serif",
                  paddingTop: "10px",
                  paddingBottom: "1px"
                }}
              >
                PiAMC-The Asian Math Circuit
              </h4>
              <Card.Text
                className="card-text text-secondary"
                align="center"
                style={{
                  fontSize: "16px",
                  textAlign: "justify",
                  padding: "10px 10px 10px 10px ",
                }}
              >
               Achievers set standards for being outstanding and refining themselves every day. CIFS celebrates the achievements of two such students who have brought 
               laurels to school showcasing their talent and entering the *Hall of Fame* for ‘PiAMC-The Asian Math Circuit’.
            Reyansh Gupta, a student of Grade 5 PYP has become one of the Pioneer awardees scoring 15th rank amongst 300,000 students from 16 countries throughout Asia.🗾
            Pranshu Gupta..
                <a
                  target="_blank"
                  href="https://www.facebook.com/permalink.php?story_fbid=4856333877818253&id=2260011530783847"
                >
                  {" "}
                  <u>More</u>{" "}
                </a>
              </Card.Text>
            </Card>
          </Grid>
        
        
          <Grid item xs={12} sm={12} md={4}>
            <Card
              style={{
                border: "solid #fcbc3c",
              }}
            >
              <Card.Img
                variant="top"
                src={require("../../assets/stories/science11.PNG")}
              />

              <h4
                align="center"
                style={{
                  fontSize: "18px",
                  color: "#e95721",
                  fontWeight: "bold",
                  fontStyle: "italic",
                  fontFamily: "sans-serif",
                  paddingTop: "10px",
                  paddingBottom: "1px"
                }}
              >
                Science Symposium
          
              </h4>

              <Card.Text
                className="card-text text-secondary"
                align="center"
                style={{
                  fontSize: "16px",
                  textAlign: "justify",
                  padding: "10px 10px 10px 10px ",
                }}
              >
              To enhance critical thinking🧠, problem solving and analytical skills a ‘Science Symposium’ 
              was conducted in CIFS, wherein the students exhibited their open-mindedness and #creativity 
              through their projects in the form of simulations, programming, working models or giving a 
              hypothetical solution to some local issue. Beholding their creativity filled the audience with a 
              sense of reverence and pride...
                <a
                  target="_blank"
                  href="https://www.facebook.com/permalink.php?story_fbid=4516315111820133&id=2260011530783847"
                >
                  {" "}
                  <u>More</u> <br />
                </a>
              </Card.Text>
            </Card>
          </Grid>
          </Grid>




          <Grid container spacing={1}>
          <Grid item xs={12} sm={12} md={4} className="imageAdjust">
            <Card
              style={{
                border: "solid #fcbc3c",
              }}
            >
              <Card.Img
                variant="top"
                src={require("../../assets/stories/doodle.jpg")}
              />

              <h4
                align="center"
                style={{
                  fontSize: "18px",
                  color: "#e95721",
                  fontWeight: "bold",
                  fontStyle: "italic",
                  fontFamily: "sans-serif",
                  paddingTop: "10px",
                  paddingBottom: "1px"
                }}
              >
                Let’s Doodle Competition
              </h4>
              <Card.Text
                className="card-text text-secondary"
                align="center"
                style={{
                  fontSize: "16px",
                  textAlign: "justify",
                  padding: "10px 10px 10px 10px ",
                }}
              >
              CIFS organized ‘Let’s Doodle Competition’ for the IBPYP Learners as the
              recent research in neuroscience and psychology  shows that doodling helps children to stay focused, 
              grasp new concepts and retain information. A blank page can serve as an extending playing field for the brain 
              of young learners. Just as we need to warm up our bodies for physical wellbeing, we need to warm up our fingers ,
              wrists and shoulders and get in the creative mindset for art...
                
                <a
                  target="_blank"
                  href="https://www.facebook.com/permalink.php?story_fbid=4513160645468913&id=2260011530783847"
                >
                  {" "}
                  <u>More</u>{" "}
                </a>
              </Card.Text>
            </Card>
          </Grid>

          <Grid item xs={12} sm={12} md={4} className="imageAdjust">
            <Card
              style={{
                border: "solid #fcbc3c",
              }}
            >
              <Card.Img
                variant="top"
                src={require("../../assets/stories/weaving.PNG")}
              />

              <h4
                align="center"
                style={{
                  fontSize: "18px",
                  color: "#e95721",
                  fontWeight: "bold",
                  fontStyle: "italic",
                  fontFamily: "sans-serif",
                  paddingTop: "10px",
                  paddingBottom: "1px"
                }}
              >
                Weaving with nature Activity
              </h4>
              <Card.Text
                className="card-text text-secondary"
                align="center"
                style={{
                  fontSize: "16px",
                  textAlign: "justify",
                  padding: "10px 10px 10px 10px ",
                }}
              >
               CIFS organized an activity ‘Weaving with nature’ on November 25, 2021 for IBPYP Nursery-KG II. 
               The activity was to build fine motor skills and to enhance hand- eye coordination of the 
               young learners. Through this activity children learnt to create patterns with wool,twigs and 
               dry leaves🍃🍂...
                <a
                  target="_blank"
                  href="https://www.facebook.com/permalink.php?story_fbid=4509928242458820&id=2260011530783847"
                >
                  {" "}
                  <u>More</u>{" "}
                </a>
              </Card.Text>
            </Card>
          </Grid>
        


          <Grid item xs={12} sm={12} md={4}>
            <Card
              style={{
                border: "solid #fcbc3c",
              }}
            >
              <Card.Img
                variant="top"
                src={require("../../assets/stories/post15.PNG")}
              />

              <h4
                align="center"
                style={{
                  fontSize: "18px",
                  color: "#e95721",
                  fontWeight: "bold",
                  fontStyle: "italic",
                  fontFamily: "sans-serif",
                  paddingTop: "10px",
                  paddingBottom: "1px"
                }}
              >
                Webinar in association with ‘Vigyantram’
          
              </h4>

              <Card.Text
                className="card-text text-secondary"
                align="center"
                style={{
                  fontSize: "16px",
                  textAlign: "justify",
                  padding: "10px 10px 10px 10px ",
                }}
              >
              CIFS always believes in generating opportunities for students to work together for self-motivation,
              one such step is the webinar series organized in association with ‘Vigyantram’. A webinar💻 on
            ‘Implementation of Artificial Intelligence’ for Checkpoint 6-8 enlightened 
              the students on the ways AI can be employed for facilitating life...
                <a
                  target="_blank"
                  href="https://www.facebook.com/permalink.php?story_fbid=4487596921358619&id=2260011530783847"
                >
                  {" "}
                  <u>More</u> <br />
                </a>
              </Card.Text>
            </Card>
          </Grid>
          </Grid>


          

        
        



        <Grid container spacing={1}>
          <Grid item xs={12} sm={12} md={4}>
            <Card
              style={{
                border: "solid #fcbc3c",
              }}
            >
              <Card.Img
                variant="top"
                src={require("../../assets/stories/web1.PNG")}
              />

              <h4
                align="center"
                style={{
                  fontSize: "18px",
                  color: "#e95721",
                  fontWeight: "bold",
                  fontStyle: "italic",
                  fontFamily: "sans-serif",
                  paddingTop: "10px",
                  paddingBottom: "1px"
                }}
              >
                Webinar Series in collaboration with ‘Vigyantram’
              </h4>

              <Card.Text
                className="card-text text-secondary"
                align="center"
                style={{
                  fontSize: "16px",
                  textAlign: "justify",
                  padding: "10px 10px 10px 10px ",
                }}
              >
              CIFS believing in words of David Warlick ‘We need technology in every classroom and 
              in every student and teacher's hand, because it is the pen and paper  of our time, 
              and it is the lens through which we experience much of our world’, organised a webinar 💻series 
              in collaboration with ‘Vigyantram’ to enable the students be technology experts, so that they are 
              dexterous enough to face the real life challenges in the ever changing world...
                <a
                  target="_blank"
                  href="https://www.facebook.com/permalink.php?story_fbid=4467629656688679&id=2260011530783847"
                >
                  {" "}
                  <u>More</u> <br />
                </a>
              </Card.Text>
            </Card>
          </Grid>

          <Grid item xs={12} sm={12} md={4} className="imageAdjust">
            <Card
              style={{
                border: "solid #fcbc3c",
              }}
            >
              <Card.Img
                variant="top"
                src={require("../../assets/stories/slc.PNG")}
              />

              <h4
                align="center"
                style={{
                  fontSize: "18px",
                  color: "#e95721",
                  fontWeight: "bold",
                  fontStyle: "italic",
                  fontFamily: "sans-serif",
                  paddingTop: "10px",
                  paddingBottom: "1px"
                }}
              >
                Student-Led Conference
              </h4>
              <Card.Text
                className="card-text text-secondary"
                align="center"
                style={{
                  fontSize: "16px",
                  textAlign: "justify",
                  padding: "10px 10px 10px 10px ",
                }}
              >
                To showcase child’s learning, a Student-Led Conference was conducted at CIFS on 29th October, 2021 for IBPYP 
                Nursery to V. Students reflected on their learning based on the past transdisciplinary themes covered 
                and parents supported their learning by becoming an integral part of it. Students actively got engaged with 
                parents and shared their projects and learning engagements from different subject areas...
                 
                
                <a
                  target="_blank"
                  href="https://www.facebook.com/permalink.php?story_fbid=4412674042184241&id=2260011530783847"
                >
                  {" "}
                  <u>More</u>{" "}
                </a>
              </Card.Text>
            </Card>
          </Grid>

          <Grid item xs={12} sm={12} md={4} className="imageAdjust">
            <Card
              style={{
                border: "solid #fcbc3c",
              }}
            >
              <Card.Img
                variant="top"
                src={require("../../assets/stories/hallo.PNG")}
              />

              <h4
                align="center"
                style={{
                  fontSize: "18px",
                  color: "#e95721",
                  fontWeight: "bold",
                  fontStyle: "italic",
                  fontFamily: "sans-serif",
                  paddingTop: "10px",
                  paddingBottom: "1px"
                }}
              >
                Halloween Celebration
              </h4>
              <Card.Text
                className="card-text text-secondary"
                align="center"
                style={{
                  fontSize: "16px",
                  textAlign: "justify",
                  padding: "10px 10px 10px 10px ",
                }}
              >
                On the eve of Halloween 🎃 , students of IBPYP grades Nursery to V dressed in their 
                favourite spookiest costume paraded in the school campus 🏫 and danced 🕺 to the scary 
                dance tunes.They were in awe of all the different costumes and overall, it was high-spirited 
                day adding a tinge of haunt to the October calendar...
    
                <a
                  target="_blank"
                  href="https://www.facebook.com/permalink.php?story_fbid=4410054515779527&id=2260011530783847"
                >
                  {" "}
                  <u>More</u>{" "}
                </a>
              </Card.Text>
            </Card>
          </Grid>
        </Grid>
  



        <Grid container spacing={1}>
          <Grid item xs={12} sm={12} md={4}>
            <Card
              style={{
                border: "solid #fcbc3c",
              }}
            >
              <Card.Img
                variant="top"
                src={require("../../assets/stories/post7.PNG")}
              />

              <h4
                align="center"
                style={{
                  color: "#e95721",
                  fontWeight: "bold",
                  fontFamily: "sans-serif",
                  paddingTop: "20px"
                }}
              >
                Dance and Sports Activities. 
              </h4>

              <Card.Text
                className="card-text text-secondary"
                align="center"
                style={{
                  fontSize: "16px",
                  textAlign: "justify",
                  padding: "10px 10px 10px 10px ",
                }}
              >
              To foster wellness, healthy lifestyle and incorporate fitness , young learners from IBPYP Nursery to grade 5 were exposed 
              to various dance 💃🏻 and sports 🏀 activities. 
              Students participated enthusiastically to the tunes of Zumba and the tiny tots danced to the wake up song. 
              They also played hula hoops ladders and hurdle race. 
              All in all it was a fun-filled experience as the students were welcomed back to school...
                <a
                  target="_blank"
                  href="https://www.facebook.com/watch/?v=405973077734145"
                >
                  {" "}
                  <u>More</u> <br />
                </a>
              </Card.Text>
            </Card>
          </Grid>

          

          <Grid item xs={12} sm={12} md={4} className="imageAdjust">
            <Card
              style={{
                border: "solid #fcbc3c",
              }}
            >
              <Card.Img
                variant="top"
                src={require("../../assets/stories/post9.PNG")}
              />

              <h4
                align="center"
                style={{
                  fontSize: "18px",
                  color: "#e95721",
                  fontWeight: "bold",
                  fontStyle: "italic",
                  fontFamily: "sans-serif",
                  paddingTop: "10px",
                  paddingBottom: "1px"
                }}
              >
                Clash of Pi Competition
              </h4>
              <Card.Text
                className="card-text text-secondary"
                align="center"
                style={{
                  fontSize: "16px",
                  textAlign: "justify",
                  padding: "10px 10px 10px 10px ",
                }}
              >
                CIFS has always boosted the students to face challenges bravely. One such challenge was ‘Clash of Pi’, a
                competition conducted by ‘Asian Math Circuit’. Once again the students have proved their acumen
                and brought laurels ✨to school. We feel proud to announce that seven of the IBPYP and CAIE students 
                secured School ranks – Ayaan Walia, Diya Kalra, Harshal Aggarwal, Yuvraj Gupta, Reyansh Gupta,
              Ansh Goel, Arohi Mahajan....
    
                <a
                  target="_blank"
                  href="https://www.facebook.com/permalink.php?story_fbid=4358850617566584&id=2260011530783847"
                >
                  {" "}
                  <u>More</u>{" "}
                </a>
              </Card.Text>
            </Card>
          </Grid>
        </Grid>

        <Grid container spacing={1}>
          <Grid item xs={12} sm={12} md={4}>
            <Card
              style={{
                border: "solid #fcbc3c",
              }}
            >
              <Card.Img
                variant="top"
                src={require("../../assets/stories/post10.PNG")}
              />

              <h4
                align="center"
                style={{
                  fontSize: "18px",
                  color: "#e95721",
                  fontWeight: "bold",
                  fontStyle: "italic",
                  fontFamily: "sans-serif",
                  paddingTop: "10px",
                  paddingBottom: "1px"
                }}
              >
                44th Junior Punjab State Swimming Championship
              </h4>

              <Card.Text
                className="card-text text-secondary"
                align="center"
                style={{
                  fontSize: "16px",
                  textAlign: "justify",
                  padding: "10px 10px 10px 10px ",
                }}
              >
                 A person who believes himself to be brave and talented is capable of 
                 converting dreams into reality’. Arohi Mahajan an IGCSE student at CIFS 
                 is one of those persons who has brought laurels to school by bagging 2nd position
                 🥈in the 44th Junior Punjab State Swimming Championship🏊‍.... ”
                <a
                  target="_blank"
                  href="https://www.facebook.com/permalink.php?story_fbid=4346104838841162&id=2260011530783847"
                >
                  {" "}
                  <u>More</u> <br />
                </a>
              </Card.Text>
            </Card>
          </Grid>

          <Grid item xs={12} sm={12} md={4} className="imageAdjust">
            <Card
              style={{
                border: "solid #fcbc3c",
              }}
            >
              <Card.Img
                variant="top"
                src={require("../../assets/stories/post11.PNG")}
              />

              <h4
                align="center"
                style={{
                  fontSize: "18px",
                  color: "#e95721",
                  fontWeight: "bold",
                  fontStyle: "italic",
                  fontFamily: "sans-serif",
                  paddingTop: "10px",
                  paddingBottom: "1px"
                }}
              >
                Webinar Liberal Education
              </h4>
              <Card.Text
                className="card-text text-secondary"
                align="center"
                style={{
                  fontSize: "16px",
                  textAlign: "justify",
                  padding: "10px 10px 10px 10px ",
                }}
              >
                CIFS today organised an extensive path towards career through a Webinar 
                💻Liberal Education in India, in accordance with Ashoka University. 
                This provided students with introduction to the concept and multi-disciplinarity 
                of liberal education,its application in the Indian context and also vis-a-vis the 
                uture of work and the recently released New Education Policy...
                
                <a
                  target="_blank"
                  href="https://www.facebook.com/permalink.php?story_fbid=4336973653087614&id=2260011530783847"
                >
                  {" "}
                  <u>More</u>{" "}
                </a>
              </Card.Text>
            </Card>
          </Grid>

          <Grid item xs={12} sm={12} md={4} className="imageAdjust">
            <Card
              style={{
                border: "solid #fcbc3c",
              }}
            >
              <Card.Img
                variant="top"
                src={require("../../assets/stories/post12.PNG")}
              />

              <h4
                align="center"
                style={{
                  fontSize: "18px",
                  color: "#e95721",
                  fontWeight: "bold",
                  fontStyle: "italic",
                  fontFamily: "sans-serif",
                  paddingTop: "10px",
                  paddingBottom: "1px"
                }}
              >
                21st District Roller Skating Championship
              </h4>
              <Card.Text
                className="card-text text-secondary"
                align="center"
                style={{
                  fontSize: "16px",
                  textAlign: "justify",
                  padding: "10px 10px 10px 10px ",
                }}
              >
               21st District Roller Skating Championship⛸ was held at Police D.A.V School from 
               29th September to 1st October 2021. Our Students Inayat of Grade 2  (Innovative) 
               got Second position in the following events - 1 lap and 500 m. Whereas, Ovia of 
               Grade 9 (IB) got First position in each of the events - 1 lap and 500 m... 
                <a
                  target="_blank"
                  href="https://www.facebook.com/permalink.php?story_fbid=4334689176649395&id=2260011530783847"
                >
                  {" "}
                  <u>More</u>{" "}
                </a>
              </Card.Text>
            </Card>
          </Grid>
        </Grid>


        <Grid container spacing={1}>
          

          <Grid item xs={12} sm={12} md={4} className="imageAdjust">
            <Card
              style={{
                border: "solid #fcbc3c",
              }}
            >
              <Card.Img
                variant="top"
                src={require("../../assets/stories/post2.PNG")}
              />

              <h4
                align="center"
                style={{
                  fontSize: "18px",
                  color: "#e95721",
                  fontWeight: "bold",
                  fontStyle: "italic",
                  fontFamily: "sans-serif",
                  paddingTop: "10px",
                  paddingBottom: "1px"
                }}
              >
                Habitat for Humanity
              </h4>
              <Card.Text
                className="card-text text-secondary"
                align="center"
                style={{
                  fontSize: "16px",
                  textAlign: "justify",
                  padding: "10px 10px 10px 10px ",
                }}
              >
                Believing in the quote by Eric Yuan, ‘Empathy, humanity and support for each other is more important than revenue, than #growth’, 
                CIFS conducted an orientation programme for IBDP Junior students. 
                The event, organized in association with an NGO ‘Habitat for humanity’ was an attempt to 
                inculcate amongst the students an attitude of being humane and care for others selflessly..
                
                <a
                  target="_blank"
                  href="https://www.facebook.com/2260011530783847/posts/4233852803399700/"
                >
                  {" "}
                  <u>More</u>{" "}
                </a>
              </Card.Text>
            </Card>
          </Grid>

          <Grid item xs={12} sm={12} md={4} className="imageAdjust">
            <Card
              style={{
                border: "solid #fcbc3c",
              }}
            >
              <Card.Img
                variant="top"
                src={require("../../assets/stories/post3.jpg")}
              />

              <h4
                align="center"
                style={{
                  fontSize: "18px",
                  color: "#e95721",
                  fontWeight: "bold",
                  fontStyle: "italic",
                  fontFamily: "sans-serif",
                  paddingTop: "10px",
                  paddingBottom: "1px"
                }}
              >
                Virtual Declamation Competition
              </h4>
              <Card.Text
                className="card-text text-secondary"
                align="center"
                style={{
                  fontSize: "16px",
                  textAlign: "justify",
                  padding: "10px 10px 10px 10px ",
                }}
              >
                Students of IBPYP Grade 1 - 5 had the opportunity to display
                their talents and discover a new side of them when they
                enthusiastically participated in ‘Virtual Declamation
                Competition’. This competition served as a platform to promote
                an exchange of ideas among the students. The talented
                participants spellbound the audience...
                <a
                  target="_blank"
                  href="https://www.facebook.com/2260011530783847/posts/3380742085377447/"
                >
                  {" "}
                  <u>More</u>{" "}
                </a>
              </Card.Text>
            </Card>
          </Grid>
        </Grid>

        




        
      </div>
    </Grid>
  </main>
)
