import React from "react"
import Gallery from "../gallery"
import t21 from "../../assets/titleimage/t21.png"
import { Grid } from "@material-ui/core"
import { Helmet } from "react-helmet"


export const SummerCamp = () => (
  <main>
  <div>
    <Helmet>
    <title>
    Summer Camp | Cambridge International Foundation School 

         </title>
    </Helmet>

    </div>
    
    <Grid container style={{ margin: "10px 2px" }}>
      <div
        className="teamContainer pageContainer pageContainer1 pageContainer3 alumniVideo"
        style={{ marginBottom: "30px" }}
      >
        <h1
          style={{
            color: "#e95721",
            fontSize: "35px",
            fontWeight: "bold",
            fontFamily: "sans-serif",
          }}
        >
          Summer Camp
        </h1>
        
        
        


        <div align="center">
        <img
         
          style={{
            background: "transparent",
           
          }}
          src={require("../../assets/images/scamp1.png")}
          alt="Our-Story"
          loader="gradient"
          gradientPreset="life"
        />
        </div>
        <br />
        <br />

        <div align="center">
        <img
         
          style={{
            background: "transparent",
           
          }}
          src={require("../../assets/images/scamp2.png")}
          alt="Our-Story"
          loader="gradient"
          gradientPreset="life"
        />
        </div>

        <div align="center">
        <img
         
          style={{
            background: "transparent",
           
          }}
          src={require("../../assets/images/scamp3.png")}
          alt="Our-Story"
          loader="gradient"
          gradientPreset="life"
        />
        </div>

        <div align="center">
        <img
         
          style={{
            background: "transparent",
           
          }}
          src={require("../../assets/images/scamp4.png")}
          alt="Our-Story"
          loader="gradient"
          gradientPreset="life"
        />
        </div>

        <div align="center">
        <img
         
          style={{
            background: "transparent",
           
          }}
          src={require("../../assets/images/scamp5.png")}
          alt="Our-Story"
          loader="gradient"
          gradientPreset="life"
        />
        </div>

        <div align="center">
        <img
         
          style={{
            background: "transparent",
           
          }}
          src={require("../../assets/images/scamp6.png")}
          alt="Our-Story"
          loader="gradient"
          gradientPreset="life"
        />
        </div>
        <br/>
        

        <div align="center">
        <img
         
          style={{
            background: "transparent",
           
          }}
          src={require("../../assets/images/scamp7.png")}
          alt="Our-Story"
          loader="gradient"
          gradientPreset="life"
        />
        </div>

        <div align="center">
        <img
         
          style={{
            background: "transparent",
           
          }}
          src={require("../../assets/images/scamp8.png")}
          alt="Our-Story"
          loader="gradient"
          gradientPreset="life"
        />
        </div>


  
      </div>
    </Grid>
  </main>
)
