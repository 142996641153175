import React from "react"
import { Grid } from "@material-ui/core"
import { Helmet } from "react-helmet"

export const ArtAndCraft = () => (
  <main>
  <div>
    <Helmet>
    <title>
    Art & Craft | Cambridge International Foundation School 

         </title>
    </Helmet>

    </div>
    <Grid container style={{ margin: "10px 2px" }}>
      <div className="teamContainer pageContainer pageContainer1">
        <h1
          style={{
            color: "#e95721",
            fontWeight: "bold",
            fontFamily: "sans-serif",
          }}
        >
          Art and Craft
        </h1>
        <p>
          Beginning with audacity, Art is the transforming experience of
          attaining rapture while reaching our goals. To give the students the
          same rapturing experience, a full-fledged Art &amp; Craft studio is
          available in school that provides the students with opportunities to
          express themselves in the most creative way.
        </p>
        <p>
          <i style={{ fontSize: "150%", color: "#0ca685" }}>
            "Art is an international language understood by all"
          </i>
        </p>

        <div className="row">
          <div className="col">
            <img
              style={{
                border: "solid #fcbc3c",
              }}
              src={require("../../assets/images/art1.jpg")}
              alt="Our-Story"
              loader="gradient"
              gradientPreset="life"
            />
          </div>

          <div className="col">
            <img
              style={{
                border: "solid #fcbc3c",
              }}
              src={require("../../assets/images/art2.jpg")}
              alt="Our-Story"
              loader="gradient"
              gradientPreset="life"
            />
          </div>
          <div className="col">
            <img
              style={{
                border: "solid #fcbc3c",
              }}
              src={require("../../assets/images/art3.jpg")}
              alt="Our-Story"
              loader="gradient"
              gradientPreset="life"
            />
          </div>
        </div>

        <div className="row">
          <div className="col">
            <img
              style={{
                border: "solid #fcbc3c",
              }}
              src={require("../../assets/images/art4.jpg")}
              alt="Our-Story"
              loader="gradient"
              gradientPreset="life"
            />
          </div>

          <div className="col">
            <img
              style={{
                border: "solid #fcbc3c",
              }}
              src={require("../../assets/images/art5.jpg")}
              alt="Our-Story"
              loader="gradient"
              gradientPreset="life"
            />
          </div>
          <div className="col">
            <img
              style={{
                border: "solid #fcbc3c",
              }}
              src={require("../../assets/images/art6.jpg")}
              alt="Our-Story"
              loader="gradient"
              gradientPreset="life"
            />
          </div>
        </div>
      </div>
    </Grid>
  </main>
)
