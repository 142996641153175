import React from "react"
import al1 from "../../assets/blogs/al1.mp4"
import al2 from "../../assets/blogs/al2.mp4"
import al3 from "../../assets/blogs/al3.mp4"
import t22 from "../../assets/titleimage/t22.png"
import al4 from "../../assets/blogs/al4.mp4"
import al7 from "../../assets/blogs/al7.mp4"
import al6 from "../../assets/blogs/al6.mp4"
import { Grid } from "@material-ui/core"
import ReactPlayer from "react-player"
import { Helmet } from "react-helmet"

export const Alumni = () => (
  <main>
  <div>
    <Helmet>
    <title>
    Alumni | Cambridge International Foundation School 

         </title>
    </Helmet>

    </div>
    <img src={t22} alt="Our-Story" />
    <Grid container justify="center" style={{ margin: "10px 2px" }}>
        <div className="teamContainer pageContainer pageContainer1 alumniVideo"
           style={{ marginBottom: "30px" ,paddingRight:"50px"}}
        >
      
      <h1
        style={{
          color: "#e95721",
          fontWeight: "bold",
          fontSize: "35px",
          fontFamily: "sans-serif",
        }}
      >
        Alumni Speak
      </h1>

      <p className="card-text text-secondary">
        The best reflection of an institute are its alumni. They represent how
        worthy the institute is to shape its charges in accordance with the ever
        changing global scenario. A very important overlooked entity in the past
        ‘Alumni and Alma-mater connection’, has become the strategic factor for
        the success of an institute. CIFS too, believes in establishing a very
        healthy relationship with its alumni. We strive to unite the past
        students with the present so as to pass the traditions of the
        institution to the junior ones and help the senior alumni to recollect
        with fellow graduates; their learning experiences and opportunities of
        professional growth.
      </p>

      <i style={{ fontSize: "150%", color: "#0ca685" , marginBottom:"50px"}}>
        Videos from our Alumni
      </i>
      
     <Grid container spacing={1}>
          <Grid item sm={12} xs={12} md={6}>
        <ReactPlayer
         width="100%"
          url={al1}
          style={{ border: "3px solid #fcbc3c"}}
          controls
          type="video/mp4"
        />
    </Grid>

          <Grid item sm={12} xs={12} md={6}>
        <ReactPlayer
         width="100%"
          url={al2}
          style={{ border: "3px solid #fcbc3c" }}
          controls
          type="video/mp4"
        />
      </Grid>
    </Grid>

    <Grid container spacing={1}>
          <Grid item xs={12} sm={12} md={6}>
        <ReactPlayer
         width="100%"
          url={al3}
          style={{ border: "3px solid #fcbc3c" }}
          controls
          type="video/mp4"
        />
      </Grid>

          <Grid item sm={12} xs={12} md={6}>
        <ReactPlayer
         width="100%"
          url={al4}
          style={{ border: "3px solid #fcbc3c" }}
          controls
          type="video/mp4"
        />
    </Grid>
    </Grid>

    <Grid container spacing={1}>
          <Grid item xs={12} sm={12} md={6}>
        <ReactPlayer
         width="100%"
          url={al7}
          style={{ border: "3px solid #fcbc3c" }}
          controls
          type="video/mp4"
        />
     </Grid> 

          <Grid item sm={12} xs={12} md={6}>
        <ReactPlayer
         width="100%"
          url={al6}
          style={{ border: "3px solid #fcbc3c" }}
          controls
          type="video/mp4"
        />
     </Grid>
    </Grid>
    </div>
    </Grid>

  </main>
)
