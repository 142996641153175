import React from "react"
import "../components/layout.css"
import Header from "./common/header"
import Footer from "./common/Footer"

export const Layout = props => {
  return (
    <>
      {/* <div className="App">
        <TextScroller text="Admission Open 2020-21. Register Now." />
      </div> */}
      <Header />
      <main style={{ marginTop: 110 }}>{props.children}</main>
      <Footer />
    </>
  )
}
