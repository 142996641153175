import React from "react"
import "./Footer.css"
import InstagramIcon from "@material-ui/icons/Instagram"
import TwitterIcon from "@material-ui/icons/Twitter"
import { Facebook, YouTube } from "@material-ui/icons"

const Footer = () => {
  return (
    <div align="center" className="main-footer">
      <div className="container">
        <div className="row">
          {/*Column1 */}
          <div className="col">
            <h4
              style={{ fontSize: "bold", fontSize: "25px", color: "#e95721" }}
            >
              So how can we make you shine?
            </h4>
            <div className="list-unstyled">
              <p
                style={{
                  fontFamily: "Times",
                  fontSize: "17px",
                  color: "#0ca685",
                }}
              >
                Contact Cambridge International Foundation school to find out.
              </p>
            </div>
            <br />
          </div>
        </div>
      </div>
      <div align="center">
        <div className="row">
          <div className="col-sm-1 offset-md-4">
            <a href="https://www.facebook.com/Cambridge-International-Foundation-School-Jalandhar-2260011530783847">
              <Facebook style={{ color: "black", fontSize: "35px" }} />
            </a>
          </div>
          <div className="col-sm-1">
            <a href="https://www.instagram.com/lwescambridgeschools/">
              <InstagramIcon style={{ color: "black", fontSize: "35px" }} />
            </a>
          </div>
          <div className="col-sm-1">
            <a href="https://twitter.com/cambridge_jal?s=08">
              <TwitterIcon style={{ color: "black", fontSize: "35px" }} />
            </a>
          </div>
          <div className="col-sm-1" style={{ paddingBottom: "30px" }}>
            <a href="https://www.youtube.com/channel/UCJrs1psY46djYBSTIFb9fqg">
              <YouTube style={{ color: "black", fontSize: "35px" }} />
            </a>
          </div>
        </div>
      </div>

      <div
        className="row"
        style={{ background: "#fcbc3c", paddingTop: "25px" }}
      >
        <p
          className="col-sm"
          style={{
            fontFamily: "museo-slab",
            fontSize: "15px",
            color: "black",
            fontWeight: "bold",
          }}
        >
          &copy;{new Date().getFullYear()} | All Rights Reserved | Designed & Managed by Ed-TechTeam
        </p>
        <br />
        <br />
      </div>
    </div>
  )
}
export default Footer
