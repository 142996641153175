import React from "react"
import { makeStyles } from "@material-ui/styles"
import { Paper, Grid } from "@material-ui/core"
import aboutOptions from "./aboutSection"
import { withRouter } from "react-router-dom"

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),

    color: theme.palette.text.secondary,
  },
  enquiry: {
    padding: theme.spacing(2),

    color: theme.palette.text.secondary,
    height: "100%",
  },
  aboutSection: {
    margin: 0,
  },
}))

const GridWrapper3 = props => {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      {props.title && (
        <Grid container spacing={0} alignItems="center" justify="center">
          {props.title}
        </Grid>
      )}

      <Grid container spacing={1}>
        <Grid item xs={16} sm={8}>
          <Paper elevation={0} className={classes.paper}>
            <props.children />
          </Paper>
        </Grid>
        <Grid item xs={14} sm={4}>
          <Paper elevation={0} className={classes.enquiry}>
            <div
              style={{
                marginLeft: "40px",
                fontSize: "20px",
                color: "blue",
                background: "#f4f0ec",
                padding: "10px",
                marginRight: "80px",
                color: "#e95721",
              }}
            >
              {aboutOptions.map(option => (
                <div
                  style={{
                    cursor: "pointer",
                  }}
                  onClick={() => props.history.push(option.link)}
                >
                  {option.title}
                </div>
              ))}
            </div>

            <div
              style={{
                marginTop: "30px",
                marginBottom: "20px",
                marginLeft: "40px",
                color: "#e95721",
                fontWeight: "bold",
                fontSize: "25px",
                fontFamily: "sans-serif",
              }}
            >
              <p>Our Community</p>
            </div>
            <p style={{ marginLeft: "40px", marginRight: "50px" }}>
              Learning Wings has K1-12 and Early Education schools spread over
              North India. Visit the school websites, or call us, or write to us
              for more information. All schools are currently accepting
              admissions.
            </p>

            <div
              className="row"
              style={{ paddingTop: "20px", paddingLeft: "22px" }}
            >
              <div
                className="col"
                className="img-hover-zoom--colorize"
                style={{
                  paddingLeft: "50px",
                }}
              >
                <a href="https://www.cisfgjal.school/" target="_blank">
                  <img
                    style={{
                      border: "solid #fcbc3c",
                      width: "250px",
                      height: "250px",
                    }}
                    src={require("../../assets/images/girls.jpg")}
                    alt="Our-Story"
                    loader="gradient"
                    gradientPreset="life"
                  />
                </a>
              </div>

              <div
                className="col"
                className="img-hover-zoom--colorize"
                style={{
                  paddingLeft: "50px",
                }}
              >
                <a href="https://www.cisfgjal.school/" target="_blank">
                  <img
                    style={{
                      border: "solid #fcbc3c",
                      width: "250px",
                      height: "250px",
                    }}
                    src={require("../../assets/images/Building.jpg")}
                    alt="Our-Story"
                    loader="gradient"
                    gradientPreset="life"
                  />
                </a>
              </div>
              <div
                className="col"
                className="img-hover-zoom--colorize"
                style={{
                  paddingLeft: "50px",
                }}
              >
                <a href="https://www.cisfgjal.school/" target="_blank">
                  <img
                    style={{
                      border: "solid #fcbc3c",
                      width: "250px",
                      height: "250px",
                    }}
                    src={require("../../assets/images/mohali1.jpg")}
                    alt="Our-Story"
                    loader="gradient"
                    gradientPreset="life"
                  />
                </a>
              </div>
              <div
                className="col"
                className="img-hover-zoom--colorize"
                style={{
                  paddingLeft: "50px",
                }}
              >
                <a href="https://www.cisfgjal.school/" target="_blank">
                  <img
                    style={{
                      border: "solid #fcbc3c",
                      width: "250px",
                      height: "250px",
                    }}
                    src={require("../../assets/images/pathankot.jpg")}
                    alt="Our-Story"
                    loader="gradient"
                    gradientPreset="life"
                  />
                </a>
              </div>
              <div
                className="col"
                className="img-hover-zoom--colorize"
                style={{
                  paddingLeft: "50px",
                }}
              >
                <a href="https://www.cisfgjal.school/" target="_blank">
                  <img
                    style={{
                      border: "solid #fcbc3c",
                      width: "250px",
                      height: "250px",
                    }}
                    src={require("../../assets/images/patti.jpg")}
                    alt="Our-Story"
                    loader="gradient"
                    gradientPreset="life"
                  />
                </a>
              </div>
              <div
                className="col"
                className="img-hover-zoom--colorize"
                style={{
                  paddingLeft: "50px",
                }}
              >
                <a href="https://www.cisfgjal.school/" target="_blank">
                  <img
                    style={{
                      border: "solid #fcbc3c",
                      width: "250px",
                      height: "250px",
                    }}
                    src={require("../../assets/images/jammu.JPG")}
                    alt="Our-Story"
                    loader="gradient"
                    gradientPreset="life"
                  />
                </a>
              </div>
            </div>
          </Paper>
        </Grid>
      </Grid>
    </div>
  )
}

export default withRouter(GridWrapper3)
