import React from "react"
import { Grid } from "@material-ui/core"
import { Helmet } from "react-helmet"

export const Sports = () => (
  <main>
   <div>
    <Helmet>
    <title>
    Sports | Cambridge International Foundation School 

         </title>
    </Helmet>

    </div>
    <Grid container justify="center" style={{ margin: "10px 2px" }}>
      <div
        className="teamContainer pageContainer pageContainer1"
        style={{ marginBottom: "30px" }}
      >
        <h1
          style={{
            color: "#e95721",
            fontSize: "35px",
            fontWeight: "bold",
            fontFamily: "sans-serif",
          }}
        >
          Sports
        </h1>
        <p>
          The development of mind and body go hand-in-hand. In short, physical
          education plays a vital role in creating well-rounded, confident
          individuals. Sports are an integral part of our education system.
        </p>
        <p>
          <i style={{ fontSize: "150%", color: "#0ca685" }}>
            "If you can believe it, the mind can achieve it."
          </i>
        </p>

        <p>
          To make them good at their respective sport, every Cambridge
          International School has expert coaches and trainers who impart
          invaluable tips to children to hone their skills. All our schools have
          a huge sports ground, an athletic track, tennis courts, basketball and
          badminton courts that reflect our seriousness towards sports. We have
          world class facilities for hockey, cricket, football, volleyball,
          table tennis, badminton, throw ball, and basketball.
        </p>

        <div className="row">
          <div className="col">
            <img
              style={{
                border: "solid #fcbc3c",
              }}
              src={require("../../assets/images/sports1.jpg")}
              alt="Our-Story"
              loader="gradient"
              gradientPreset="life"
            />
          </div>

          <div className="col">
            <img
              style={{
                border: "solid #fcbc3c",
              }}
              src={require("../../assets/images/sports3.jpg")}
              alt="Our-Story"
              loader="gradient"
              gradientPreset="life"
            />
          </div>
          <div className="col">
            <img
              style={{
                border: "solid #fcbc3c",
              }}
              src={require("../../assets/images/sports8.jpg")}
              alt="Our-Story"
              loader="gradient"
              gradientPreset="life"
            />
          </div>
        </div>

        <div className="row">
          <div className="col">
            <img
              style={{
                border: "solid #fcbc3c",
              }}
              src={require("../../assets/images/sports6.jpg")}
              alt="Our-Story"
              loader="gradient"
              gradientPreset="life"
            />
          </div>

          <div className="col">
            <img
              style={{
                border: "solid #fcbc3c",
              }}
              src={require("../../assets/images/sports7.jpg")}
              alt="Our-Story"
              loader="gradient"
              gradientPreset="life"
            />
          </div>
          <div className="col">
            <img
              style={{
                border: "solid #fcbc3c",
              }}
              src={require("../../assets/images/sports4.jpg")}
              alt="Our-Story"
              loader="gradient"
              gradientPreset="life"
            />
          </div>
        </div>
      </div>
    </Grid>
  </main>
)
